import React, { useMemo, useState, useContext } from "react";
import { StyledCardNameWrapper } from "../../robots/assignments/components";
import { RobotsDataContext } from "../../../contexts/RobotsDataContext";
import {
  StyledFooterButton,
  StyledFooterWrapper,
  StyledRobotName,
  StyledTaskAndNameWrapper,
  StyledTeleopCard,
  StyledTeleopStatus,
  StyledTransitive,
} from "./components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import URLS from "../../../constants/urls";
import { useTransitive } from "../../../contexts/TransitiveContext";
import { ROBOTSTATUSENUM, TELEOPSTATUSENUM } from "../../../enums";
import ICONS from "../../../constants/icons";
import { IconWrapper } from "../../robots/tasks-in-assignments/components";

const TeleopCard = ({ robot }) => {
  const { teleopRobots, Transitives } = useTransitive();
  const { highlightedRobots } = useContext(RobotsDataContext);
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const currentRobot = useMemo(() => {
    const found = teleopRobots.find(
      (r) =>
        `${r.robotId + r.clientName}` === `${robot.robotId + robot.client.name}`
    );
    return found ? { ...found, ...robot } : robot;
  }, [teleopRobots, robot]);

  const TransitiveComponent = useMemo(() => {
    return Transitives.find((t) => t.key === currentRobot.id);
  }, [currentRobot, Transitives]);
  const navigateToTeleoperationDetails = () => {
    history.push(`${URLS.teleoperation}/${robot.id}`, {
      from: "teleoperation",
    });
  };
  const isHighlighted = highlightedRobots.includes(robot.robotId);

  const statusToDisplay = useMemo(() => {
    let status = "";

    switch (currentRobot?.teleop_status?.status) {
      case TELEOPSTATUSENUM.IDLE:
        status = "Available";
        break;
      case TELEOPSTATUSENUM.REQUESTING:
        status = "Requesting";
        break;
      case TELEOPSTATUSENUM.TELEOPERATING:
        status = "Teleoperating";
        break;
      case TELEOPSTATUSENUM.STOPPING_TELEOPERATION:
        status = "Stopping Teleoperation";
        break;
      default:
        status = "Offline";
        break;
    }

    return status;
  }, [currentRobot]);

  return (
    <div onClick={navigateToTeleoperationDetails}>
      <StyledTeleopCard
        opacity={1}
        style={{ width: "410px", height: "430px", maxWidth: "unset" }}
        isHighlighted={isHighlighted}
      >
        <StyledCardNameWrapper style={{ padding: "10px 16px" }}>
          <StyledTaskAndNameWrapper style={{ width: "200px" }}>
            <StyledRobotName>
              {currentRobot?.current_task?.name || "No Tasks Running"}
            </StyledRobotName>
            <StyledRobotName style={{ fontSize: "16px" }}>
              {currentRobot?.client?.name}
            </StyledRobotName>
          </StyledTaskAndNameWrapper>
          <StyledTeleopStatus
            status={currentRobot?.teleop_status?.status || ROBOTSTATUSENUM.OFF}
          >
            {statusToDisplay}
          </StyledTeleopStatus>
        </StyledCardNameWrapper>
        <StyledTransitive toggle={toggle}>
          <IconWrapper
            onClick={(e) => {
              e.stopPropagation();
              setToggle((prev) => !prev);
            }}
            className="switch-icon"
          >
            <img src={ICONS.SwitchIcon} alt="" />
          </IconWrapper>
          {currentRobot?.teleop_status?.status ? (
            TransitiveComponent
          ) : (
            <img
              src={ICONS.ErrorRobotIcon}
              alt="error"
              style={{ width: "320px" }}
            />
          )}
        </StyledTransitive>
        <StyledFooterWrapper>
          <StyledFooterButton>{currentRobot?.name}</StyledFooterButton>
        </StyledFooterWrapper>
      </StyledTeleopCard>
    </div>
  );
};

export default TeleopCard;
