import styled from "styled-components";

export const TeleopHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  margin-top: 20px;
`;
export const TeleopHeaderContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  /* justify-content: space-between; */
  padding: 12px;
`;

export const StyledRobotName = styled.h3`
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
  padding: 6px;
`;

export const StyledTeleopStatus = styled.h4`
  font-size: 24px;
  font-weight: 500;
`;

export const TeleopPageGridWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
  /* height: 100%; */
`;

export const StyledTeleopLeftContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  position: relative;
`;
export const StyledTeleopRightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

export const IconWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  z-index: 998;
`;

export const StyledMapName = styled.h4`
  font-size: 24px;
  color: #030a1b;
  font-weight: 500;
`;

export const RunninAppHeader = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

export const TransitiveComponentWrapper = styled.div`
  width: 100%;
  height: ${({ isFullScreen }) => !isFullScreen && "100%"};
  video {
    /* height: 100%; */
    width: ${({ isFullScreen, teleopVideoWidth }) =>
      isFullScreen ? "100%" : teleopVideoWidth};
  }

  .webrtc-video-wrapper {
    margin-top: 60px;
    /* height: ${({ childHeight, isFullScreen }) =>
      !isFullScreen ? childHeight : "100%"}; */
    display: flex;
    flex-direction: ${(props) =>
      props.isFullScreen ? "row" : "column"} !important;
    justify-content: center;
    align-items: center;
  }
`;

export const SignalWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  > p {
    font-weight: 700;
  }
`;

export const TeleopDebugLogsWrapper = styled.div`
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0%);
  width: 500px;
`;

export const RobotStatusWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;

export const EmptyAppRunningWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JoystickDescriptionWrapper = styled.div`
  position: absolute;
  z-index: 999;
  right: 0;
  top: 50px;
  transform: translate(100%, 0%);
`;
