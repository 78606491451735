import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import ModalOutline from "../../../../components/modal-outline/ModalOutline";
import { StyledSelect } from "../../tasks-in-assignments/components";
import { StyledInputTitle } from "../../../teleoperation/card/components";
import { ElevatorModalContent, StyledElevatorChooseP } from "./components";
import { ModalFooter } from "../../../../components/info-modal/components";
import { StyledButton } from "../../../../components/button/components";
import requestElevator from "../../../../APIs/elevators/requestElevator";
import dispatchElevator from "../../../../APIs/elevators/dispatchElevator";
import Loader from "../../../../components/loader/Loader";
import { setToastMessages } from "../../../../redux/toast";

const RequestElevatorModal = ({
  isOpen,
  setIsElevatorModalOpen = () => {},
  currentRobot,
  callType,
  lastElevatorCalled,
  direction,
  floor,
  setDirection,
  setFloor,
  chosenElevator,
  setChosenElevator,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDirectionChange = (e) => {
    setDirection(e.target.value);
  };

  const handleElevatorChane = (e) => {
    setChosenElevator(e.target.value);
  };

  const handleFloorChange = (e) => {
    setFloor(e.target.value);
  };

  const handleRequestElevator = useCallback(
    async (e) => {
      setLoading(true);
      try {
        await requestElevator({
          direction,
          floor: +floor,
          robotId: currentRobot.robotId,
          clientName: currentRobot.clientName,
          groupId: 1,
        });
        setLoading(false);
        setIsElevatorModalOpen(false);
      } catch (error) {
        console.error(error);
        dispatch(
          setToastMessages({
            text: error?.response?.data?.message,
            type: "error",
          })
        );
        setLoading(false);
      }
      e.preventDefault();
    },
    [currentRobot, dispatch, floor, direction, setIsElevatorModalOpen]
  );

  const handleDispatchElevator = useCallback(
    async (e) => {
      setLoading(true);
      try {
        await dispatchElevator({
          floor: +floor,
          robotId: currentRobot.robotId,
          clientName: currentRobot.clientName,
          groupId: 1,
          machine: lastElevatorCalled,
        });
        setLoading(false);
        setIsElevatorModalOpen(false);
      } catch (error) {
        console.error(error);
        dispatch(
          setToastMessages({
            text: error?.response?.data?.message,
            type: "error",
          })
        );
        setLoading(false);
      }
      e.preventDefault();
    },
    [currentRobot, dispatch, floor, lastElevatorCalled, setIsElevatorModalOpen]
  );

  const handleCallElevator = useCallback(
    async (e) => {
      setLoading(true);
      try {
        await dispatchElevator({
          floor: +floor,
          robotId: currentRobot.robotId,
          clientName: currentRobot.clientName,
          groupId: 1,
          machine: +chosenElevator,
        });
        setLoading(false);
        setIsElevatorModalOpen(false);
      } catch (error) {
        console.error(error);
        dispatch(
          setToastMessages({
            text: error?.response?.data?.message,
            type: "error",
          })
        );
        setLoading(false);
      }
      e.preventDefault();
    },
    [currentRobot, dispatch, floor, setIsElevatorModalOpen, chosenElevator]
  );

  return (
    <ModalOutline
      isOpen={!!isOpen}
      onRequestClose={() => setIsElevatorModalOpen(false)}
      heading={
        callType === "carCall" ? "Elevator " + lastElevatorCalled : "Elevators"
      }
    >
      <ElevatorModalContent>
        {callType === "hallCall" && (
          <div>
            <StyledInputTitle>Direction:</StyledInputTitle>
            <StyledSelect value={direction} onChange={handleDirectionChange}>
              <option value={"UP"}>Up</option>
              <option value={"DOWN"}>Down</option>
            </StyledSelect>
          </div>
        )}

        <div>
          {callType === "hallCall" && (
            <StyledInputTitle>Current Floor:</StyledInputTitle>
          )}
          {callType === "carCall" && (
            <StyledInputTitle>Destination Floor:</StyledInputTitle>
          )}
          <StyledSelect value={floor} onChange={handleFloorChange}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((fl) => (
              <option key={"handleFloorChange_button_" + fl} value={fl}>
                {fl}
              </option>
            ))}
          </StyledSelect>
        </div>

        {callType === "hallCall" && (
          <div>
            <StyledElevatorChooseP>
              *Optional, if not chosen system will choose
            </StyledElevatorChooseP>
            <StyledInputTitle>Elevator:</StyledInputTitle>
            <StyledSelect value={chosenElevator} onChange={handleElevatorChane}>
              <option value={null}></option>
              <option value={2}>2</option>
              <option value={4}>4</option>
            </StyledSelect>
          </div>
        )}
      </ElevatorModalContent>
      <ModalFooter style={{ margin: "30px 0" }}>
        <StyledButton
          id="elevator-confirm-button"
          backgroundColor={"#0096C6"}
          padding="10px 50px"
          disabled={loading}
          onClick={async (e) => {
            if (callType === "hallCall" && chosenElevator === null)
              await handleRequestElevator(e);
            if (callType === "hallCall" && chosenElevator)
              await handleCallElevator(e);
            if (callType === "carCall") await handleDispatchElevator(e);
          }}
        >
          {loading ? (
            <Loader height={20} noText noMargin padding={0} />
          ) : (
            <span style={{ color: "#fff", fontSize: "16px" }}>
              {callType === "carCall" ? "Dispatch" : "Request"}
            </span>
          )}
        </StyledButton>
      </ModalFooter>
    </ModalOutline>
  );
};

export default RequestElevatorModal;
