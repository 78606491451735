import React, { useEffect, useMemo, useState } from "react";
import {
  PreviewContainer,
  StyledSubtitle,
  StyledTitle,
  WidgetHeader,
  WidgetTypeWrapper,
} from "./components";
import PieWidget from "./widget-types/PieWidget";
import NumberWidget from "./widget-types/NumberWidget";
import ChartWidget from "./widget-types/ChartWidget";
import GraphWidget from "./widget-types/GraphWidget";
import getAnalyticsPreview from "../../../APIs/analytics/getAnalyticsPreview";
import { useSelector } from "react-redux";
import { useDebounce } from "../../../hooks/useDebounce";
import moment from "moment-timezone";

const WidgetPreview = ({ widgetData }) => {
  const { debounce } = useDebounce();
  const [previewData, setPreviewData] = useState();
  const { chosenClient } = useSelector((state) => state.clients);
  const dataType = useMemo(() => {
    switch (widgetData.type) {
      case "Number":
        return (
          <NumberWidget
            config={previewData}
            analytics={previewData?.analytics}
          />
        );
      case "Pie":
        return (
          <PieWidget config={previewData} analytics={previewData?.analytics} />
        );
      case "Chart":
        return (
          <ChartWidget
            config={previewData}
            analytics={previewData?.analytics}
          />
        );
      case "Graph":
        return (
          <GraphWidget
            config={previewData}
            analytics={previewData?.analytics}
          />
        );
      default:
        return null;
    }
  }, [previewData, widgetData.type]);
  useEffect(() => {
    const fetchData = () => {
      const timePeriod = {
        from: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
      };
      if (chosenClient && widgetData.event && widgetData.parameter) {
        let query = new URLSearchParams({
          ...widgetData,
          ...timePeriod,
        });
        getAnalyticsPreview(chosenClient, query).then((data) => {
          setPreviewData(data);
        });
      }
    };

    const debouncedFetchData = debounce(fetchData, 500);
    debouncedFetchData();
  }, [chosenClient, widgetData, debounce]);
  return (
    previewData && (
      <PreviewContainer>
        <WidgetHeader>
          <StyledTitle style={{ width: "100%" }}>
            {widgetData.title}
          </StyledTitle>
        </WidgetHeader>
        <StyledSubtitle>{widgetData?.description}</StyledSubtitle>
        <WidgetTypeWrapper>{dataType}</WidgetTypeWrapper>
      </PreviewContainer>
    )
  );
};

export default WidgetPreview;
