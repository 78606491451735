import React, { useContext, useMemo } from "react";
import { StyledNumberWidgetWrapper } from "./components";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";

const NumberWidget = ({ analytics, config, wrapperWidth }) => {
  const { isMobile } = useContext(ResponsiveContext);

  const number = useMemo(() => {
    if (config.unit === "distance") {
      //temporary
      return analytics.number / 1000 + "mi";
    } else {
      return analytics.number;
    }
  }, [analytics, config]);

  const fontSize = useMemo(() => {
    if (wrapperWidth < 350) {
      return number?.length > 3 ? "50px" : "100px";
    } else {
      return number?.length > 5 ? "70px" : "100px";
    }
  }, [number, wrapperWidth]);

  return (
    <StyledNumberWidgetWrapper
      isMobile={isMobile}
      style={{
        fontSize,
      }}
    >
      {number}
    </StyledNumberWidgetWrapper>
  );
};

export default NumberWidget;
