import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as moment from "moment-timezone";
import getAnalyticsLogs from "../../../APIs/analytics/getAnalyticsLogs";
import {
  BackDrop,
  ClientsTable,
  ComponentWrapper,
  EditItem,
  StyledEditBox,
  StyledEditIcon,
  TableHeader,
  TableHeaderItems,
  TableRow,
  TableRowItem,
  VertMenuWrapper,
} from "./components";
import STRINGS from "../../../constants/strings";
import ICONS from "../../../constants/icons";
import AddEventModal from "./AddEventModal";
import InfoModal from "../../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../../constants/types";
import deleteAnalyticsLog from "../../../APIs/analytics/deleteAnalyticsLog";

const headerArr = [
  STRINGS.date_time,
  STRINGS.event_name,
  STRINGS.robot,
  STRINGS.parameters,
];

const AnalyticsLogTable = ({ addEventModalOpen, setAddEventModalOpen }) => {
  const location = useLocation();

  const { chosenClient } = useSelector((state) => state.clients);
  const [analyticsData, setAnalyticsData] = useState([]);

  const [currentModalData, setCurrentModalData] = useState({});
  const [newEventForm, setNewEventForm] = useState({
    application: "",
    event: "",
    robot: "",
    parameters: "",
    createdAt: "",
  });

  const [showEdit, setShowEdit] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const handleDelete = async (id) => {
    await deleteAnalyticsLog(id);
    setAnalyticsData((prev) => prev.filter((item) => item.id !== id));
  };

  const handleEdit = (item) => {
    setCurrentModalData({
      ...item,
      robot: item?.robot?.name,
      application: item?.application?.technicalName,
      parameters: JSON.stringify(item.parameters),
      createdAt: item.createdAt,
    });
    setAddEventModalOpen(true);
  };

  useEffect(() => {
    if (chosenClient) {
      const params = new URLSearchParams(location.search);
      params.size &&
        getAnalyticsLogs(chosenClient, params).then((data) => {
          setAnalyticsData(data);
        });
    }
  }, [chosenClient, location.search]);

  return (
    <ComponentWrapper>
      <ClientsTable>
        <TableHeader>
          {headerArr.map((item, index) => (
            <TableHeaderItems key={index}>{item}</TableHeaderItems>
          ))}
        </TableHeader>
        <AddEventModal
          newEventForm={newEventForm}
          setNewEventForm={setNewEventForm}
          isOpen={addEventModalOpen}
          currentModalData={currentModalData}
          setIsOpen={setAddEventModalOpen}
          setAnalyticsData={setAnalyticsData}
          setCurrentModalData={setCurrentModalData}
        />
        {analyticsData.map((item, index) => (
          <TableRow key={index}>
            <TableRowItem>
              {moment(item.createdAt).format("DD/MM/YY - HH:mm")}
            </TableRowItem>
            <TableRowItem>{item.event}</TableRowItem>
            <TableRowItem>{item?.robot?.name} </TableRowItem>
            <TableRowItem>
              <pre>
                <code>
                  {typeof item.parameters === "object"
                    ? JSON.stringify(item.parameters, null, 2)
                    : item.parameters}
                </code>
              </pre>
            </TableRowItem>
            <VertMenuWrapper>
              <img
                src={ICONS.MenuVertical}
                alt="edit"
                onClick={() => setShowEdit((prev) => !prev && item.id)}
              />

              {showEdit === item.id && (
                <React.Fragment>
                  <StyledEditBox>
                    <EditItem
                      onClick={() => {
                        setShowEdit(false);
                        handleEdit(item);
                      }}
                    >
                      <StyledEditIcon src={ICONS.PencilDraw} alt="Pencil" />
                      Edit
                    </EditItem>
                    <EditItem onClick={() => setIsDeleteModal(true)}>
                      <StyledEditIcon src={ICONS.DeleteIcon} alt="DeleteIcon" />
                      Delete
                    </EditItem>
                  </StyledEditBox>
                  <InfoModal
                    hideHeader
                    isOpen={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    type={InfoModalTypes.WARNING}
                    itemId={item.id}
                    title="Are you sure to delete an event?"
                    noOuterClickClosing
                    cancelAction={() => setIsDeleteModal(false)}
                    submitAction={() => {
                      handleDelete(item.id);
                      setIsDeleteModal(false);
                      setShowEdit(false);
                    }}
                    cancelText={"No"}
                    submitText={"Yes"}
                    disabled={false}
                  />
                  <BackDrop onClick={() => setShowEdit(false)}></BackDrop>
                </React.Fragment>
              )}
            </VertMenuWrapper>
          </TableRow>
        ))}
      </ClientsTable>
    </ComponentWrapper>
  );
};

export default AnalyticsLogTable;
