import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import COLORS from "../../../constants/colors";
import STRINGS from "../../../constants/strings";
import ModalOutline from "../../../components/modal-outline/ModalOutline";
import { ModalContentForm } from "../../../components/add-client-modal/components";
import { InputWrapper } from "../../../components/input/components";
import InputText from "../../../components/input/InputText";
import { ModalFooter } from "../../../components/modal-outline/components";
import Loader from "../../../components/loader/Loader";
import CustomButton from "../../../components/button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import addAnalyticsLog from "../../../APIs/analytics/addAnalyticsLog";
import { isEmpty } from "../../../utils/isEmpty";
import { DateAndTimeWrapper, DateBox } from "./components";
import moment from "moment-timezone";
import TimePicker from "react-time-picker/dist/TimePicker";
import SearchableSelect from "../../../components/searchable-select/SearchableSelect";
import getEventNames from "../../../APIs/analytics/getEventNames";
import { setEventNames } from "../../../redux/analytics";
import getApplicationTechincalnamesByClient from "../../../APIs/application/getApplicationTechincalnamesByClient";

const combineDateAndTime = (date, time) => {
  const [hours, minutes] = time.split(":");
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  return newDate;
};

const paramDefaultStates = {
  event: "",
  robot: "",
  application: "",
  parameters: "",
  createdAt: "",
};

const AddEventModal = ({
  isOpen,
  setIsOpen,
  currentModalData,
  setNewEventForm,
  newEventForm,
  setCurrentModalData,
  setAnalyticsData,
}) => {
  const { eventNames } = useSelector((state) => state.analytics);
  const { robotsData } = useSelector((state) => state.robots);
  const [errorValidations, setErrorValidations] = useState(paramDefaultStates);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(moment().format("HH:mm"));
  const [showCalendar, setShowCalendar] = useState(false);

  const [appNames, setAppNames] = useState([]);

  const { client, chosenClient } = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(currentModalData).length) {
      setNewEventForm(currentModalData);
      setSelectedDate(new Date(currentModalData.createdAt));
      setSelectedTime(
        moment(new Date(currentModalData.createdAt)).format("HH:mm")
      );
    } else {
      setNewEventForm((prev) => ({
        ...prev,
        createdAt: combineDateAndTime(new Date(), moment().format("HH:mm")),
      }));
    }
  }, [currentModalData, setNewEventForm]);

  useEffect(() => {
    if (chosenClient && eventNames.length === 0) {
      getEventNames(chosenClient).then((data) => {
        dispatch(setEventNames(data));
      });
    }
  }, [dispatch, chosenClient, eventNames.length]);

  useEffect(() => {
    chosenClient &&
      getApplicationTechincalnamesByClient(chosenClient).then((data) => {
        setAppNames(data);
      });
  }, [chosenClient]);

  const handleModalClose = () => {
    setIsOpen(false);

    setErrorValidations(paramDefaultStates);
    setCurrentModalData({});
  };

  const handleChange = (inputValue, fieldName) => {
    let obj = { ...newEventForm, [fieldName]: inputValue };

    setNewEventForm(obj);
    setErrorValidations((prev) => ({ ...prev, [fieldName]: "" }));
  };

  // Handle Date change
  const handleDateChange = (value) => {
    setSelectedDate(value);
    const combinedDateTime = combineDateAndTime(value, selectedTime);
    handleChange(combinedDateTime, "createdAt");
  };

  // Handle Time change
  const handleTimeChange = (value) => {
    setSelectedTime(value);
    const combinedDateTime = combineDateAndTime(selectedDate, value);
    handleChange(combinedDateTime, "createdAt");
  };

  const validateForm = () => {
    let errorFlag = false;
    const validations = { ...paramDefaultStates };

    Object.keys(newEventForm).forEach((key) => {
      if (!newEventForm[key]) {
        validations[key] = "This field cannot be empty";
        errorFlag = true;
      } else if (key === "parameters") {
        try {
          JSON.parse(newEventForm[key]);
        } catch (error) {
          validations[key] = "This field must be a valid JSON";
          errorFlag = true;
        }
      }
    });

    setErrorValidations(validations);
    return errorFlag;
  };

  const handleSubmit = async () => {
    if (validateForm()) return;
    setLoading(true);
    const newAnalytics = await addAnalyticsLog({
      id: isEmpty(currentModalData) ? undefined : currentModalData.id,
      app_name: newEventForm.application,
      clientName: client.name,
      robotId: newEventForm.robot,
      event: newEventForm.event,
      parameters: JSON.parse(newEventForm.parameters),
      createdAt: newEventForm.createdAt,
    });
    setAnalyticsData((prev) => {
      const index = prev.findIndex((item) => item.id === newAnalytics.id);
      if (index > -1) {
        const updatedData = [...prev];
        updatedData[index] = newAnalytics;
        return updatedData;
      } else return [...prev, newAnalytics];
    });
    setLoading(false);
    handleModalClose();
  };

  return (
    <ModalOutline
      isOpen={isOpen}
      heading={
        Object.keys(currentModalData).length
          ? STRINGS.edit_event
          : STRINGS.add_new_event
      }
      onRequestClose={handleModalClose}
      buttonText={STRINGS.create}
      newEventForm={newEventForm}
      //onSubmit={submitForm}
    >
      <ModalContentForm>
        <InputWrapper>
          <SearchableSelect
            value={newEventForm?.event || "Choose event"}
            handleChange={(val) => handleChange(val, "event")}
            options={eventNames}
            error={errorValidations.event}
          />
        </InputWrapper>

        <InputWrapper>
          <SearchableSelect
            value={newEventForm?.robot || "Choose robot"}
            handleChange={(val) => handleChange(val, "robot")}
            options={robotsData.map((item) => item.robotId)}
            error={errorValidations.robot}
          />
        </InputWrapper>

        <InputWrapper>
          <SearchableSelect
            value={newEventForm.application || "Choose application"}
            handleChange={(val) => handleChange(val, "application")}
            options={appNames}
            error={errorValidations.application}
          />
        </InputWrapper>

        <InputWrapper>
          <InputText
            width="42.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={STRINGS.parameters}
            handleChange={(e) => handleChange(e, "parameters")}
            name={STRINGS.parameters}
            value={newEventForm.parameters || ""}
            error={errorValidations.parameters}
            backgroundColor="#fff"
          />
        </InputWrapper>

        <InputWrapper>
          <DateAndTimeWrapper>
            <div style={{ width: "100%" }}>
              <DateBox
                showCalendar={showCalendar}
                onClick={() => setShowCalendar((prev) => !prev)}
              >
                {moment(selectedDate).format("dddd, MMM Do")}
              </DateBox>
            </div>
            <div style={{ width: "100%" }}>
              <DateBox>
                <TimePicker
                  format={"HH:mm"}
                  disableClock={true}
                  clearIcon={false}
                  onChange={handleTimeChange}
                  value={selectedTime}
                />
              </DateBox>
            </div>
          </DateAndTimeWrapper>
          {showCalendar && (
            <div style={{ position: "absolute", zIndex: "9999" }}>
              <OutsideClickHandler
                onOutsideClick={() => setShowCalendar(false)}
              >
                <Calendar onChange={handleDateChange} value={selectedDate} />
              </OutsideClickHandler>
            </div>
          )}
        </InputWrapper>
      </ModalContentForm>

      <ModalFooter>
        {loading ? (
          <Loader height={50} noText noMargin padding={0} />
        ) : (
          <CustomButton
            backgroundColor={COLORS.secondary.ncs}
            type="submit"
            capitalize
            onClick={handleSubmit}
          >
            {Object.keys(currentModalData).length
              ? STRINGS.update_client
              : STRINGS.create}
          </CustomButton>
        )}
      </ModalFooter>
    </ModalOutline>
  );
};

export default AddEventModal;
