import { ROBOTSTATUSENUM } from "../../enums";

export const determineRobotStatusText = (robot) => {
  switch (robot?.robot_manager?.status?.toUpperCase()) {
    case ROBOTSTATUSENUM.AVAILABLE.toUpperCase():
      return "Robot is available";

    case ROBOTSTATUSENUM.UNAVAILABLE.toUpperCase():
      return (
        robot?.current_task?.name?.split("_")?.join(" ") ||
        robot?.currentApplication
      );

    case ROBOTSTATUSENUM.CHARGING.toUpperCase():
      return "Robot is at the charging station";

    case ROBOTSTATUSENUM.ERROR.toUpperCase():
      return robot?.current_task?.name?.split("_")?.join(" ");

    case ROBOTSTATUSENUM.OFF.toUpperCase():
      return "Robot is not connected";

    case ROBOTSTATUSENUM.PAUSED.toUpperCase():
      return "The emergency button has been activated";

    default:
      return "Robot not connected";
  }
};
