import styled from "styled-components";
import ICONS from "../../constants/icons";

export const StyledSelectType = styled.div`
  appearance: none;
  background-image: ${(props) =>
    props.showOptions ? `url(${ICONS.ArrowUp})` : `url(${ICONS.ArrowDown})`};
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: ${(props) => (props.isMobile ? "14px" : "18px")};
  color: #909491;
  font-size: 16px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4;")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff;"};
  width: 100%;
  height: 50px;
  border: ${(props) => (props.disabled ? "none" : "2px solid #c4c4c4")};
  border-radius: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
`;
export const StyledOptionsBox = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  /* padding: 5px 20px; */
  margin-top: 1px;
  z-index: 99999;
  overflow: auto;
  max-height: 230px;
  cursor: pointer;
`;

export const StyledOptionType = styled.div`
  /* padding: 7px 0px; */
  display: flex;
  align-items: center;
  color: "#909491";
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  padding: 13px 19px;

  display: flex;
  gap: 10px;
  width: 100%;
  cursor: pointer;
`;

export const StyledSelectMultiplyChoiceWrapper = styled.div`
  position: relative;
  width: 100%;
  > div {
    width: 100%;
  }
`;
