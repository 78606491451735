import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import socket from "../../APIs/socket/socket";
import MapComponent from "../robots/MapComponent";
import { ModalWarningHeader } from "../../components/robot-task-warning-modal/components";
import ICONS from "../../constants/icons";
import {
  CloseIconWrapper,
  ModalActionTitle,
  ModalActionSubtitle,
  StyledActionContentWrapper,
  Button1,
  Button2,
  StyledButtonsWrapper,
  StyledTopControllsWrapper,
  StyledRotationWrapper,
} from "./components";
import { RobotsDataContext } from "../../contexts/RobotsDataContext";
import rotateRobot from "../../APIs/robots/rotateRobot";
import StyledSelectForStreaming from "../robots/streaming/StyledSelectForStreaming";
import getRobotCameraList from "../../APIs/robots/getRobotCameraList";
import sendMessageToRobotApp from "../../APIs/robots/sendMessageToApp";
import { clearFinishedTasks, setStreamPopup } from "../../redux/robots";
import KeepMounted from "../../helpers/KeepMounted";
import { LoaderSignButton } from "../../components/loader/components";
import ConfirmCloseModal from "./ConfirmCloseModal";
import moveRobotLinear from "../../APIs/robots/moveRobotLinear";
import CustomModal from "../../components/custom-modal/CustomModal";
import { ROBOTTASKSTATUSENUM } from "../../enums";

const RobotActions = () => {
  const { client } = useSelector((state) => state.clients);
  const { streamPopup, inProgressTasks } = useSelector((state) => state.robots);
  const { RobotsData } = useContext(RobotsDataContext);
  const [choesnComponent, setChosenComponent] = useState("camera");
  const [rotateLoading, setRotateLoading] = useState(false);
  const [buttonsLoading, setButtonsLoding] = useState({
    button1: false,
    button2: false,
  });
  const [actionData, setActionData] = useState();
  const [cameraList, setCameraList] = useState([]);
  const [chosenCamera, setChosenCameraType] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  // const [streamError, setStreamError] = useState(false);
  const [streamingModalSize, setStreamingModalSize] = useState(0);
  const [modalStyles, setModalStyles] = useState();

  const [movesPressed, setMovesPressed] = useState({
    front: false,
    back: false,
  });

  const actionComponentWrapperRef = useRef();
  const dispatch = useDispatch();
  const setIsOpen = useCallback(
    (state) => {
      dispatch(setStreamPopup(state));
      setStreamingModalSize(state ? 2 : 1);
    },
    [dispatch]
  );

  const requestClose = useCallback(() => {
    setIsOpen(false);
    setCloseModal(false);
    setStreamingModalSize(false);
  }, [setIsOpen]);
  const handleComponentChange = (comp) => setChosenComponent(comp);

  const currentRobot = useMemo(
    () => RobotsData.find((item) => item.robotId === actionData?.robotId),
    [RobotsData, actionData?.robotId]
  );

  useEffect(() => {
    if (streamingModalSize === 2) {
      setModalStyles({
        height: "80%",
        width: "65%",
        transform: "translate(-50%, -50%)",
        offsetx: -window.innerWidth * 0.65 * 0.5,
        offsety: -window.innerHeight * 0.8 * 0.5,
      });
    }
    if (streamingModalSize === 3) {
      setModalStyles({
        height: "95%",
        width: "95%",
      });
    }
    if (streamingModalSize === 1) {
      setModalStyles({
        height: "430px",
        width: "340px",
        // transform: "translate(-10%, -10%)",
        left: "auto",
        right: "0",
        bottom: "0px",
        // offsetx: window.innerWidth * 0.8 - 10,
        // offsety: window.innerHeight - 440,
        outline: "rgba(0, 0, 0, 0.2) solid 1px",
      });
    }
  }, [streamingModalSize]);

  useEffect(() => {
    if (client?.name) {
      socket.on("action_data", (data) => {
        // if (data?.content?.details?.close) setIsOpen(false);
        setIsOpen(true);
        setActionData({
          ...data,
          clientName: client?.name,
        });
        setChosenCameraType(data?.content?.details?.camera);
      });

      return () => {
        socket.off("action_data");
      };
    }
  }, [client, setIsOpen]);

  useEffect(() => {
    if (
      inProgressTasks.find(
        (el) =>
          el?.status !== ROBOTTASKSTATUSENUM.INPROGRESS &&
          el?.robot?.robotId === actionData?.robotId &&
          el?.application.technicalName === actionData?.app_id
      )
    ) {
      requestClose();
    }
    const finishedApp = inProgressTasks.find(
      (el) => el?.status !== ROBOTTASKSTATUSENUM.INPROGRESS
    );
    if (finishedApp) {
      dispatch(clearFinishedTasks());
    }
  }, [inProgressTasks, actionData, requestClose, dispatch]);

  const handleRotation = useCallback(
    async (angle) => {
      if (rotateLoading) return;
      setRotateLoading(true);
      await rotateRobot(currentRobot?.robotId, angle);
      setRotateLoading(false);
    },
    [currentRobot?.robotId, rotateLoading]
  );

  const handleLinearMovement = useCallback(
    async (distance) => {
      if (distance > 0 && movesPressed.front) return;
      if (distance < 0 && movesPressed.back) return;
      if (rotateLoading) return;
      setRotateLoading(true);
      await moveRobotLinear(currentRobot?.robotId, distance);
      if (distance > 0 && !movesPressed.front)
        setMovesPressed((prev) => ({ ...prev, front: true }));
      if (distance < 0 && !movesPressed.back)
        setMovesPressed((prev) => ({ ...prev, back: true }));
      setRotateLoading(false);
    },
    [currentRobot?.robotId, rotateLoading, movesPressed]
  );

  const sendMessageToApp = useCallback(
    async (buttonNum, message) => {
      setButtonsLoding((prev) => ({
        ...prev,
        ["button" + buttonNum]: true,
      }));
      try {
        await sendMessageToRobotApp(
          currentRobot?.robotId,
          message,
          actionData?.app_id,
          actionData?.command
        );
        setIsOpen(false);
        setStreamingModalSize(1);
      } catch (error) {
        console.log(error);
      }

      setButtonsLoding({
        button1: false,
        button2: false,
      });
      setMovesPressed({
        front: false,
        back: false,
      });
    },
    [currentRobot?.robotId, actionData?.app_id, actionData?.command, setIsOpen]
  );

  useEffect(() => {
    currentRobot?.id &&
      actionData?.camera &&
      getRobotCameraList(currentRobot?.id)
        .then((data) => {
          setCameraList(data);
        })
        .catch((err) => console.log(err));
  }, [currentRobot?.id, actionData?.camera]);

  return (
    <CustomModal
      onRequestClose={requestClose}
      isOpen={!!streamPopup || streamingModalSize > 0}
      width={modalStyles?.width}
      height={modalStyles?.height}
      transform={modalStyles?.transform}
      modalStyles={modalStyles}
      shouldCloseOnOverlayClick={false}
      showBackground={streamingModalSize > 1}
      isDraggable={streamingModalSize === 1}
    >
      {streamingModalSize !== 3 && (
        <ModalWarningHeader>
          <CloseIconWrapper onClick={() => setCloseModal(true)}>
            <img src={ICONS.BlackCross} alt="X" />
          </CloseIconWrapper>
          <ModalActionTitle>{actionData?.title || null}</ModalActionTitle>
          {streamingModalSize !== 1 && (
            <ModalActionSubtitle>
              {actionData?.subtitle || null}
            </ModalActionSubtitle>
          )}
        </ModalWarningHeader>
      )}

      <StyledActionContentWrapper
        fullscreen={streamingModalSize === 3}
        subtitle={actionData?.subtitle}
        style={{ height: streamingModalSize === 1 && "260px" }}
        ref={actionComponentWrapperRef}
      >
        <KeepMounted chosen={choesnComponent}>
          <React.Fragment key={"map"}>
            <MapComponent
              mapWidth={actionComponentWrapperRef?.current?.clientWidth}
              mapHeight={actionComponentWrapperRef?.current?.clientHeight}
              pointWidth={0}
              robotWidth={20}
              showToolTip={true}
              // onlyRobotId={actionData?.robotId}
            />
            <img
              onClick={() => handleComponentChange("camera")}
              src={ICONS.Camera}
              alt="Camera"
              style={{
                position: "absolute",
                right: "15px",
                cursor: "pointer",
                top: "8px",
              }}
            />
          </React.Fragment>
          <React.Fragment key={"camera"}>
            {streamingModalSize !== 1 && (
              <StyledSelectForStreaming
                width={"190px"}
                options={cameraList}
                chosenValue={chosenCamera}
                setChosenValue={setChosenCameraType}
                fromPopup
              />
            )}

            {streamingModalSize !== 1 && (
              <StyledTopControllsWrapper>
                <img
                  src={ICONS.turn360}
                  alt=""
                  style={{
                    borderRadius: "20px",
                    background: rotateLoading
                      ? "rgba(212, 212, 212, 1)"
                      : "#7FC7D9",
                    padding: "10px",
                    height: "49px ",
                    cursor: rotateLoading ? "not-allowed" : "pointer",
                    marginRight: "10px",
                  }}
                  onClick={() => handleRotation(360)}
                />
                <img
                  src={ICONS.ButtonArrow}
                  alt=""
                  onClick={() => handleLinearMovement(1)}
                  style={{
                    background:
                      rotateLoading || movesPressed.front
                        ? "rgba(212, 212, 212, 1)"
                        : "#40A2D8",
                    width: "49px",
                    height: "49px ",
                    padding: "9px",
                    borderRadius: "50%",
                    cursor:
                      rotateLoading || movesPressed.front
                        ? "not-allowed"
                        : "pointer",
                    transform: "rotate(90deg)",
                  }}
                />

                <StyledRotationWrapper>
                  <img
                    src={ICONS.ButtonArrow}
                    alt=""
                    onClick={() => handleRotation(15)}
                    style={{
                      background: rotateLoading
                        ? "rgba(212, 212, 212, 1)"
                        : "#0686d8",
                      cursor: rotateLoading ? "not-allowed" : "pointer",
                      borderRadius: "50%",
                      width: "49px",
                      height: "49px ",
                    }}
                  />
                  Rotate
                  <img
                    src={ICONS.ButtonArrow}
                    alt=""
                    onClick={() => handleRotation(-15)}
                    style={{
                      transform: "rotate(180deg)",
                      background: rotateLoading
                        ? "rgba(212, 212, 212, 1)"
                        : "#0686d8",
                      cursor: rotateLoading ? "not-allowed" : "pointer",
                      borderRadius: "50%",
                      height: "49px ",
                      width: "49px",
                    }}
                  />
                </StyledRotationWrapper>
                <img
                  src={ICONS.ButtonArrow}
                  alt=""
                  onClick={() => handleLinearMovement(-1)}
                  style={{
                    background:
                      rotateLoading || movesPressed.back
                        ? "rgba(212, 212, 212, 1)"
                        : "#40A2D8",
                    width: "49px",
                    height: "49px ",
                    padding: "9px",
                    borderRadius: "50%",
                    cursor:
                      rotateLoading || movesPressed.back
                        ? "not-allowed"
                        : "pointer",
                    transform: "rotate(-90deg)",
                  }}
                />
                <img
                  onClick={() => handleComponentChange("map")}
                  src={ICONS.LocationPin}
                  alt="LocationPin"
                  style={{
                    position: "absolute",
                    right: "55px",
                    cursor: "pointer",
                  }}
                />
                <img
                  src={
                    streamingModalSize === 3
                      ? ICONS.MinimizeFullscreenWhite
                      : ICONS.FullscreenWhite
                  }
                  alt=""
                  style={{
                    position: "absolute",
                    right: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setStreamingModalSize((prev) => (prev === 2 ? 3 : 2))
                  }
                />
              </StyledTopControllsWrapper>
            )}
          </React.Fragment>
        </KeepMounted>
      </StyledActionContentWrapper>

      {streamingModalSize === 2 && (
        <StyledButtonsWrapper
          style={{ gap: streamingModalSize === 1 && "10px" }}
        >
          {actionData?.button1 && (
            <Button2
              disabled={buttonsLoading.button1 || buttonsLoading.button2}
              onClick={() => sendMessageToApp(1, actionData.button1)}
            >
              {buttonsLoading.button1 ? (
                <LoaderSignButton noMargin={true} />
              ) : (
                actionData.button1
              )}
            </Button2>
          )}
          {actionData?.button2 && (
            <Button1
              disabled={buttonsLoading.button1 || buttonsLoading.button2}
              onClick={() => sendMessageToApp(2, actionData.button2)}
            >
              {buttonsLoading.button2 ? (
                <LoaderSignButton noMargin={true} />
              ) : (
                actionData.button2
              )}
            </Button1>
          )}
        </StyledButtonsWrapper>
      )}
      <ConfirmCloseModal
        requestCloseMain={requestClose}
        isOpen={closeModal}
        setCloseModal={setCloseModal}
      />
    </CustomModal>
  );
};

export default RobotActions;
