import React, { useContext, useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import getApplications from "../../APIs/application/getApplications";
import AssignmentDetailsWrapper from "../../components/agenda-assignment-details-modal/AssignmentDetailsWrapper";
import ImmediateTaskModal from "../../components/immediate-task-modal/ImmediateTaskModal";
import { setAppsData } from "../../redux/applications";
import { handleAgendaRevert, setITModal } from "../../redux/dashboard";
import AgendaTable from "./AgendaTable";
import {
  AgendaPageWrapper,
  DashboardTitle,
  LeftSideDiv,
  RightPageWrapper,
  WhiteWrapper,
} from "./components";
import RobotFleet from "./robot-fleet/RobotFleet";
import Shortcuts from "./shortcuts/Shortcuts";
import useRevert from "../../hooks/useRevert";
import FLoatButtonAgenda from "../../components/Flaoting-menu/FLoatButtonAgenda";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const Dashboard = () => {
  const { isMobile } = useContext(ResponsiveContext);
  const { chosenClient, currentUser } = useSelector((state) => state.clients);

  const [isOpen, setIsOpen] = React.useState([]);

  // eslint-disable-next-line no-unused-vars
  const _ = useRevert("clickRevert", () => {
    dispatch(handleAgendaRevert());
  });

  const dispatch = useDispatch();
  useEffect(() => {
    chosenClient &&
      getApplications(chosenClient).then((data) => {
        dispatch(setAppsData(data));
      });
  }, [chosenClient, dispatch]);

  const onITModalClose = useCallback(() => {
    dispatch(setITModal({ isOpen: false, robotName: "" }));
  }, [dispatch]);

  return (
    <div style={{ display: "flex", gap: "20px", height: "100%" }}>
      <LeftSideDiv>
        {!isMobile && (
          <WhiteWrapper
            style={{ padding: isMobile ? "14px" : "24px" }}
            isMobile={isMobile}
          >
            <DashboardTitle isMobile={isMobile}>
              Welcome Back{" "}
              {!currentUser?.roles?.all &&
                currentUser?.firstName + " " + currentUser?.lastName}
              !
            </DashboardTitle>
          </WhiteWrapper>
        )}
        {isMobile && <FLoatButtonAgenda />}
        <AgendaPageWrapper isMobile={isMobile}>
          <AgendaTable setIsOpen={setIsOpen} />
        </AgendaPageWrapper>
      </LeftSideDiv>
      {!isMobile && (
        <RightPageWrapper>
          <Shortcuts />
          <RobotFleet />
        </RightPageWrapper>
      )}
      <AssignmentDetailsWrapper isOpen={isOpen} setIsOpen={setIsOpen} />
      <ImmediateTaskModal onRequestClose={onITModalClose} />
    </div>
  );
};

export default Dashboard;
