import React, { useCallback, useState } from "react";
import {
  ColumnNumber,
  ErrorMessage,
  IconsContainer,
  IconsContainerWrapper,
  IconWrapper,
  InputTitle,
  InputWrapper,
  RowUnderLine,
  StyledIcon,
  StyledOption,
  StyledSelect,
  TaskRow,
} from "./components";
import ICONS from "../../../constants/icons";
import InfoModal from "../../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../../constants/types";
import deleteTask from "../../../APIs/tasks/deleteTask";
import TaskParametersEditMode from "../../../components/agenda-assignment-details-modal/TaskParametersEditMode";
import { useSelector } from "react-redux";
import { ROLESENUM } from "../../../enums";
import { setParams } from "../../../utils/setParams";
import { Draggable } from "react-beautiful-dnd";

const TaskRowComponent = ({
  index,
  item,
  appsData,
  chosenAssignment,
  setChosenAssignment,
  handleSave,
  isError,
  setIsError,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const { currentRole } = useSelector((state) => state.clients);
  const { locations } = useSelector((state) => state.maps);

  const handleEdit = (id) => {
    setChosenAssignment((prev) => ({
      ...prev,
      tasks: prev.tasks.map((item) =>
        item.id === id ? { ...item, disabled: !item.disabled } : item
      ),
    }));
  };

  const handleSelectChange = (e, id) => {
    setIsError(false);
    const found = {
      ...appsData.find((item) => item.id === e.target.value),
    };

    setChosenAssignment((prev) => ({
      ...prev,
      tasks: prev.tasks.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            application: found,
            parameters: setParams(found, locations),
          };
        } else return item;
      }),
    }));
  };
  const handleParametersChange = useCallback(
    (inputValue, taskId, paramName) => {
      setIsError(false);
      let obj = {
        ...chosenAssignment,
        tasks: chosenAssignment.tasks.map((item) => {
          if (item.id === taskId) {
            let params = { ...item.parameters };
            params[paramName] = inputValue;
            return { ...item, parameters: params };
          } else return item;
        }),
      };
      setChosenAssignment(obj);
    },
    [chosenAssignment, setChosenAssignment, setIsError]
  );

  const handleDelete = async (id) => {
    await deleteTask(id);
    setChosenAssignment((prev) => ({
      ...prev,
      tasks: chosenAssignment?.tasks?.filter((item) => item.id !== id),
    }));
  };

  return (
    <React.Fragment>
      <Draggable
        key={item?.id}
        index={index}
        draggableId={item?.id?.toString()}
      >
        {(provided) => (
          <>
            <TaskRow
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onSubmit={(e) => {
                e.preventDefault();
                ![ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(
                  currentRole
                ) && handleSave(e, item);
              }}
            >
              <ColumnNumber>{index + 1}.</ColumnNumber>
              <InputWrapper>
                <InputTitle>Task</InputTitle>

                <StyledSelect
                  onClick={(e) => e.stopPropagation()}
                  disabled={item.disabled}
                  value={item.aliasName}
                  onChange={(e) => handleSelectChange(e, item.id)}
                >
                  <StyledOption value={0} defaultValue={false}>
                    Choose Task
                  </StyledOption>
                  {appsData.map((app, ind) => (
                    <StyledOption
                      key={ind}
                      value={app.id}
                      selected={app.aliasName === item?.application?.aliasName}
                    >
                      {app.aliasName}
                    </StyledOption>
                  ))}
                </StyledSelect>
                {isError &&
                  (!item?.application ||
                    !Object.keys(item?.application).length) && (
                    <ErrorMessage>*This field can not be empty</ErrorMessage>
                  )}
              </InputWrapper>

              <TaskParametersEditMode
                item={item}
                handleParametersChange={handleParametersChange}
                assignmentDetails={chosenAssignment}
                setAssignmentsDetails={setChosenAssignment}
                isError={isError}
              />
              {item.disabled && (
                <IconsContainerWrapper className="icons-container">
                  {item.disabled && currentRole !== ROLESENUM.VIEWER && (
                    <IconsContainer>
                      <IconWrapper
                        background={"rgba(6, 134, 216, 0.1)"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(item.id);
                        }}
                      >
                        <StyledIcon src={ICONS.PencilDraw} />
                      </IconWrapper>
                      <IconWrapper
                        background={"rgba(212, 47, 57, 0.1)"}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsDeleteModal(true);
                        }}
                      >
                        <StyledIcon src={ICONS.DeleteIcon} />
                      </IconWrapper>
                    </IconsContainer>
                  )}
                </IconsContainerWrapper>
              )}
              {!item.disabled && (
                <IconsContainerWrapper className="icons-container">
                  {!item.disabled && (
                    <IconsContainer>
                      <IconWrapper
                        type="submit"
                        background={"rgba(255, 193, 35, 0.1)"}
                      >
                        <StyledIcon src={ICONS.SaveDisk} />
                      </IconWrapper>
                    </IconsContainer>
                  )}
                </IconsContainerWrapper>
              )}

              <InfoModal
                isOpen={isDeleteModal}
                setIsDeleteModal={setIsDeleteModal}
                type={InfoModalTypes.ATTENTION}
                itemId={item.id}
                title="Are you sure to delete ?"
                noOuterClickClosing
                cancelAction={() => setIsDeleteModal(false)}
                submitAction={() => {
                  handleDelete(item.id);
                  setIsDeleteModal(false);
                }}
                cancelText={"No"}
                submitText={"Yes"}
                disabled={false}
              />
            </TaskRow>
            <RowUnderLine />
          </>
        )}
      </Draggable>
    </React.Fragment>
  );
};

export default TaskRowComponent;
