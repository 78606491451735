import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AssignmentsContext } from "../../contexts/AssignmentsContext";
import useAssignments from "../../hooks/useAssignments";
import useAssignmentsModal from "../../hooks/useAssignmentsModal";
import {
  handleAssignmentDetailsUpdate,
  setAssignmentEditMode,
  setDeleteAssignment,
  setDeleteTask,
  setEditTaskData,
  setStopAssignmentData,
  setStopTaskData,
  updateAssignmentStatus,
  updateSingleAssignmentPercentage,
} from "../../redux/dashboard";
import { detectChanges } from "../../utils/detectChanges";
import Drawer from "../Drawer/Drawer";
import AssignmentDetails from "./AssignmentDetails";
import AssignmentDetailsEditMode from "./AssignmentDetailsEditMode";
import ConfirmAssignmentChangesContent from "./ConfirmAssignmentChangesContent";
import DeleteAssignmentModalContent from "./DeleteAssignmentModalContent";
import DeleteAssignmentRunEvery from "./DeleteAssignmentRunEvery";
import { useCallback } from "react";
import useTasks from "../../hooks/useTasks";
import ConfirmChangeModal from "./assignment-details-modals/ConfirmChangeModal";
import EditTaskModalRunEvery from "./assignment-details-modals/EditTaskModalRunEvery";
import DeleteTaskModal from "./assignment-details-modals/DeleteTaskModal";
import EditAssignmentModal from "./assignment-details-modals/EditAssignmentModal";
import DeleteTaskRunEveryModal from "./assignment-details-modals/DeleteTaskRunEveryModal";
import DiscardChangesModalContent from "./assignment-details-modals/DiscardChangesModalContent";
import StopTaskModal from "./assignment-details-modals/StopTaskModal";
import StopAssignmentModal from "./assignment-details-modals/StopAssignmentModal";
import getTasksFromArchiveById from "../../APIs/tasks-archive/getTaskArchiveById";

const AssignmentDetailsWrapper = ({
  isOpen,
  setIsOpen = () => {},
  calendar,
  setDrawerRef,
  leftSide,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscardModal, setIsDiscardModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isEditAssignmentModal, setIsEditAssignmentModal] = useState(false);
  const [isDeleteAssignmentRunEvery, setIsDeleteAssignmentRunEvery] =
    useState(false);
  const { assignmentDetails, agendaData } = useSelector(
    (state) => state.dashboard
  );
  const [assignmentDetailsCopy, setAssignmentDetailsCopy] =
    useState(assignmentDetails);

  const dispatch = useDispatch();
  const {
    deleteTaskData,
    isAssignmentEditMode,
    editTaskData,
    stopTaskData,
    stopAssignmentData,
  } = useSelector((state) => state.dashboard);
  const { assignmentsData, setAssignmentsData } =
    useContext(AssignmentsContext);
  const {
    newAssignmentForm,
    setNewAssignmentForm,
    currentModalData,
    setCurrentModalData,
    handleDelete,
  } = useAssignments({
    setAssignmentsData,
    assignmentsData,
  });

  const {
    handleSubmit,
    hadnleSubmitOTE,
    errorText,
    isValidAssignment,
    createDuplicatedRunOn,
  } = useAssignmentsModal({
    newAssignmentForm,
    setNewAssignmentForm,
    setIsOpen: setIsModalOpen,
    assignmentsData,
    setAssignmentsData,
    calendar,
  });

  const { handleSave, handleSaveException, handleTaskDelete } = useTasks({
    assignmentDetails: assignmentDetailsCopy,
    setAssignmentsDetails: setAssignmentDetailsCopy,
  });

  useEffect(() => {
    let timeoutOpen, timeoutClose;
    if (assignmentDetails) {
      setAssignmentDetailsCopy(assignmentDetails);
      timeoutOpen = setTimeout(() => {
        setIsOpen(true);
      }, 100);
    } else {
      setIsOpen(false);
      timeoutClose = setTimeout(() => {
        setAssignmentDetailsCopy(assignmentDetails);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutOpen);
      clearTimeout(timeoutClose);
    };
  }, [assignmentDetails, setIsOpen]);
  const handleBackBtnClick = () => {
    const changes = detectChanges(currentModalData, newAssignmentForm);
    if (newAssignmentForm.isCreated) {
      changes
        ? setIsDiscardModal(true)
        : dispatch(setAssignmentEditMode(false));
    } else {
      changes
        ? setIsDiscardModal(true)
        : dispatch(handleAssignmentDetailsUpdate(null));
    }
  };

  const handleDeleteAssignment = useCallback(async () => {
    await handleDelete(assignmentDetails.id);
    setIsOpen(false);
    setIsDeleteModal(false);
    setTimeout(() => {
      setAssignmentDetailsCopy(null);
      dispatch(setDeleteAssignment(assignmentDetails));
      dispatch(handleAssignmentDetailsUpdate(null));
    }, 1000);
  }, [assignmentDetails, dispatch, setIsOpen, handleDelete]);

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    let taskId = params.get("taskId");
    if (taskId) {
      getTasksFromArchiveById(taskId)
        .then((data) => {
          const assignmentTemplate = {
            assignment: true,
            ...data[0].assignment,
            tasks: [...data],
          };
          dispatch(
            handleAssignmentDetailsUpdate(
              updateAssignmentStatus(
                updateSingleAssignmentPercentage(assignmentTemplate)
              )
            )
          );
        })
        .catch((e) => console.error(e));
    }
  }, [agendaData, dispatch]);

  return (
    <React.Fragment>
      {assignmentDetailsCopy && (
        <Drawer
          leftSide={leftSide}
          setDrawerRef={setDrawerRef}
          calendar={calendar}
          heading={
            assignmentDetails?.assignment
              ? "Assignment details"
              : "Custom task details"
          }
          isOpen={isOpen}
          onRequestClose={handleBackBtnClick}
          setIsDiscardModal={setIsDiscardModal}
          currentModalData={currentModalData}
          newAssignmentForm={newAssignmentForm}
        >
          {assignmentDetailsCopy && !isAssignmentEditMode && (
            <AssignmentDetails
              calendar={calendar}
              assignmentDetails={assignmentDetailsCopy}
              setAssignmentsDetails={setAssignmentDetailsCopy}
              isOpen={isOpen}
              setIsDeleteModal={setIsDeleteModal}
              setIsDeleteAssignmentRunEvery={setIsDeleteAssignmentRunEvery}
            />
          )}
          {isAssignmentEditMode && assignmentDetailsCopy.assignment && (
            <AssignmentDetailsEditMode
              isOpen={isOpen}
              assignmentDetails={assignmentDetailsCopy}
              setIsModalOpen={setIsModalOpen}
              newAssignmentForm={newAssignmentForm}
              setNewAssignmentForm={setNewAssignmentForm}
              currentModalData={currentModalData}
              setCurrentModalData={setCurrentModalData}
              errorText={errorText}
              setIsDiscardModal={setIsDiscardModal}
              handleBackBtnClick={handleBackBtnClick}
              setIsEditAssignmentModal={setIsEditAssignmentModal}
              isValidAssignment={isValidAssignment}
              handleAssignmentSave={handleSubmit}
            />
          )}
        </Drawer>
      )}
      <ConfirmChangeModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ConfirmAssignmentChangesContent
          handleSubmit={() => {
            handleSubmit(
              newAssignmentForm,
              newAssignmentForm.isCreated ? currentModalData : {},
              null,
              setIsModalOpen
            );
          }}
          onClose={() => setIsModalOpen(false)}
          assignmentDetails={assignmentDetails}
        />
      </ConfirmChangeModal>
      <ConfirmChangeModal
        isOpen={isDiscardModal}
        onRequestClose={() => setIsDiscardModal(false)}
      >
        <DiscardChangesModalContent
          handleSubmit={() => {
            setIsDiscardModal(false);
            newAssignmentForm.isCreated
              ? dispatch(setAssignmentEditMode(false))
              : dispatch(handleAssignmentDetailsUpdate(null));
          }}
          setIsOpen={setIsDiscardModal}
        />
      </ConfirmChangeModal>

      {/* this modal opens when you click delete icon on run on assignment */}

      <ConfirmChangeModal
        isOpen={isDeleteModal}
        onRequestClose={() => setIsDeleteModal(false)}
      >
        <DeleteAssignmentModalContent
          handleDelete={handleDeleteAssignment}
          setIsOpen={setIsDeleteModal}
          assignmentDetails={assignmentDetails}
        />
      </ConfirmChangeModal>

      {/* this modal opens when you click delete icon on run every assignment */}
      <ConfirmChangeModal
        isOpen={isDeleteAssignmentRunEvery}
        onRequestClose={() => setIsDeleteAssignmentRunEvery(false)}
      >
        <DeleteAssignmentRunEvery
          handleDeleteAssignment={handleDeleteAssignment}
          setIsDeleteAssignmentRunEvery={setIsDeleteAssignmentRunEvery}
          hadnleSubmitOTE={hadnleSubmitOTE}
        />
      </ConfirmChangeModal>
      {/* this modal opens when you click delete task in run on assignment */}

      <ConfirmChangeModal
        isOpen={deleteTaskData}
        onRequestClose={() => dispatch(setDeleteTask(null))}
      >
        {assignmentDetailsCopy?.disposable && (
          <DeleteTaskModal
            handleDelete={handleTaskDelete}
            handleCancel={() => dispatch(setDeleteTask(null))}
          />
        )}
        {!assignmentDetailsCopy?.disposable && (
          <DeleteTaskRunEveryModal
            handleDelete={handleTaskDelete}
            handleCancel={() => dispatch(setDeleteTask(null))}
          />
        )}
      </ConfirmChangeModal>

      {/* this modal opens when you click save task in run every assignment */}

      <ConfirmChangeModal
        isOpen={editTaskData}
        onRequestClose={() => dispatch(setEditTaskData(null))}
      >
        <EditTaskModalRunEvery
          handleSave={handleSave}
          handleSaveException={handleSaveException}
        />
      </ConfirmChangeModal>

      {/* this modal opens when you click save changes on run every assignment */}

      <ConfirmChangeModal
        isOpen={isEditAssignmentModal}
        onRequestClose={() => setIsEditAssignmentModal(false)}
      >
        <EditAssignmentModal
          setIsEditAssignmentModal={setIsEditAssignmentModal}
          handleSubmit={handleSubmit}
          newAssignmentForm={newAssignmentForm}
          currentModalData={currentModalData}
          hadnleSubmitOTE={hadnleSubmitOTE}
          createDuplicatedRunOn={createDuplicatedRunOn}
        />
      </ConfirmChangeModal>

      {/* This modal opens when you click stop task icon */}

      <ConfirmChangeModal
        isOpen={stopTaskData}
        onRequestClose={() => dispatch(setStopTaskData(null))}
      >
        <StopTaskModal onRequestClose={() => dispatch(setStopTaskData(null))} />
      </ConfirmChangeModal>

      {/* This modal opens when you click stop assignment icon */}

      <ConfirmChangeModal
        isOpen={stopAssignmentData}
        onRequestClose={() => dispatch(setStopAssignmentData(null))}
      >
        <StopAssignmentModal
          handleCancel={() => dispatch(setStopAssignmentData(null))}
        />
      </ConfirmChangeModal>
    </React.Fragment>
  );
};

export default AssignmentDetailsWrapper;
