import moment from "moment-timezone";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ICONS from "../../constants/icons";
import AssignmentBox from "./AssignmentBox";
import {
  TimeAmount,
  TimeDiv,
  StyledAgendaRow,
  AssignmentBoxesWrapper,
  StyledDraggableWrapper,
} from "./components";
import {
  handleAssignmentDetailsUpdate,
  setAssignmentEditMode,
} from "../../redux/dashboard";
import { AssignmentsContext } from "../../contexts/AssignmentsContext";
import { isTimeFuture } from "../../utils/dateHelper";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { AGENDAASSIGNMENTSSTATUSENUM } from "../../enums";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const AgendaRow = ({ time, assignmentBeforeDrag }) => {
  const { isMobile } = useContext(ResponsiveContext);
  const {
    assignmentDetails,
    isAssignmentEditMode,
    sortedDataForAgenda,
    calendarDate,
  } = useSelector((state) => state.dashboard);
  const { assignmentTemplate } = useContext(AssignmentsContext);
  const dispatch = useDispatch();
  const [showCurrentTime, setShowCurrentTime] = useState({});
  useEffect(() => {
    let date = new Date();
    const currentTime =
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes();
    +currentTime.split(":")[0] === +time.split(":")[0] - 1 &&
      setShowCurrentTime({
        show: true,
        top: (date.getMinutes() * 100) / 60,
      });
  }, [time]);

  const handleRowClick = (e) => {
    if (assignmentDetails && !isAssignmentEditMode) {
      dispatch(handleAssignmentDetailsUpdate(null));
    }
    if (!assignmentDetails || isAssignmentEditMode) {
      let creatingTime = `${+time.split(":")[0] - 1}`;
      if (e.target.offsetHeight / 2 > e.nativeEvent.offsetY) {
        creatingTime = creatingTime + ":00";
      } else {
        creatingTime = creatingTime + ":30";
      }
      dispatch(
        handleAssignmentDetailsUpdate({
          ...assignmentTemplate,
          justCreated: true,
          executionTime: calendarDate,
          time: creatingTime,
        })
      );
      dispatch(setAssignmentEditMode(true));
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#fff",
  });
  const found = sortedDataForAgenda.find((item) => item.isDragging);
  return (
    <Droppable
      droppableId={time}
      key={time}
      isDropDisabled={!isTimeFuture(time, calendarDate)}
    >
      {(provided, snapshot) => (
        <StyledAgendaRow
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{
            ...getListStyle(snapshot.isDraggingOver),
            cursor: isTimeFuture(time, calendarDate) && "pointer",
          }}
          onClick={(e) =>
            !isMobile && isTimeFuture(time, calendarDate) && handleRowClick(e)
          }
          className="clickAllow clickRevert"
          // onMouseEnter={handleDrop}
        >
          <TimeDiv>
            {showCurrentTime.show && (
              <img
                src={ICONS.CurrentTimeLine}
                alt="currentTime"
                style={{ position: "absolute", top: `${showCurrentTime.top}%` }}
              />
            )}
            <TimeAmount>{time === "24:00" ? "00:00" : time}</TimeAmount>
          </TimeDiv>
          <AssignmentBoxesWrapper>
            {assignmentBeforeDrag &&
              +moment(assignmentBeforeDrag?.executionTime).format("HH") ===
                +time.split(":")[0] - 1 && (
                <AssignmentBox item={assignmentBeforeDrag} />
              )}
            {sortedDataForAgenda
              ?.map((item, index) => {
                if (
                  +moment(item.executionTime).format("HH:mm")?.split(":")[0] ===
                    +time?.split(":")[0] - 1 ||
                  +item.executionTime?.split(":")[0] ===
                    +time?.split(":")[0] - 1
                ) {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={
                        !(
                          (item.agendaAssignmentStatus ===
                            AGENDAASSIGNMENTSSTATUSENUM.DEFAULT &&
                            !found) ||
                          found?.id === item.id
                        )
                      }
                    >
                      {(provided) => (
                        <StyledDraggableWrapper
                          key={item.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          width={item.width}
                        >
                          <AssignmentBox key={item.id} item={item} />
                        </StyledDraggableWrapper>
                      )}
                    </Draggable>
                  );
                } else return false;
              })
              .filter((el) => el)}
          </AssignmentBoxesWrapper>
        </StyledAgendaRow>
      )}
    </Droppable>
  );
};

export default AgendaRow;
