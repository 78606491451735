import React, { useEffect, useRef, useState } from "react";
import {
  IconWrapper,
  StyledMapName,
  TeleopHeaderContainer,
} from "../components";
import { StyledSingleLineContainer } from "../../../../components/commonStyles";
import ICONS from "../../../../constants/icons";
import MapComponent from "../../MapComponent";
import MapSelector from "../../../../components/map-selector/MapSelector";
import { useSelector } from "react-redux";
import { setChosenBuilding, setChosenFloor } from "../../../../redux/maps";
import { useDispatch } from "react-redux";
import LocalizingModal from "../../../../components/navigation_map/localize-robot-on-map/LocalizingModal";
import { GoToMap } from "../../../dashboard/components";
import { ROLESENUM } from "../../../../enums";

const TeleopMapComponent = ({
  isFullScreen,
  currentRobot,
  handleMapFullScreen = () => {},
}) => {
  const mapWrapperRef = useRef(null);
  const [userSelected, setUserSelected] = useState(false); // Track if user has selected
  const [prevMapName, setPrevMapName] = useState(null); // Track previous map_name
  const [isLocalizingOpen, setIsLocalizingOpen] = useState(false);
  const [mapWidth, setMapWidth] = useState();
  const [mapHeight, setMapHeight] = useState();
  const { chosenFloor } = useSelector((state) => state.maps);
  const { currentRole } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    setMapWidth(0);
    setMapHeight(0);
  }, [isFullScreen]);

  useEffect(() => {
    setMapWidth(mapWrapperRef?.current?.offsetWidth);
    setMapHeight(mapWrapperRef?.current?.offsetHeight);

    const handleWindowSizeChange = () => {
      setMapWidth(mapWrapperRef?.current?.offsetWidth);
      setMapHeight(mapWrapperRef?.current?.offsetHeight);
    };
    window.addEventListener("resize", () => {
      handleWindowSizeChange();
    });
    return () => {
      window.removeEventListener("resize", () => {
        handleWindowSizeChange();
      });
    };
  }, [mapWidth, mapHeight]);

  useEffect(() => {
    const currentMapName = currentRobot?.localization_status?.map_name;

    if (currentMapName && currentMapName !== prevMapName) {
      const [building, floor] = currentMapName.split("__");

      if (building && floor) {
        if (!userSelected) {
          // Only update if user hasn't selected
          dispatch(setChosenBuilding(building));
          dispatch(setChosenFloor(floor));
        }
        setPrevMapName(currentMapName); // Update previous map_name
      }
    }
  }, [dispatch, currentRobot, userSelected, prevMapName]);

  const handleLocalizeRobot = () => {
    setIsLocalizingOpen(true);
  };

  return (
    <TeleopHeaderContainer style={{ flexDirection: "column" }}>
      <StyledSingleLineContainer
        style={{
          position: "relative",
          justifyContent: "space-between",
          height: "60px",
        }}
      >
        <StyledMapName>{chosenFloor}</StyledMapName>
        <div>
          <MapSelector
            onMapChange={() => {
              setUserSelected(true);
            }}
          />
        </div>

        {[
          ROLESENUM.ADMIN,
          ROLESENUM.TELEOPERATOR,
          ROLESENUM.CLIENTADMIN,
        ].includes(currentRole) && (
          <GoToMap onClick={handleLocalizeRobot}>Localize Robot</GoToMap>
        )}
        <IconWrapper onClick={handleMapFullScreen}>
          <img src={ICONS.QrscanIcon} alt="" />
        </IconWrapper>
      </StyledSingleLineContainer>
      <LocalizingModal
        isOpen={isLocalizingOpen}
        currentRobot={currentRobot}
        onRequestClose={() => setIsLocalizingOpen(false)}
      />
      <div
        style={{
          height: isFullScreen ? "500px" : "300px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          position: "relative",
          pointerEvents: "none",
        }}
        ref={mapWrapperRef}
      >
        {mapHeight && mapWidth && (
          <MapComponent
            mapWidth={mapWidth}
            mapHeight={mapHeight}
            robotWidth={40}
            pointWidth={5}
            onlyRobotId={currentRobot?.id}
          />
        )}
      </div>
    </TeleopHeaderContainer>
  );
};

export default TeleopMapComponent;
