import React from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./ToastMessage.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ToastMessageComponent = ({
  type,
  text,
  id,
  autoClose,
  style,
  hideIcon,
  redirect,
}) => {
  const history = useHistory();

  useEffect(() => {
    toast[type](text, {
      onClick: () => {
        if (redirect) {
          history.push(redirect);
        }
      },
    });
  }, [text, type, id, history, redirect]);

  return (
    <>
      <ToastContainer
        style={style}
        position="bottom-center"
        autoClose={autoClose ? (autoClose > 10000 ? 10000 : autoClose) : 3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        icon={!hideIcon}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={5}
      />
    </>
  );
};
export default ToastMessageComponent;
