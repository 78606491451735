import React from "react";
import {
  ComponentHeader,
  ComponentTitle,
} from "../../admin-page/environment-setup/components";
import STRINGS from "../../../constants/strings";
import TeleopRobotsSelector from "./TeleopRobotsSelector";

const TeleopHeader = ({ selectedRobot, setSelectedRobot }) => {
  return (
    <ComponentHeader>
      <ComponentTitle>{`${STRINGS.teleoperation}`}</ComponentTitle>
      <TeleopRobotsSelector
        selectedRobot={selectedRobot}
        setSelectedRobot={setSelectedRobot}
      />
      <div></div>
    </ComponentHeader>
  );
};

export default TeleopHeader;
