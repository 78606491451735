import React, { useContext, useMemo } from "react";
import Modal from "react-modal";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import ICONS from "../../../constants/icons";
import Loader from "../../../components/loader/Loader";
import {
  ITMWrapper,
  LeftDone,
  LeftError,
  LeftLoading,
  StyledRobotImg,
} from "../../../components/immediate-task-modal/components";
import robotPNG from "../../../assets/png/robot.png";
import { NotAllowedSVG } from "../../../assets/NotAllowedSVG";
import COLORS from "../../../constants/colors";

const ConfigurationResponse = ({
  responseModalType,
  onRequestClose = () => {},
}) => {
  const { isMobile } = useContext(ResponsiveContext);
  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "999",
    },

    content: {
      zIndex: "999",
      width: isMobile ? "80%" : "649px",
      height: "320px",
      overflow: "unset",
      top: "50%",
      left: "50%",
      right: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };
  const renderContent = useMemo(() => {
    switch (responseModalType) {
      case "loading":
        return (
          <LeftLoading isMobile={isMobile}>
            <h1>Sending Configuration To Robot</h1>
            <Loader height={0} noText noMargin padding={0} />
            <button onClick={onRequestClose}>OK</button>
          </LeftLoading>
        );

      case "error":
        return (
          <LeftError isMobile={isMobile}>
            <h1>Couldn't Send Data</h1>
            <NotAllowedSVG />
            {!isMobile && (
              <p>Error in sending battery configuration data to the robot.</p>
            )}
            <button onClick={onRequestClose}>OK</button>
          </LeftError>
        );

      case "done":
        return (
          <LeftDone isMobile={isMobile}>
            <h1>Great! Configuration sent to robot</h1>
            <img src={ICONS.Checked} alt="Done" />
            <button onClick={onRequestClose}>OK</button>
          </LeftDone>
        );
      default:
        break;
    }
  }, [responseModalType, onRequestClose, isMobile]);
  return (
    <Modal
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      isOpen={!!responseModalType}
    >
      <ITMWrapper>
        {renderContent}
        <StyledRobotImg isMobile={isMobile} src={robotPNG} alt="robot" />
      </ITMWrapper>{" "}
    </Modal>
  );
};

export default ConfigurationResponse;
