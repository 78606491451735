import React from "react";
import {
  StyledErrorPageTitle,
  StyledErrorPageWrapper,
  StyledErrorText,
  StyledImgWrapper,
  StyledTryAgainBtn,
} from "../components";
import ICONS from "../../../constants/icons";
import STRINGS from "../../../constants/strings";

const StreamingErrorScreen = ({ setShowCameraStream, setStreamError }) => {
  return (
    <StyledErrorPageWrapper>
      <StyledErrorPageTitle>{STRINGS.streaming}</StyledErrorPageTitle>
      <StyledImgWrapper>
        <img src={ICONS.ErrorRobotIcon} alt="error" style={{ width: "100%" }} />
        <StyledErrorText>Could not connect to robot cameras </StyledErrorText>
      </StyledImgWrapper>
      <StyledTryAgainBtn
        onClick={() => {
          setShowCameraStream(true);
          setStreamError(false);
        }}
      >
        Try Again
      </StyledTryAgainBtn>
    </StyledErrorPageWrapper>
  );
};

export default StreamingErrorScreen;
