import React from "react";
import SearchableSelect from "../searchable-select/SearchableSelect";
import { setChosenBuilding, setChosenFloor } from "../../redux/maps";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const MapSelector = ({ showBuilding, onMapChange = () => {} }) => {
  const dispatch = useDispatch();
  const {
    mapsData,
    floorOptions,
    buildingOptions,
    chosenFloor,
    chosenBuilding,
  } = useSelector((state) => state.maps);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      {(mapsData?.length > 1 || showBuilding) && (
        <div style={{ width: "180px", marginTop: "10px" }}>
          <SearchableSelect
            value={chosenBuilding}
            handleChange={(val) => {
              dispatch(setChosenBuilding(val));
              onMapChange();
            }}
            options={buildingOptions}
          />
        </div>
      )}
      {(mapsData?.length > 1 || mapsData[0]?.floors?.length > 1) && (
        <div style={{ width: "180px", marginTop: "10px" }}>
          <SearchableSelect
            value={floorOptions.find((el) => el.value === chosenFloor)}
            options={floorOptions}
            handleChange={(val) => {
              dispatch(setChosenFloor(val));
              onMapChange();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MapSelector;
