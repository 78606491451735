import moment from "moment";
import { ROBOTTASKSTATUSENUM } from "../../enums";

export const getTimeInfo = (item, isMobile) => {
  if (!item) return null;
  if (
    [
      ROBOTTASKSTATUSENUM.DONE,
      ROBOTTASKSTATUSENUM.ERROR,
      ROBOTTASKSTATUSENUM.Failed,
      ROBOTTASKSTATUSENUM.OFFLINE,
      // ROBOTTASKSTATUSENUM.DELAYED,
    ].includes(item?.status)
  ) {
    if (isMobile) {
      return `${moment(item.createdAt).format("HH:mm")}`;
    } else {
      return `${moment(item.createdAt).format("HH:mm")} - ${moment(
        item.updatedAt
      ).format("HH:mm")}`;
    }
  }

  if (ROBOTTASKSTATUSENUM.DELAYED === item?.status) {
    return `${moment(item.createdAt).format("HH:mm")}`;
  }

  return null; // Default return value if conditions are not met
};

export const formatUpdatedAtTime = (updatedAt) => {
  return updatedAt ? `${moment(updatedAt).format("HH:mm")}` : null;
};
