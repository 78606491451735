import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import InfoModal from "../../components/info-modal/InfoModal";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import {
  AddMemberContentWrapper,
  FormContentWrapper,
  Title,
} from "./components";
import setPassword from "../../APIs/auth/setPassword";
import { useHistory, useParams } from "react-router-dom";
import { InfoModalTypes } from "../../constants/types";
import URLS from "../../constants/urls";

const SetPassword = () => {
  const [sucessOpen, setSuccessOpen] = useState();
  const [userSetPasswordLoading, setUserSetPasswordLoading] = useState(false);
  const { token } = useParams();
  const history = useHistory();

  const handleValidation = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.repassword) {
      errors.repassword = "Required";
    }
    if (values.password !== values.repassword) {
      errors.repassword = "Passwords do not match";
    }
    return errors;
  };

  const userSetPassword = async (vars, { setSuccessOpen }) => {
    const { password } = vars;

    setUserSetPasswordLoading(true);

    try {
      const res = await setPassword({ password, token: token });
      if (res?.data?.error) {
        alert.error(res.data?.error);
      } else {
        setSuccessOpen({
          type: InfoModalTypes.SUCCESS,
          isOpen: true,
          title: "Yeah!",
          subTitle: "Password has been changed",
          content: "You can sign in with the new password",
          onRequestClose: () => {
            history?.push(URLS.sign_in);
          },
        });
      }
    } catch (error) {
      alert.error(error.response.data.error.message[0]);
    }

    setUserSetPasswordLoading(false);
  };

  useEffect(() => {
    let timeout;
    if (sucessOpen) {
      timeout = setTimeout(() => {
        history.push(URLS.sign_in);
      }, 2000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [sucessOpen, history]);

  return (
    <OutlineNonAuthorised>
      <InfoModal
        {...sucessOpen}
        noOuterClickClosing
        okayText={"return to sign in"}
        setIsDeleteModal={() => history.push(URLS.sign_in)}
      />
      <AddMemberContentWrapper>
        <Title style={{ fontSize: "4rem" }}>{STRINGS.set_password}</Title>
        <Formik
          onSubmit={(v) => userSetPassword(v, { setSuccessOpen })}
          validate={(values) => {
            return handleValidation(values);
          }}
          initialValues={{
            password: "",
            repassword: "",
          }}
          // validationSchema={SetPasswordSchema}
        >
          {({ setFieldValue, errors, touched, isSubmitting }) => (
            <Form>
              <FormContentWrapper
                style={{
                  width: "36.8rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                disabled={isSubmitting || userSetPasswordLoading}
              >
                <Field id="password" name="password">
                  {({ field }) => (
                    <InputText
                      type="password"
                      height="5.6rem"
                      margin="1.8rem 0"
                      placeholder={STRINGS.please_type_your_password}
                      backgroundColor="#fff"
                      handleChange={(v) => setFieldValue(field.name, v)}
                      name={"new password"}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      icon={ICONS.PasswordEye}
                    />
                  )}
                </Field>
                <Field id="repassword" name="repassword">
                  {({ field }) => (
                    <InputText
                      type="password"
                      height="5.6rem"
                      margin="1.8rem 0"
                      backgroundColor="#fff"
                      placeholder={STRINGS.please_type_your_password_again}
                      handleChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.repeat_password}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      icon={ICONS.PasswordEye}
                    />
                  )}
                </Field>
                <CustomButton
                  type="submit"
                  backgroundColor={COLORS.secondary.ncs}
                  width="100%"
                  padding="0 7.2rem"
                  height="5.8rem"
                  margin="2rem 0 1.4rem"
                  shadow
                  capitalize
                >
                  {STRINGS.next}
                </CustomButton>
              </FormContentWrapper>
            </Form>
          )}
        </Formik>
      </AddMemberContentWrapper>
    </OutlineNonAuthorised>
  );
};

export default SetPassword;
