import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ICONS from "../../../constants/icons";
import STRINGS from "../../../constants/strings";
import URLS from "../../../constants/urls";
import { HeaderStatusText, StatusToggleIcon } from "../assignments/components";
import {
  BackArrow,
  BackToListText,
  HeaderRightSide,
  RobotDetailsHeader,
} from "../components";
import {
  AssignmentDetailHeader,
  AssignmentDetailsWrapperComp,
  AssignmentName,
  CardHeaderText,
  ContentText,
  IconAndInfo,
  IconWrapper,
  StyledIcon,
  TableWrapper,
} from "./components";
import { AssignmentsContext } from "../../../contexts/AssignmentsContext";
import useAssignments from "../../../hooks/useAssignments";
import Tasks from "./Tasks";
import AddAssignmentModal from "../../../components/add-assignment-modal/AddAssignmentModal";
import { ASSIGNMENTSTATUSENUM, ROLESENUM } from "../../../enums";
import getAssignment from "../../../APIs/assignments/getAssignment";
import { useSelector } from "react-redux";

const AssignmentTasks = ({ setShowCalendar }) => {
  var moment = require("moment"); // require
  const { id } = useParams();
  const { currentRole } = useSelector((state) => state.clients);
  const { assignmentsData, setAssignmentsData, assignmentTemplate } =
    useContext(AssignmentsContext);
  const [chosenAssignment, setChosenAssignment] = useState({});

  useEffect(() => {
    getAssignment(id).then((data) => {
      setChosenAssignment({
        ...assignmentTemplate,
        ...data,
        tasks: data?.tasks
          ?.map((item) => ({
            ...item,
            disabled: true,
            isCreated: true,
          }))
          .sort((a, b) => b.order - a.order),
      });
    });
  }, [id, assignmentTemplate]);

  const {
    handleToggle,
    handleEdit,
    isOpen,
    setIsOpen,
    newAssignmentForm,
    currentModalData,
    setNewAssignmentForm,
  } = useAssignments({ setAssignmentsData, setChosenAssignment });

  return (
    <React.Fragment>
      <RobotDetailsHeader>
        <Link to={URLS.assignments} style={{ display: "flex" }}>
          <BackArrow src={ICONS.ChevronLeft} />
          <BackToListText>{STRINGS.back_to_assignments}</BackToListText>
        </Link>
      </RobotDetailsHeader>
      <TableWrapper>
        <AssignmentDetailHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <AssignmentName>{chosenAssignment.name}</AssignmentName>
          <HeaderRightSide>
            <CardHeaderText>Assignment status</CardHeaderText>
            <HeaderStatusText>
              {chosenAssignment.status === "active"
                ? ASSIGNMENTSTATUSENUM.Active
                : ASSIGNMENTSTATUSENUM.Off}
            </HeaderStatusText>
            <StatusToggleIcon
              style={{
                width: "36px",
                cursor:
                  ![ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(
                    currentRole
                  ) && "pointer",
              }}
              src={
                chosenAssignment.status === "active"
                  ? ICONS.ToggleActive
                  : ICONS.ToggleOff
              }
              alt="ToggleIcon"
              onClick={() => {
                ![ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(
                  currentRole
                ) && handleToggle(chosenAssignment.id, "chosen");
              }}
            />
            {![ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(
              currentRole
            ) && (
              <IconWrapper
                background={"rgba(6, 134, 216, 0.1)"}
                onClick={() => handleEdit(chosenAssignment)}
              >
                <StyledIcon
                  style={{ cursor: "pointer" }}
                  src={ICONS.PencilDraw}
                  alt="Pencil"
                />
              </IconWrapper>
            )}
          </HeaderRightSide>
        </AssignmentDetailHeader>
        <AddAssignmentModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentModalData={currentModalData}
          newAssignmentForm={newAssignmentForm}
          setNewAssignmentForm={setNewAssignmentForm}
          setAssignmentsData={setAssignmentsData}
          assignmentsData={assignmentsData}
          setShowCalendar={setShowCalendar}
          chosenAssignment={chosenAssignment}
          setChosenAssignment={setChosenAssignment}
        />

        <AssignmentDetailsWrapperComp>
          <IconAndInfo>
            <IconWrapper background={"rgba(235, 92, 169, 0.05)"}>
              <StyledIcon src={ICONS.ColoredDocument} alt="Pencil" />
            </IconWrapper>
            <ContentText>{chosenAssignment?.tasks?.length}</ContentText>
          </IconAndInfo>

          <IconAndInfo>
            <IconWrapper background={"rgba(59, 129, 26, 0.05)"}>
              <StyledIcon src={ICONS.ColoredStatus} alt="Pencil" />
            </IconWrapper>
            <ContentText>{chosenAssignment.status}</ContentText>
          </IconAndInfo>

          <IconAndInfo>
            <IconWrapper background={"rgba(255, 168, 0, 0.05)"}>
              <StyledIcon src={ICONS.ClockIcon} alt="Pencil" />
            </IconWrapper>
            {!chosenAssignment.disposable && (
              <ContentText>
                {" "}
                {!chosenAssignment.disposable &&
                  "Every " +
                    chosenAssignment.weekDays
                      ?.map((item) => (item.chosen ? item.day : ""))
                      .filter((item) => item) +
                    " - " +
                    chosenAssignment.time}
              </ContentText>
            )}
            {chosenAssignment.disposable && (
              <ContentText>
                {moment(chosenAssignment.executionTime).format("dddd, MMM Do") +
                  "-" +
                  chosenAssignment.time}
              </ContentText>
            )}
          </IconAndInfo>
        </AssignmentDetailsWrapperComp>
      </TableWrapper>

      <Tasks
        chosenAssignment={chosenAssignment}
        setChosenAssignment={setChosenAssignment}
      />
    </React.Fragment>
  );
};

export default AssignmentTasks;
