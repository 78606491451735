import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import COLORS from "../../../constants/colors";
import {
  LocalizingModalMapWrapper,
  LocalizingModalWrapper,
  StyledMapName,
} from "./components";
import RobotLocalizeOnMap from "./RobotLocalizeOnMap";
import ICONS from "../../../constants/icons";
import { CloseMapModalIcon } from "../../../pages/dashboard/components";
import { StyledHeaderBtn } from "../../../pages/robots/components";
import { StyledSingleLineContainer } from "../../commonStyles";
import localizeRobot from "../../../APIs/robots/LocalizeRobot";
import Loader from "../../loader/Loader";
import { IconWrapper } from "../../../pages/robots/teleoperation/components";
import LocalizingInputs from "./LocalizingInputs";
const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "999",
  },

  content: {
    zIndex: "999",
    width: "90vh",
    height: "90vh",
    overflow: "hidden",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    border: "none",
    padding: "40px",
  },
};
const LocalizingModal = ({
  isOpen,
  onRequestClose = () => {},
  currentRobot,
}) => {
  const { chosenMap, mapOriginalWidth, mapOriginalHeight } = useSelector(
    (state) => state.maps
  );

  const mapImgRef = useRef(null);
  const scale = mapOriginalHeight / mapOriginalWidth;
  const xScale = mapOriginalWidth / mapImgRef?.current?.offsetWidth;
  const yScale = mapOriginalHeight / mapImgRef?.current?.offsetHeight;
  const hasInitialized = useRef(false);

  const [robotsDataForMap, setRobotsDataForMap] = useState({
    localization_data: {
      map_name: chosenMap?.technicalName,
      x_pixel: currentRobot?.localization_status?.x_pixel || 0,
      y_pixel: currentRobot?.localization_status?.y_pixel || 100,
      angle_degrees: currentRobot?.localization_status?.angle_degrees || 0,
      force_localization: true,
    },
  });

  const [lineStart, setLineStart] = useState(null);
  const [lineEnd, setLineEnd] = useState(null);
  const [angle, setAngle] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isToggle, setIsToggle] = useState(false);

  useEffect(() => {
    if (lineStart?.x && lineStart?.y) {
      setRobotsDataForMap((prev) => ({
        ...prev,
        localization_data: {
          ...prev.localization_data,
          map_name: chosenMap?.technicalName,
          x_pixel: lineStart?.x * xScale,
          y_pixel: lineStart?.y * yScale,
          angle_degrees: angle,
        },
      }));
    }
  }, [
    chosenMap?.technicalName,
    angle,
    lineStart?.x,
    lineStart?.y,
    xScale,
    yScale,
  ]);

  useEffect(() => {
    if (
      !hasInitialized.current &&
      currentRobot?.localization_status?.x_pixel !== undefined &&
      currentRobot?.localization_status?.y_pixel !== undefined &&
      currentRobot?.localization_status?.angle_degrees !== undefined
    ) {
      setRobotsDataForMap({
        localization_data: {
          x_pixel: currentRobot.localization_status.x_pixel,
          y_pixel: currentRobot.localization_status.y_pixel,
          angle_degrees: currentRobot.localization_status.angle_degrees,
        },
      });
      hasInitialized.current = true;
    }
  }, [
    currentRobot?.localization_status?.x_pixel,
    currentRobot?.localization_status?.y_pixel,
    currentRobot?.localization_status?.angle_degrees,
  ]);

  const handleChange = (val, key) => {
    setRobotsDataForMap((prev) => ({
      ...prev,
      localization_data: {
        ...prev.localization_data,
        [key]: +val,
      },
    }));
  };

  const handleLocalizeRobot = async () => {
    try {
      setIsLoading(true);
      const response = await localizeRobot({
        robotId: currentRobot?.robotId,
        localization_data: {
          ...robotsDataForMap?.localization_data,
          force_localization: robotsDataForMap?.localization_data
            ?.force_localization
            ? true
            : false,
        },
      });
      console.log("🚀 ~ handleLocalizeRobot ~ response:", response);
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ handleLocalizeRobot ~ error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setRobotsDataForMap({
        localization_data: {
          x_pixel: currentRobot?.localization_status?.x_pixel,
          y_pixel: currentRobot?.localization_status?.y_pixel,
          angle_degrees: currentRobot?.localization_status?.angle_degrees,
          force_localization: true,
        },
      });
    }
  }, [
    isOpen,
    currentRobot?.localization_status?.x_pixel,
    currentRobot?.localization_status?.y_pixel,
    currentRobot?.localization_status?.angle_degrees,
  ]);

  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <StyledMapName>{chosenMap?.technicalName}</StyledMapName>
      <LocalizingModalWrapper>
        <LocalizingModalMapWrapper>
          <RobotLocalizeOnMap
            robotsDataForMap={robotsDataForMap}
            lineStart={lineStart}
            lineEnd={lineEnd}
            angle={angle}
            setLineStart={setLineStart}
            setLineEnd={setLineEnd}
            setAngle={setAngle}
            xScale={xScale}
            yScale={yScale}
            mapImgRef={mapImgRef}
            scale={scale}
            isToggle={isToggle}
          />
        </LocalizingModalMapWrapper>

        <LocalizingInputs
          handleChange={handleChange}
          robotsDataForMap={robotsDataForMap}
        />
      </LocalizingModalWrapper>
      <StyledSingleLineContainer style={{ justifyContent: "center" }}>
        <StyledHeaderBtn onClick={handleLocalizeRobot} disabled={isLoading}>
          {isLoading ? (
            <Loader height={20} noText noMargin padding={0} />
          ) : (
            "Localize"
          )}
        </StyledHeaderBtn>
      </StyledSingleLineContainer>

      <IconWrapper
        onClick={(e) => {
          e.stopPropagation();
          setIsToggle((prev) => !prev);
        }}
        className="switch-icon"
        style={{
          position: "absolute",
          top: "10px",
          right: "50px",
          width: "30px",
          height: "30px",
        }}
      >
        <img src={ICONS.SwitchIcon} alt="" />
      </IconWrapper>
      <CloseMapModalIcon
        src={ICONS.CloseGray}
        alt="close"
        onClick={() => {
          onRequestClose();
        }}
      />
    </Modal>
  );
};

export default LocalizingModal;
