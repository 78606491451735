import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";
import {
  PercentageWrapper,
  RobotDetailsButtonWrapper,
  RobotLogoWrapper,
  RobotRowPhoto,
  RobotsRowButton,
  RobotsRowItem,
  RobotsTableRow,
  StyledBattery,
  StyledStatus,
} from "./components";
import { determineRobotStatusText } from "../../utils/robots/robotStatusText";
import {
  EditItem,
  StyledEditBox,
  StyledEditIcon,
  VertMenuWrapper,
} from "../admin-page/environment-setup/components";
import { Link } from "react-router-dom";
import URLS from "../../constants/urls";
import { ROBOTSTATUSENUM, ROBOTSTATUSENUMALIAS, ROLESENUM } from "../../enums";
import { setBatterfyConfigurationData } from "../../redux/robots";
import { useDispatch } from "react-redux";

const RobotRow = ({
  item,
  showEdit,
  setShowEdit,
  handleEdit,
  setIsDeleteModal,
  setChosenItem,
  currentRole,
}) => {
  const dispatch = useDispatch();

  return (
    <Link to={`${URLS.robots}/${item.id}`} key={item.id}>
      <RobotsTableRow>
        <RobotLogoWrapper>
          <RobotRowPhoto status={item?.robot_manager?.status} alt="Logo" />
          <StyledStatus
            status={item?.robot_manager?.status || ROBOTSTATUSENUM.OFF}
          >
            {ROBOTSTATUSENUMALIAS[
              item?.robot_manager?.status?.toUpperCase()?.replace &&
                item?.robot_manager?.status?.toUpperCase()?.replace(/\s/g, "")
            ] || ROBOTSTATUSENUM.OFF}
          </StyledStatus>
        </RobotLogoWrapper>
        <RobotsRowItem>{item.name}</RobotsRowItem>
        <RobotsRowItem>{determineRobotStatusText(item)}</RobotsRowItem>
        <RobotsRowItem>
          {item?.robot_manager?.status &&
            item?.robot_manager?.status !== ROBOTSTATUSENUM.OFF && (
              <>
                <PercentageWrapper>
                  {item?.battery?.level
                    ? (+item?.battery?.level * 100)?.toFixed(0) + "%"
                    : null}
                </PercentageWrapper>
                <StyledBattery
                  battery={
                    item?.battery?.level
                      ? (+item?.battery?.level)?.toFixed(2)
                      : null
                  }
                >
                  {item?.robot_manager?.status === ROBOTSTATUSENUM.CHARGING && (
                    <img src={ICONS.ChargingIcon} alt="" />
                  )}
                </StyledBattery>
              </>
            )}
        </RobotsRowItem>
        <RobotsRowItem style={{ justifyContent: "space-between" }}>
          <RobotDetailsButtonWrapper className="view-details-wrapper">
            <RobotsRowButton>{`${STRINGS.view_details}`}</RobotsRowButton>
          </RobotDetailsButtonWrapper>

          {[
            ROLESENUM.ADMIN,
            ROLESENUM.CLIENTADMIN,
            ROLESENUM.DEVELOPER,
            ROLESENUM.TELEOPERATOR,
          ].includes(currentRole) && (
            <VertMenuWrapper>
              <img
                src={ICONS.MenuVertical}
                alt="edit"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEdit((prev) => !prev && item.id);
                }}
              />
              {showEdit === item.id && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowEdit(false);
                  }}
                >
                  <StyledEditBox>
                    <EditItem
                      onClick={(e) => {
                        e.preventDefault();
                        setShowEdit(false);
                        handleEdit(item);
                      }}
                    >
                      <StyledEditIcon src={ICONS.PencilDraw} alt="Pencil" />
                      Edit
                    </EditItem>
                    <EditItem
                      onClick={(e) => {
                        e.preventDefault();
                        setChosenItem(item);
                        setIsDeleteModal(true);
                      }}
                    >
                      <StyledEditIcon src={ICONS.DeleteIcon} alt="DeleteIcon" />
                      Delete
                    </EditItem>
                    <EditItem
                      onClick={(e) => {
                        e.preventDefault();
                        setChosenItem(item);
                        dispatch(setBatterfyConfigurationData(item));
                      }}
                    >
                      <StyledEditIcon src={ICONS.NavSettings} alt="settings" />
                      Battery
                    </EditItem>
                  </StyledEditBox>
                </OutsideClickHandler>
              )}
            </VertMenuWrapper>
          )}
        </RobotsRowItem>
      </RobotsTableRow>
    </Link>
  );
};

export default RobotRow;
