import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { RobotDetailsWrapper } from "../components";
import {
  StyledTeleopLeftContainer,
  StyledTeleopRightContainer,
  TeleopHeaderWrapper,
  TeleopPageGridWrapper,
} from "./components";
import { RobotsDataContext } from "../../../contexts/RobotsDataContext";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TeleopGeneralInfo from "./TeleopGeneralInfo";
import TeleopMapComponent from "./teleop-map/TeleopMapComponent";
import RobotRunningApp from "./robot-running-app/RobotRunningApp";
import TeleopStreamingComponent from "./teleop-streaming-component/TeleopStreamingComponent";
import { useTransitive } from "../../../contexts/TransitiveContext";
import Elevator from "./elevator/Elevator";
import TeleopDebugLogs from "./debug-logs/TeleopDebugLogs";
import { useSelector } from "react-redux";
import { setChosenClient, setClient } from "../../../redux/clients";
import { AdminTableContext } from "../../../contexts/AdminTableContext";
import { useDispatch } from "react-redux";

const DEBOUNCE_DELAY = 300;

// Add actions for elevator modal control
const elevatorModalReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_MODAL":
      return {
        ...state,
        isElevatorModalOpen: !state.isElevatorModalOpen,
        callType: action.payload,
      };
    case "SET_DIRECTION":
      return { ...state, direction: action.payload };
    case "SET_FLOOR":
      return { ...state, floor: action.payload };
    case "SET_ELEVATOR":
      return { ...state, chosenElevator: action.payload };
    default:
      return state;
  }
};

const RobotTeleopDetails = () => {
  const { id } = useParams();
  const location = useLocation();

  const dispatchFunction = useDispatch();
  const navigationSource = location.state?.from;
  const { clients } = useContext(AdminTableContext);
  const { chosenClient } = useSelector((state) => state.clients);
  const [fullScreenType, setFullScreenType] = useState(null);
  const { RobotsData } = useContext(RobotsDataContext);
  const { teleopRobots } = useTransitive();

  // Refs to track the previous state of buttons
  const prevButtonStates = useRef({
    L2: false,
    R2: false,
    X: false,
    Y: false,
    UP: false,
    DOWN: false,
    LEFT: false,
    RIGHT: false,
    O: false,
    B: false,
    L1: false,
    R1: false,
  });

  // Refs to track last pressed time for each button
  const lastPressedTime = useRef({
    L2: 0,
    R2: 0,
    X: 0,
    Y: 0,
    UP: 0,
    DOWN: 0,
    LEFT: 0,
    RIGHT: 0,
    O: 0,
    B: 0,
    L1: 0,
    R1: 0,
  });

  // Ref to store the current animation frame ID
  const animationFrameId = useRef(null);

  const [elevatorState, dispatch] = useReducer(elevatorModalReducer, {
    isElevatorModalOpen: false,
    direction: "UP",
    floor: 0,
    confirm: false,
    callType: null,
    chosenElevator: 4,
  });

  const currentRobot = useMemo(() => {
    //if navigated from teleoperation page
    if (navigationSource) return teleopRobots.find((item) => item.id === id);
    //if navigated from robots page
    return RobotsData.find((item) => item.id === id);
  }, [RobotsData, id, teleopRobots, navigationSource]);

  useEffect(() => {
    const handleGamepadInput = () => {
      const gamepads = navigator.getGamepads();
      const gamepad = gamepads[0]; // Assuming you want the first gamepad.

      if (gamepad) {
        const { buttons } = gamepad;
        const currentTime = Date.now();

        // Handle L1 (button index 4)
        if (
          buttons[4].pressed &&
          !prevButtonStates.current.L1 &&
          currentTime - lastPressedTime.current.L1 > DEBOUNCE_DELAY
        ) {
          const inButton = document.getElementById("elevator-in-button");
          if (inButton) inButton.click();
          lastPressedTime.current.L1 = currentTime;
        }

        // Handle R1 (button index 5)
        if (
          buttons[5].pressed &&
          !prevButtonStates.current.R1 &&
          currentTime - lastPressedTime.current.R1 > DEBOUNCE_DELAY
        ) {
          const outButton = document.getElementById("elevator-out-button");
          if (outButton) outButton.click();
          lastPressedTime.current.R1 = currentTime;
        }

        // Handle L2 (button[6])
        if (
          buttons[6].pressed &&
          !prevButtonStates.current.L2 &&
          currentTime - lastPressedTime.current.L2 > DEBOUNCE_DELAY
        ) {
          setFullScreenType((prev) => (!prev ? "MAP" : null));
          lastPressedTime.current.L2 = currentTime;
        }

        // Handle R2 (button[7])
        if (
          buttons[7].pressed &&
          !prevButtonStates.current.R2 &&
          currentTime - lastPressedTime.current.R2 > DEBOUNCE_DELAY
        ) {
          setFullScreenType((prev) => (!prev ? "TELEOP" : null));
          lastPressedTime.current.R2 = currentTime;
        }

        // Handle X / [] (button[2])
        if (
          buttons[2].pressed &&
          !prevButtonStates.current.X &&
          currentTime - lastPressedTime.current.X > DEBOUNCE_DELAY
        ) {
          const teleopButton = document.getElementById("teleop-button");
          if (teleopButton) teleopButton.click();
          lastPressedTime.current.X = currentTime;
        }

        // Y / triangle Button (Button index 3)
        if (
          buttons[3].pressed &&
          !prevButtonStates.current.Y &&
          currentTime - lastPressedTime.current.Y > DEBOUNCE_DELAY
        ) {
          dispatch({ type: "TOGGLE_MODAL", payload: "hallCall" });
          lastPressedTime.current.Y = currentTime;
        }

        // B / O Button (Button index 3)
        if (
          buttons[1].pressed &&
          !prevButtonStates.current.B &&
          currentTime - lastPressedTime.current.B > DEBOUNCE_DELAY
        ) {
          dispatch({ type: "TOGGLE_MODAL", payload: "carCall" });
          lastPressedTime.current.B = currentTime;
        }

        // Arrow Up (Button index 12) and Down (Button index 13)
        if (elevatorState.isElevatorModalOpen) {
          if (
            buttons[12].pressed &&
            currentTime - lastPressedTime.current.UP > DEBOUNCE_DELAY
          ) {
            dispatch({ type: "SET_DIRECTION", payload: "UP" });
            lastPressedTime.current.UP = currentTime;
          }

          if (
            buttons[13].pressed &&
            currentTime - lastPressedTime.current.DOWN > DEBOUNCE_DELAY
          ) {
            dispatch({ type: "SET_DIRECTION", payload: "DOWN" });
            lastPressedTime.current.DOWN = currentTime;
          }

          // right arrow Button (Button index 15)
          if (
            buttons[15].pressed &&
            currentTime - lastPressedTime.current.LEFT > DEBOUNCE_DELAY
          ) {
            dispatch({
              type: "SET_FLOOR",
              payload: elevatorState.floor + 1,
            });
            lastPressedTime.current.LEFT = currentTime;
          }

          // left arrow Button (Button index 14)
          if (
            buttons[14].pressed &&
            currentTime - lastPressedTime.current.RIGHT > DEBOUNCE_DELAY
          ) {
            dispatch({
              type: "SET_FLOOR",
              payload: Math.max(elevatorState.floor - 1, 0),
            });
            lastPressedTime.current.RIGHT = currentTime;
          }

          // left A / X Button (Button index 0)
          if (
            buttons[0].pressed &&
            currentTime - lastPressedTime.current.O > DEBOUNCE_DELAY
          ) {
            const elevatorbutton = document.getElementById(
              "elevator-confirm-button"
            );
            if (elevatorbutton) elevatorbutton.click();
            lastPressedTime.current.O = currentTime;
          }
        }

        // Store previous button states
        prevButtonStates.current = {
          ...prevButtonStates.current,
          L2: buttons[6].pressed,
          R2: buttons[7].pressed,
          X: buttons[2].pressed,
          Y: buttons[3].pressed,
          UP: buttons[12].pressed,
          DOWN: buttons[13].pressed,
          LEFT: buttons[14].pressed,
          RIGHT: buttons[15].pressed,
          O: buttons[0].pressed,
          L1: buttons[4].pressed,
          R1: buttons[5].pressed,
        };
      }

      // Schedule the next frame
      animationFrameId.current = requestAnimationFrame(handleGamepadInput);
    };

    // Start the loop
    animationFrameId.current = requestAnimationFrame(handleGamepadInput);

    return () => {
      // Cleanup: Cancel the scheduled animation frame
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [elevatorState]);

  useEffect(() => {
    if (!chosenClient || !currentRobot || !navigationSource) return;
    if (currentRobot?.client?.id !== chosenClient) {
      dispatchFunction(setChosenClient(currentRobot?.client?.id));
      dispatchFunction(
        setClient(clients.find((item) => item.id === currentRobot?.client?.id))
      );
    }
  }, [currentRobot, chosenClient, clients, dispatchFunction, navigationSource]);

  return (
    <RobotDetailsWrapper>
      <TeleopHeaderWrapper>
        <TeleopGeneralInfo currentRobot={currentRobot} />
      </TeleopHeaderWrapper>
      <TeleopPageGridWrapper>
        <StyledTeleopLeftContainer
          className={fullScreenType === "MAP" && "sr-only"}
          style={{
            width: fullScreenType === "TELEOP" && "100%",
          }}
        >
          <TeleopDebugLogs currentRobot={currentRobot} />

          <TeleopStreamingComponent
            currentRobot={currentRobot}
            isFullScreen={fullScreenType === "TELEOP"}
            handleTeleopFullScreen={() =>
              setFullScreenType((prev) => (!prev ? "TELEOP" : null))
            }
          />
        </StyledTeleopLeftContainer>

        <StyledTeleopRightContainer
          style={{
            width: fullScreenType === "MAP" && "100%",
            height: fullScreenType === "TELEOP" && "unset",
          }}
          className={fullScreenType === "TELEOP" && "sr-only"}
        >
          <TeleopMapComponent
            isFullScreen={fullScreenType === "MAP"}
            currentRobot={currentRobot}
            handleMapFullScreen={() =>
              setFullScreenType((prev) => (!prev ? "MAP" : null))
            }
          />

          <div
            style={{ height: "164px" }}
            className={fullScreenType && "sr-only"}
          >
            <Elevator
              currentRobot={currentRobot}
              isElevatorModalOpen={elevatorState.isElevatorModalOpen}
              direction={elevatorState.direction}
              setDirection={(dir) =>
                dispatch({ type: "SET_DIRECTION", payload: dir })
              }
              floor={elevatorState.floor}
              setFloor={(fl) => dispatch({ type: "SET_FLOOR", payload: fl })}
              chosenElevator={elevatorState.chosenElevator}
              setChosenElevator={(elevator) =>
                dispatch({ type: "SET_ELEVATOR", payload: elevator })
              }
              toggleModal={() => dispatch({ type: "TOGGLE_MODAL" })}
              setCallType={(callType) =>
                dispatch({ type: "TOGGLE_MODAL", payload: callType })
              }
              callType={elevatorState.callType}
            />
          </div>
          {fullScreenType !== "MAP" && (
            <RobotRunningApp currentRobot={currentRobot} />
          )}
        </StyledTeleopRightContainer>
      </TeleopPageGridWrapper>
    </RobotDetailsWrapper>
  );
};

export default RobotTeleopDetails;
