import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StyledAssignmentPopupWrapper, StyledEachDay } from "./components";

import {
  handleAssignmentDetailsUpdate,
  handleUpdateAssignmentFromAgenda,
  setAgendaDataBeforeDrag,
  setAssignmentDetailsBeforeChange,
  setAssignmentEditMode,
  setDraggableAssignment,
  setDuplicateAssignment,
} from "../../redux/dashboard";
import { useContext } from "react";
import { AssignmentsContext } from "../../contexts/AssignmentsContext";
import CalendarRow from "./CalendarRow";
import {
  changeHoursAndMinutesInISOString,
  isTimeFuture,
} from "../../utils/dateHelper";
import { timeArray } from "../../constants/time-array";
import { TimeTableWrapper } from "../dashboard/components";
import AssignmentDetailsWrapper from "../../components/agenda-assignment-details-modal/AssignmentDetailsWrapper";
import AssignmentBox from "../dashboard/AssignmentBox";
import { AGENDAASSIGNMENTSSTATUSENUM } from "../../enums";
const CalendarTable = ({ isOpen, setIsOpen, drawerRef, setDrawerRef }) => {
  const popupWrapperRef = useRef();
  const assignmentBoxRef = useRef();
  const bottomRef = useRef();
  const dispatch = useDispatch();
  const {
    sortedDataForAgenda,
    assignmentDetails,
    isAssignmentEditMode,
    draggableAssignment,
    agendaData,
    isLoading,
    chosenWeekDays,
  } = useSelector((state) => state.dashboard);
  const { assignmentTemplate } = useContext(AssignmentsContext);

  const [assignmentBeforeDrag, setAssignmentBeforeDrag] = useState(null);
  const rightSide = ["Sun", "Mon", "Tue"].includes(
    moment(assignmentDetails?.agendaDate).format("ddd")
  );
  const leftSide = ["Wed", "Thu", "Fri", "Sat"].includes(
    moment(assignmentDetails?.agendaDate).format("ddd")
  );
  const isWed = ["Wed"].includes(
    moment(assignmentDetails?.agendaDate).format("ddd")
  );

  const found = sortedDataForAgenda.find((el) => el.isDragging);
  useEffect(() => {
    !assignmentDetails && setIsOpen(false);
  }, [assignmentDetails, setIsOpen]);

  const handleDayClick = (e, time, day) => {
    if (assignmentDetails && !isAssignmentEditMode) {
      dispatch(handleAssignmentDetailsUpdate(null));
    }
    if (!assignmentDetails || isAssignmentEditMode) {
      const isValidTime = isTimeFuture(time, day);
      if (isValidTime) {
        let creatingTime = `${+time.split(":")[0] - 1}`;
        if (e.target.offsetHeight / 2 > e.nativeEvent.offsetY) {
          creatingTime = creatingTime + ":00";
        } else {
          creatingTime = creatingTime + ":30";
        }
        dispatch(
          handleAssignmentDetailsUpdate({
            ...assignmentTemplate,
            justCreated: true,
            executionTime: day,
            time: creatingTime,
          })
        );
        dispatch(setAssignmentEditMode(true));
      }
    }
  };
  const handleDrag = (el) => {
    const item = sortedDataForAgenda.find(
      (item) => item.id + " " + item.agendaDate === el.draggableId
    );
    if (!item?.isDragging) {
      dispatch(setAgendaDataBeforeDrag(agendaData));
    }
    dispatch(setDraggableAssignment({ ...item, isDragging: true }));
  };

  const onDragUpdate = (el) => {
    const item = sortedDataForAgenda.find(
      (item) => item.id + " " + item.agendaDate === el.draggableId
    );
    setAssignmentBeforeDrag({ ...item, isDragging: true });
  };

  const handleDrop = (result) => {
    if (result.destination.droppableId === result.source.droppableId) {
      dispatch(setDraggableAssignment(null));
      setAssignmentBeforeDrag(null);
    } else {
      const assignmentBeforeChange = sortedDataForAgenda.find(
        (item) => item.id + " " + item.agendaDate === result.draggableId
      );
      dispatch(setAssignmentDetailsBeforeChange(assignmentBeforeChange));
      if (result.destination) {
        const day = result?.destination?.droppableId?.split(" ")[0];
        const time = result?.destination?.droppableId?.split(" ")[1];
        if (draggableAssignment?.isDragging) {
          const hours = time.split(":")[0] - 1;
          const minutes = "00";
          const modifiedISOString = changeHoursAndMinutesInISOString(
            day,
            hours,
            minutes
          );
          dispatch(
            handleUpdateAssignmentFromAgenda({
              ...draggableAssignment,
              isCreated: true,
              executionTime: modifiedISOString,
              agendaDateBeforeDrag: draggableAssignment.agendaDate,
              agendaDate: modifiedISOString,
            })
          );
          dispatch(setAssignmentEditMode(true));

          if (!draggableAssignment.disposable) {
            const draggedDayIndex = chosenWeekDays.indexOf(
              result.draggableId.split(" ")[1]
            );
            const droppedDayIndex = chosenWeekDays.indexOf(day);
            let weekDays;
            const duplicateInOneDay = draggableAssignment.occurance.weekDays
              .split(",")
              .includes(droppedDayIndex.toString());
            if (duplicateInOneDay) {
              weekDays = draggableAssignment.occurance.weekDays.replace(
                `${draggedDayIndex},`,
                ""
              );
              dispatch(
                setDuplicateAssignment({
                  ...draggableAssignment,
                  occurance: {
                    ...draggableAssignment.occurance,
                    hour: hours,
                    minutes: minutes,
                    weekDays: droppedDayIndex.toString(),
                  },
                })
              );
            } else {
              weekDays = draggableAssignment.occurance.weekDays.replace(
                draggedDayIndex,
                droppedDayIndex
              );
            }

            dispatch(
              handleAssignmentDetailsUpdate({
                ...draggableAssignment,
                isCreated: true,
                occurance: {
                  ...draggableAssignment.occurance,
                  hour: hours,
                  minutes: minutes,
                  weekDays: weekDays,
                },
                agendaDate: modifiedISOString,
                executionTime: modifiedISOString,
              })
            );
          } else {
            dispatch(
              handleAssignmentDetailsUpdate({
                ...draggableAssignment,
                isCreated: true,
                agendaDate: modifiedISOString,
                executionTime: modifiedISOString,
              })
            );
          }

          dispatch(setDraggableAssignment(null));
          setAssignmentBeforeDrag(null);
        }
      }
    }
  };
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#fff",
  });
  useEffect(() => {
    chosenWeekDays?.length && bottomRef.current?.scrollIntoView();
  }, [chosenWeekDays, isLoading]);
  useEffect(() => {
    if (
      popupWrapperRef &&
      assignmentDetails &&
      drawerRef?.current?.offsetHeight &&
      assignmentBoxRef?.current?.offsetWidth
    ) {
      popupWrapperRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [popupWrapperRef, assignmentDetails, drawerRef]);
  return (
    <DragDropContext
      onDragStart={(el) => handleDrag(el)}
      onDragEnd={(result) => handleDrop(result)}
      onDragUpdate={(el) => onDragUpdate(el)}
    >
      <TimeTableWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {timeArray.map((item) => (
          <React.Fragment key={item.time}>
            <CalendarRow
              key={"AgendaRow" + item.time}
              time={item.time}
              // className="clickAllow"
            >
              {chosenWeekDays?.map((day) => (
                <Droppable
                  droppableId={day + " " + item.time}
                  key={day + item.time}
                  isDropDisabled={!isTimeFuture(item.time, day)}
                >
                  {(provided, snapshot) => (
                    <StyledEachDay
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      key={day}
                      style={{
                        ...getListStyle(snapshot.isDraggingOver),
                        cursor: isTimeFuture(item.time, day) && "pointer",
                      }}
                      className="clickAllow"
                      onClick={(e) => {
                        !assignmentBeforeDrag &&
                          handleDayClick(e, item.time, day);
                      }}
                      // onMouseEnter={() => {
                      //   handleDrop(item.time, day);
                      // }}
                    >
                      {provided.placeholder}
                      {assignmentBeforeDrag?.agendaDate === day &&
                        +moment(assignmentBeforeDrag?.executionTime).format(
                          "HH"
                        ) ===
                          +item.time.split(":")[0] - 1 && (
                          <AssignmentBox item={assignmentBeforeDrag} />
                        )}
                      {sortedDataForAgenda.map((element, index) => {
                        if (
                          moment(element.agendaDate).format("L") ===
                            moment(day).format("L") &&
                          +moment(element.executionTime)
                            .format("HH:mm")
                            ?.split(":")[0] ===
                            +item.time?.split(":")[0] - 1
                        ) {
                          return (
                            <Draggable
                              key={element.id + element.agendaDate}
                              draggableId={
                                element.id + " " + element.agendaDate
                              }
                              index={index}
                              isDragDisabled={
                                !(
                                  (element.agendaAssignmentStatus ===
                                    AGENDAASSIGNMENTSSTATUSENUM.DEFAULT &&
                                    !found) ||
                                  found?.id + found?.agendaDate ===
                                    element?.id + element?.agendaDate
                                )
                              }
                            >
                              {(provided) => (
                                <div
                                  key={element.id + element.agendaDate}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <AssignmentBox
                                    item={element}
                                    setIsOpen={setIsOpen}
                                    assignmentBoxRef={assignmentBoxRef}
                                  />
                                  {assignmentDetails?.id === element?.id &&
                                    moment(
                                      assignmentDetails?.agendaDate
                                    ).format("L") === moment(day).format("L") &&
                                    !isAssignmentEditMode && (
                                      <StyledAssignmentPopupWrapper
                                        ref={popupWrapperRef}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        rightSide={rightSide}
                                        isWed={isWed}
                                        transformLeft={
                                          assignmentBoxRef?.current
                                            ?.offsetWidth + 10
                                        }
                                        style={{
                                          height:
                                            drawerRef?.current?.offsetHeight,
                                          // visibility:
                                          //   (!assignmentBoxRef?.current
                                          //     ?.offsetWidth ||
                                          //     !drawerRef?.current
                                          //       ?.offsetHeight) &&
                                          //   "hidden",
                                        }}
                                      >
                                        <AssignmentDetailsWrapper
                                          leftSide={leftSide}
                                          setDrawerRef={setDrawerRef}
                                          calendar
                                          isOpen={isOpen}
                                          setIsOpen={setIsOpen}
                                        />
                                      </StyledAssignmentPopupWrapper>
                                    )}
                                </div>
                              )}
                            </Draggable>
                          );
                        } else return false;
                      })}
                    </StyledEachDay>
                  )}
                </Droppable>
              ))}
            </CalendarRow>
            {"07:00" === item.time && (
              <div key={"currentTime" + item.time} ref={bottomRef} />
            )}
          </React.Fragment>
        ))}
      </TimeTableWrapper>
    </DragDropContext>
  );
};

export default CalendarTable;
