import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import assignRobot from "../../APIs/robots/assignRobot";
import updateRobot from "../../APIs/robots/updateRobot";
import STRINGS from "../../constants/strings";
import { RobotsDataContext } from "../../contexts/RobotsDataContext";
import {
  AddRobotContent,
  LabelText,
  RobotModalContent,
  RobotModalContentWrapper,
  StyledHeaderBtn,
} from "../../pages/robots/components";
import { setToastMessages } from "../../redux/toast";
import InputText from "../input/InputText";
import ModalOutline from "../modal-outline/ModalOutline";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import defaultSourceConfig from "../../pages/robots/transitive/TeleopSourceConfiguration.json";
const paramDefaultStates = {
  robotId: "",
  name: "",
  teleopSourceConfig: "",
};

const AddRobotModal = ({ isOpen, setIsOpen, editRobot, setEditRobot }) => {
  const { chosenClient } = useSelector((state) => state.clients);
  const [errorValidations, setErrorValidations] = useState({
    robotId: "",
    name: "",
    teleopSourceConfig: "",
  });
  const { setRobotsData } = useContext(RobotsDataContext);
  const [robotModalForm, setRobotModalForm] = useState({
    robotId: "",
    name: "",
    clientId: chosenClient,
    teleopSourceConfig: defaultSourceConfig,
  });
  const { isMobile } = useContext(ResponsiveContext);

  const dispatch = useDispatch();
  useEffect(() => {
    editRobot
      ? setRobotModalForm({
          ...editRobot,
          teleopSourceConfig: JSON.stringify(editRobot.teleopSourceConfig),
        })
      : setRobotModalForm((prev) => ({
          ...prev,
          teleopSourceConfig: JSON.stringify(defaultSourceConfig),
        }));
  }, [editRobot, isOpen]);

  const handleChange = (inputVal, inputName) => {
    setRobotModalForm({
      ...robotModalForm,
      [inputName]: inputVal,
    });
  };

  const handleEdit = async () => {
    let obj = {
      ...robotModalForm,
      clientId: chosenClient,
      teleopSourceConfig: JSON.parse(robotModalForm.teleopSourceConfig),
      updatedAt: new Date().toISOString(),
    };
    try {
      const { data: updatedRobot } = await updateRobot(obj);

      setRobotsData((prev) =>
        prev.map((item) =>
          item.id === updatedRobot.id
            ? {
                ...item,
                ...updatedRobot,
              }
            : item
        )
      );
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRobot = async () => {
    try {
      const robot = await assignRobot({
        ...robotModalForm,
        teleopSourceConfig:
          typeof robotModalForm.teleopSourceConfig === "string"
            ? JSON.parse(robotModalForm.teleopSourceConfig)
            : robotModalForm.teleopSourceConfig,
      });
      setRobotsData((prev) => [...prev, robot]);
      handleModalClose();
    } catch (error) {
      console.log("handleAddRobot ~ error", error);
      dispatch(
        setToastMessages({ text: error.response.data.message, type: "error" })
      );
    }
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setErrorValidations(paramDefaultStates);
    setEditRobot && setEditRobot(null);
    setRobotModalForm({
      robotId: "",
      name: "",
      clientId: chosenClient,
    });
  };

  const isError = () => {
    let errorFlag = false;
    const validations = { ...paramDefaultStates };

    Object.keys(validations).forEach((key) => {
      if (!robotModalForm[key]) {
        validations[key] = "This field cannot be empty";
        errorFlag = true;
      } else if (key === "teleopSourceConfig") {
        try {
          typeof robotModalForm[key] === "string" &&
            JSON.parse(robotModalForm[key]);
        } catch (error) {
          validations[key] = "This field must be a valid JSON";
          errorFlag = true;
        }
      }
    });
    setErrorValidations(validations);
    return errorFlag;
  };
  const handleSave = () => {
    if (isError()) return;
    editRobot ? handleEdit() : handleAddRobot();
  };
  return (
    <React.Fragment>
      <ModalOutline
        isOpen={isOpen}
        heading={editRobot ? STRINGS.edit_robot_info : STRINGS.assign_new_robot}
        onRequestClose={() => handleModalClose()}
      >
        <RobotModalContent>
          <LabelText isMobile={isMobile}>Robot name: </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={robotModalForm.name || ""}
                handleChange={(e) => handleChange(e, "name")}
                error={errorValidations.name}
              />
            </AddRobotContent>
          </RobotModalContentWrapper>

          <LabelText isMobile={isMobile}>Robot ID: </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={robotModalForm.robotId || ""}
                handleChange={(e) => handleChange(e, "robotId")}
                error={errorValidations.robotId}
              />
            </AddRobotContent>
          </RobotModalContentWrapper>

          <LabelText isMobile={isMobile}>
            Teleoperation Source Configuration:
          </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={
                  typeof robotModalForm?.teleopSourceConfig === "object"
                    ? JSON.stringify(robotModalForm?.teleopSourceConfig)
                    : robotModalForm?.teleopSourceConfig || ""
                }
                handleChange={(e) => handleChange(e, "teleopSourceConfig")}
                error={errorValidations.teleopSourceConfig}
              />
            </AddRobotContent>
          </RobotModalContentWrapper>
        </RobotModalContent>
        <RobotModalContentWrapper>
          <StyledHeaderBtn style={{ margin: "30px 0" }} onClick={handleSave}>
            {editRobot ? "Edit" : "Add"}
          </StyledHeaderBtn>
        </RobotModalContentWrapper>
      </ModalOutline>
    </React.Fragment>
  );
};

export default AddRobotModal;
