import { createContext, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { getRobots } from "../APIs";
import {
  setRobotsDataRedux,
  setRobotTaskWarningData,
  setAbortTask,
  setLowBatteryRobotsQueue,
  setEmergencyPressedRobots,
} from "../redux/robots";
import socket, { joinSocketRoom } from "../APIs/socket/socket";
import { ROBOTSTATUSENUM, ROLESENUM } from "../enums";
import { setToastMessages } from "../redux/toast";
import { playAudio } from "../utils/audio/audio";
import URLS from "../constants/urls";

export const RobotsDataContext = createContext();

export const RobotsDataProvider = ({ children }) => {
  const { client, chosenClient, currentRole } = useSelector(
    (state) => state.clients
  );
  const [RobotsData, setRobotsData] = useState([]);
  const [robotsList, setRobotsList] = useState([]);
  const [highlightedRobots, setHighlightedRobots] = useState([]);
  const dispatch = useDispatch();
  const [dataIndicator, setDataIndicator] = useState(false);

  const { mutate: getRobotsMutation } = useMutation("get-robots", () =>
    getRobots(chosenClient)
  );

  const getAllRobots = useCallback(() => {
    getRobotsMutation(null, {
      onSuccess: (robots) => {
        setRobotsData(robots);
        setRobotsList(robots);
      },
      onError: (err) => console.log("Error", err),
    });
  }, [getRobotsMutation, setRobotsData]);

  useEffect(() => {
    chosenClient && getAllRobots();
  }, [chosenClient, getAllRobots]);

  useEffect(() => {
    if (client?.name && robotsList.length > 0) {
      joinSocketRoom(client.name);
      socket.on("robots_data", (data) => {
        setDataIndicator((prev) => !prev);
        const socketData = Object.values(data);
        let seenLowBatteryRobots =
          JSON.parse(sessionStorage.getItem("seen_low_battery_robots")) || [];
        let robotsQueue = [];
        socketData.forEach((el) => {
          if (
            el.battery &&
            !isNaN(el.battery.level) &&
            +el.battery.level * 100 !== 0 &&
            +el.battery.level * 100 < 20 &&
            !seenLowBatteryRobots.includes(el.robotId) &&
            el.robot_manager.status !== ROBOTSTATUSENUM.OFF
          ) {
            robotsQueue.push(el.robotId);
          }
        });
        dispatch(setLowBatteryRobotsQueue(robotsQueue));

        setRobotsData((prev) =>
          prev.map((robot) => {
            const robotData = socketData.find(
              (data) => data.robotId === robot.robotId
            );
            if (robotData) return { ...robot, ...robotData };
            else return robot;
          })
        );
      });

      socket.on("robot_warning", (data) => {
        if (data.message === "close_modal") {
          dispatch(setAbortTask(data));
        } else {
          dispatch(setRobotTaskWarningData(data));
        }
      });

      socket.on("emergency_button", (data) => {
        dispatch(setEmergencyPressedRobots(data));
      });

      socket.on("teleop_notification", (data) => {
        const foundRobot = robotsList.find(
          (robot) => robot.robotId === data.robotId
        );
        if (
          [
            ROLESENUM.ADMIN,
            ROLESENUM.CLIENTADMIN,
            ROLESENUM.TELEOPERATOR,
          ].includes(currentRole)
        ) {
          dispatch(
            setToastMessages({
              text:
                data?.clientName + " " + data?.robotId + " " + data?.message,
              type: data?.severity?.toLowerCase(),
              redirect: `${URLS.teleoperation}/${foundRobot?.id}`,
            })
          );

          playAudio(
            "https://fms-s3-dev.s3.eu-central-1.amazonaws.com/audio/fleet-task-audio.mov"
          );

          // Highlight the robot card
          setHighlightedRobots((prev) => {
            if (prev.includes(data.robotId)) return prev;

            setTimeout(() => {
              setHighlightedRobots((prev) =>
                prev.filter((id) => id !== data.robotId)
              );
            }, 10000); // 10 seconds

            return [...new Set([...prev, data.robotId])];
          });
        }
      });

      return () => {
        socket.emit("leaveRoom", client.name);
        socket.off("connect");
        socket.off("disconnect");
        socket.off("robots_data");
        socket.off("robot_warning");
        socket.off("emergency_button");
      };
    }
  }, [client, dispatch, currentRole, robotsList]);

  useEffect(() => {
    dispatch(setRobotsDataRedux(RobotsData));
  }, [RobotsData, dispatch]);

  return (
    <RobotsDataContext.Provider
      value={{
        RobotsData,
        setRobotsData,
        dataIndicator,
        highlightedRobots,
      }}
    >
      {children}
    </RobotsDataContext.Provider>
  );
};
