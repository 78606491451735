import styled from "styled-components";
import { ASSIGNMENTPROGRESSTEXTENUM, ROBOTTASKSTATUSENUM } from "../../enums";
export const WhiteWrapper = styled.div`
  width: 100%;
  background: ${(props) => (props.isMobile ? "" : "#fff")};
  box-shadow: ${(props) =>
    props.isMobile ? "unset" : "0px 8px 16px rgba(17, 17, 17, 0.04)"};
  border-radius: 16px;
`;

export const DashboardTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0686d8;
`;

export const AgendaHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isMobile ? "15px 0px 10px" : "24px 24px 10px 24px"};
  /* border-bottom: 0.5px solid rgba(144, 148, 145, 0.4); */
`;

export const AgendaTitle = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: #030a1b;
  font-family: "AssistantSemiBold", "Ubuntu-Medium";
  font-weight: 500;
`;

export const AgendaPageWrapper = styled.div`
  display: flex;
  margin-top: ${(props) => (props.isMobile ? "" : "30px")};
  gap: 20px;
  height: 100%;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) =>
    props.isMobile ? "10px" : props.isTablet ? "3px" : "20px"};
  position: relative;
`;
export const StyledDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 100px;
  line-height: 18px;
  font-weight: 600;
  color: #030a1b;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledDay = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  overflow: hidden;
`;

export const StyledDayWrapper = styled.div`
  cursor: pointer;
  padding: 10px 16px;
  background: #0096c6;
  width: 76px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: #11325f;
  }
`;

export const TimeTableWrapper = styled.div`
  /* padding: 24px; */
  padding: 0px 24px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledAgendaRow = styled.div`
  position: relative;
  min-height: 70px;
  display: flex;
  align-items: flex-end;
  border-bottom: 0.5px solid rgba(144, 148, 145, 0.4);
  /* padding: 15px 0px 0px 0px; */
`;
export const TimeDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  justify-content: flex-end;
`;
export const TimeAmount = styled.p`
  color: #08183f;
  font-weight: 400;
  font-size: 16px;
  line-height: 118%;
  letter-spacing: 0.01em;
`;

export const ToolTipWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  top: calc(0% - 35px);
  z-index: 1;
`;
export const AgendaAssignmentTooltip = styled.div`
  background: #909491;
  border-radius: 10px;
  border: unset;
  color: #fff;
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
`;
export const AgendaTooltipTriangle = styled.div`
  transform: rotate(0deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 8px 0px 7px;
  border-color: #909491 transparent transparent transparent;
  position: absolute;
  left: calc(50% - 7px);
  bottom: -10px;
`;
export const AssignmentsBox = styled.div`
  position: relative;
  user-select: none;
  border: unset;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  :last-child {
    margin-bottom: 10px;
  }
  &:hover ${ToolTipWrapper} {
    visibility: unset;
  }
`;

export const AssignmentBoxesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
`;
export const AssignmentMessage = styled.p`
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 118%;
  color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.INPROGRESS
      ? "#fff"
      : "#030a1b"};
`;
export const AssignmentMessageWrapper = styled.div`
  height: 16px;
  margin: 3px 0px;
  display: none;
`;
export const AssignmentNameAndTimeWrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
export const AssignmentWrapper = styled.div`
  width: 100%;
  height: 76px;
  /* position: relative; */
  border: 1px solid rgba(144, 148, 145, 0.3);
  border-radius: 8px;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  opacity: ${(props) => (props.isDragging ? "0.5" : "1")};
  background: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.INPROGRESS && "#0686D8"};
  border: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.WAITING && "1px solid"};

  border: ${(props) => props.isOpen && "1px solid rgba(144, 148, 145, 0.3)"};
  border-color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.WAITING &&
    props.isOpen &&
    "#D4B02F"};
  border-color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.DONE &&
    props.isOpen &&
    "#2FD453"};
  border-color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.FAILED &&
    props.isOpen &&
    "#D52F39"};
  border-color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.STOPPED &&
    props.isOpen &&
    "#E58B21"};
  border-color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.STOPPED_ASSIGNMENT &&
    props.isOpen &&
    "#E58B21"};
  border-color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.UPCOMING &&
    props.isOpen &&
    "#909491"};

  :hover {
    /* max-height: 73px; */
    border: 1px solid rgba(144, 148, 145, 0.3);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-color: ${(props) =>
      props.status === ASSIGNMENTPROGRESSTEXTENUM.WAITING && "#D4B02F"};
    border-color: ${(props) =>
      props.status === ASSIGNMENTPROGRESSTEXTENUM.DONE && "#2FD453"};
    border-color: ${(props) =>
      props.status === ASSIGNMENTPROGRESSTEXTENUM.FAILED && "#D52F39"};
    border-color: ${(props) =>
      props.status === ASSIGNMENTPROGRESSTEXTENUM.STOPPED && "#E58B21"};
    border-color: ${(props) =>
      props.status === ASSIGNMENTPROGRESSTEXTENUM.STOPPED_ASSIGNMENT &&
      "#E58B21"};
    border-color: ${(props) =>
      props.status === ASSIGNMENTPROGRESSTEXTENUM.UPCOMING && "#909491"};
    ${AssignmentMessage} {
      display: block;
      color: ${(props) =>
        props.status === ASSIGNMENTPROGRESSTEXTENUM.FAILED && "#D52F39"};
      color: ${(props) =>
        props.status === ASSIGNMENTPROGRESSTEXTENUM.DONE && "#2FD453"};
      color: ${(props) =>
        props.status === ASSIGNMENTPROGRESSTEXTENUM.STOPPED && "#E58B21"};
      color: ${(props) =>
        props.status === ASSIGNMENTPROGRESSTEXTENUM.STOPPED_ASSIGNMENT &&
        "#E58B21"};
    }
    ${AssignmentMessageWrapper} {
      display: ${(props) => props?.message && "block"};
    }
    ${AssignmentNameAndTimeWrapper} {
      gap: ${(props) => props.message && "0px"};
    }
  }

  :active {
    box-shadow: unset;
  }
`;
export const AssignmentName = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 118%;
  color: ${(props) =>
    props.status === ASSIGNMENTPROGRESSTEXTENUM.INPROGRESS
      ? "#fff"
      : "#030a1b"};
`;

export const StyledStartingTime = styled.p`
  color: #909491;
  font-size: 14px;
  line-height: 118%;
  font-weight: 400;
  color: ${(props) =>
    props.status === ROBOTTASKSTATUSENUM.INPROGRESS ? "#fff" : "#909491;"};
  opacity: ${(props) =>
    props.status === ROBOTTASKSTATUSENUM.INPROGRESS ? "0.7" : "1"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledWeekDays = styled.div`
  margin-top: ${({ margin }) => (margin ? "0" : "13px")};
  font-family: Ubuntu-light;
  font-size: 14px;
  line-height: 118%;
`;

export const AssignmentIcon = styled.img`
  /* position: absolute; */
  /* top: 16px; */
  /* right: 16px; */
  width: 16px;
`;

export const ShortcutsWrapper = styled.div`
  display: flex;
  flex-wrap: ${(props) => !props.isMobile && "wrap"};
  justify-content: space-between;
  gap: ${(props) => (props.isMobile ? "15px" : "")};
  width: 100%;
  overflow-x: ${(props) => props.isMobile && "auto"};
  padding: ${(props) => props.isMobile && "15px 5px"};
  cursor: pointer;
`;
export const ShortcutIconsBackground = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
`;

export const ShortCutButton = styled.button`
  min-width: ${(props) => (props.isMobile ? "115px" : "45%")};
  height: ${(props) => props.isMobile && "90px"};
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.isMobile && "center"};
  gap: 15px;
  margin-top: ${(props) => (props.isMobile ? "0px" : "20px")};
  border: ${(props) =>
    props.isMobile ? "1px solid #0686D8" : "1px solid #fff"};
  flex-direction: ${(props) => props.isMobile && "column"};

  cursor: pointer;
  :first-child {
    margin-top: unset;
  }
  :nth-child(2) {
    margin-top: unset;
  }

  :hover {
    border-color: ${(props) => !props.isMobile && "#0096c6"};
    transform: scale(1.03);
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.24);
  }
  &:hover ${ShortcutIconsBackground} {
    background: rgba(202, 240, 248, 0.3);
  }
  &:visited {
  }
`;

export const ShortcutIcon = styled.img``;
export const ShortcutName = styled.h4`
  color: #030a1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`;

export const RightPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FleetHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FleetTitle = styled.h3`
  color: #030a1b;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
`;
export const GoToMap = styled.p`
  font-size: 16px;
  text-decoration-line: underline;
  color: #0686d8;
  cursor: pointer;
`;

export const StyledAdjustMapText = styled.p`
  font-size: 16px;
  text-decoration-line: underline;
  color: #0686d8;
  cursor: pointer;
  margin-top: 10px;
`;
export const MapComponentWrapper = styled.div`
  border-radius: 16px;
  width: 470px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
`;

export const MapImage = styled.img``;

export const ListWrapper = styled.ul`
  margin-top: 20px;
  max-height: 100%;
  overflow-y: auto;
`;
export const ListRow = styled.li``;
export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 5px;
  cursor: pointer;
  gap: 10px;
  :hover {
    border-radius: 8px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.36);
    span {
      display: unset;
    }
  }
  :last-child {
    border-bottom: unset;
  }
`;

export const StyledTaskName = styled.div`
  font-weight: ${(props) => (props.isHovered ? "bold" : "")};
  font-size: 14px;
  line-height: 16px;
  color: #030a1b;
  width: 100%;
  display: flex;

  display: inline-block;
  text-transform: lowercase;

  ::first-letter {
    text-transform: capitalize;
  }
  justify-content: flex-end;
`;

export const PercentageWrapper = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPercentage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 118%;
  color: #ffffff;
`;

export const AgendaBoxTooltip = styled.span`
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e5eae7;
  box-shadow: 0px 4px 10px rgba(0, 147, 198, 0.18);
  padding: 15px;
  color: #08183f;
  font-weight: 600;
  font-size: 15px;
  width: 110px;
`;

export const IconWrapperDiv = styled.div`
  position: relative;
`;
export const StatusWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TaskStatusTooltip = styled.div`
  position: absolute;
  background: #0686d8;
  border-radius: 10px;
  border: unset;
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 147, 198, 0.18);
  top: calc(50% + -23px);
  left: calc(50% + 30px);
  white-space: nowrap;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
`;
export const TooltipTriangle = styled.div`
  transform: rotate(90deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 8px 0px 7px;
  border-color: #0686d8 transparent transparent transparent;
  position: absolute;
  left: -14px;
  top: 17px;
`;

export const CloseMapModalIcon = styled.img`
  position: absolute;
  top: 18px;
  right: 22px;
  cursor: pointer;
  z-index: 9999;
`;

export const LeftSideDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProgressWrapper = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressContent = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 14px;
`;

export const SentToRobotWrapper = styled.div`
  background: rgba(0, 150, 198, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SentToRobotContent = styled.div`
  color: #030a1b;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledDraggableWrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width}%` : "100%")};
`;

export const StyledVerticalDotsContainer = styled.div`
  position: absolute;
  right: -4px;
  top: 7px;
  z-index: 100;
`;
