import { Field, Form, Formik } from "formik";
import React, { useState } from "react";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import InfoModal from "../../components/info-modal/InfoModal";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

// import { SignupEmailSchema } from "../../schemas";

import {
  AddMemberContentWrapper,
  ForgotPasswordImg,
  FormContentWrapper,
  SubTitle,
  Title,
} from "./components";
import URLS from "../../constants/urls";
import resetPassword from "../../APIs/auth/resetPassword";
import { InfoModalTypes } from "../../constants/types";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const [formOpen, setFormOpen] = useState();
  const [sucessOpen, setSuccessOpen] = useState();

  const [userResetPasswordLoading, setUserResetPasswordLoading] =
    useState(false);
  const history = useHistory();

  const userResetPassword = async (vars, { setSuccessOpen }) => {
    setUserResetPasswordLoading(true);
    try {
      const res = await resetPassword(vars);
      if (res?.data?.error) {
        alert.error("There is no account with that email");
      } else {
        setSuccessOpen({
          type: InfoModalTypes.SUCCESS,
          isOpen: true,
          title: "Yeah!",
          subTitle: "Check your email.",
          content: "Reset password link is sent",
          onRequestClose: () => {
            history?.push(URLS.sign_in);
          },
        });
      }
    } catch (error) {}
    setUserResetPasswordLoading(false);
  };

  return (
    <OutlineNonAuthorised doubleBackground>
      <InfoModal
        {...sucessOpen}
        onRequestClose={() => {
          history?.push(URLS.sign_in);
        }}
        noOuterClickClosing
        okayText={"return to sign in"}
      />
      {formOpen ? (
        <AddMemberContentWrapper>
          <Title style={{ margin: "1.6rem 0" }}>
            {STRINGS.forgot_your_password}?
          </Title>
          <Formik
            onSubmit={(v) => userResetPassword(v, { setSuccessOpen })}
            // validationSchema={SignupEmailSchema}
            initialValues={{
              email: "",
            }}
          >
            {({ setFieldValue, errors, touched, isSubmitting }) => (
              <Form>
                <FormContentWrapper
                  style={{
                    width: "36.8rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  disabled={isSubmitting || userResetPasswordLoading}
                >
                  <Field id="email" name="email">
                    {({ field }) => (
                      <InputText
                        height="5.6rem"
                        margin="1.8rem 0"
                        borderColor={COLORS.secondary.water_gray}
                        placeholder={STRINGS.please_type_your_email}
                        handleChange={(v) => setFieldValue(field.name, v)}
                        value={field.value}
                        error={touched[field.name] && errors[field.name]}
                        icon={ICONS.Email}
                      />
                    )}
                  </Field>
                  <CustomButton
                    type="submit"
                    backgroundColor={COLORS.secondary.ncs}
                    width="100%"
                    padding="0 7.2rem"
                    height="5.8rem"
                    margin="2rem 0 1.4rem"
                    shadow
                    capitalize
                  >
                    {STRINGS.recover_password}
                  </CustomButton>
                </FormContentWrapper>
              </Form>
            )}
          </Formik>
        </AddMemberContentWrapper>
      ) : (
        <AddMemberContentWrapper style={{ padding: "66px 33px 66px" }}>
          <ForgotPasswordImg />

          <Title style={{ margin: "1.6rem 0" }}>
            {STRINGS.forgot_your_password}?
          </Title>
          <SubTitle style={{ width: "32.2rem" }}>
            {STRINGS.thats_okay_happens}
          </SubTitle>
          <CustomButton
            backgroundColor={COLORS.primary.oxford}
            height="5.8rem"
            margin="3.2rem 0"
            width="28.6rem"
            padding="0"
            shadow
            onClick={() => setFormOpen(true)}
          >
            {STRINGS.reset_your_pass}
          </CustomButton>
        </AddMemberContentWrapper>
      )}
    </OutlineNonAuthorised>
  );
};

export default ForgotPassword;
