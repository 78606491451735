import styled from "styled-components";

export const ElipsisAndTooltipTextWrapper = styled.div`
  width: 100%;
  display: flex;
  > div {
    display: flex;
    width: 100%;
  }
`;

export const StyledContent = styled.span`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;

  ::first-letter {
    text-transform: capitalize;
  }
`;
