import React, { useCallback, useEffect, useState } from "react";
import { StyledConfirmButton } from "./components";
import Modal from "react-modal";
import {
  CloseIconWrapper,
  ModalContentWrapper,
  ModalWarningHeader,
  ModalWarningText,
  ModalWarningTitle,
} from "../robot-task-warning-modal/components";
import ICONS from "../../constants/icons";
import { EditAssignmentButtonsWrapper } from "../agenda-assignment-details-modal/components";
import MapComponent from "../../pages/robots/MapComponent";
import COLORS from "../../constants/colors";
import { deleteFirstEmergencyPressedRobot } from "../../redux/robots";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setChosenBuilding, setChosenFloor } from "../../redux/maps";

const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "111",
  },
  content: {
    zIndex: "111",
    width: "40%",
    height: "fit-content",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    border: "3px solid #E40C0C",
    padding: "0 ",
  },
};

const EmergencyButtonModal = () => {
  const [currentRobot, setCurrentRobot] = useState();
  const { emergencyPressedRobots } = useSelector((state) => state.robots);
  const { buildingOptions, chosenBuilding, chosenFloor } = useSelector(
    (state) => state.maps
  );
  const dispatch = useDispatch();

  const mapName =
    buildingOptions.length > 1
      ? `${chosenBuilding} - ${chosenFloor}`
      : `${chosenFloor}`;
  useEffect(() => {
    let timeout = setTimeout(() => {
      //change map regarding this robot
      const map = emergencyPressedRobots[0]?.localization_status?.map_name;
      if (map) {
        dispatch(setChosenBuilding(map.split("__")[0]));
        dispatch(setChosenFloor(map.split("__")[1]));
      }
      setCurrentRobot(emergencyPressedRobots[0]);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [emergencyPressedRobots, dispatch]);

  const onRequestClose = useCallback(() => {
    dispatch(deleteFirstEmergencyPressedRobot());
    setCurrentRobot(null);
  }, [dispatch]);

  useEffect(() => {
    let timeout;
    if (currentRobot) {
      timeout = setTimeout(() => {
        onRequestClose();
      }, 30000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [currentRobot, onRequestClose]);
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={!!currentRobot}
      style={customStyles}
      ariaHideApp={false}
    >
      <ModalContentWrapper>
        <ModalWarningHeader>
          <CloseIconWrapper onClick={onRequestClose}>
            <img src={ICONS.BlackCross} alt="X" />
          </CloseIconWrapper>
          <img
            src={ICONS.AlarmIcon}
            alt="Warning"
            style={{ marginTop: "12px" }}
          />
          <ModalWarningTitle>
            {"Emergency Button Has Been Activated"}
          </ModalWarningTitle>
        </ModalWarningHeader>

        <ModalWarningText style={{ marginTop: "25px" }}>
          {`Emergency has been activated on the robot performing ${currentRobot?.current_task?.name} at ${mapName} . Please safely release the emergency button by following the instructions displayed on the robot's screen to allow operations to continue.`}
        </ModalWarningText>
        <div style={{ margin: "36px 0px" }}>
          <MapComponent
            mapWidth={516}
            mapHeight={386}
            pointWidth={0}
            robotWidth={20}
            showToolTip={false}
            // onlyRobotId={task?.robot?.id}
          />
        </div>

        <EditAssignmentButtonsWrapper
          style={{ padding: "unset", marginTop: "32px", width: "100%" }}
        >
          <StyledConfirmButton onClick={onRequestClose}>OK</StyledConfirmButton>
        </EditAssignmentButtonsWrapper>
      </ModalContentWrapper>
    </Modal>
  );
};

export default EmergencyButtonModal;
