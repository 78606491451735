import React, { useState } from "react";
import TeleopHeader from "./header/TeleopHeader";
import TeleopCard from "./card/TeleopCard";
import { AssignmentsPageWrapper } from "../robots/assignments/components";

const TeleoperationPage = () => {
  const [selectedRobot, setSelectedRobot] = useState([]);
  return (
    <React.Fragment>
      <TeleopHeader
        selectedRobot={selectedRobot}
        setSelectedRobot={setSelectedRobot}
      />
      <AssignmentsPageWrapper>
        {selectedRobot.map((robot) => (
          <TeleopCard robot={robot} />
        ))}
      </AssignmentsPageWrapper>
    </React.Fragment>
  );
};

export default TeleoperationPage;
