import React, { useContext, useMemo } from "react";
import { WhiteWrapper } from "../../../../components/commonStyles";
import {
  StyledLogsWrapper,
  StyledStatusLog,
  StyledTimeRange,
} from "../../../../components/agenda-assignment-details-modal/components";
import moment from "moment-timezone";
import StatusIcon from "../../../../components/agenda-assignment-details-modal/StatusIcon";
import {
  formatUpdatedAtTime,
  getTimeInfo,
} from "../../../../utils/tasks/timeUtils";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";
import {
  ROBOTSTATUSENUM,
  ROBOTSTATUSENUMALIAS,
  ROBOTTASKSTATUSENUM,
} from "../../../../enums";
import {
  EmptyAppRunningWrapper,
  RobotStatusWrapper,
  RunninAppHeader,
} from "../components";
import { RobotRowPhoto, StyledStatus } from "../../components";

const UTCCompareTime = 1730000000000;

const RobotRunningApp = ({ currentRobot }) => {
  const { isMobile } = useContext(ResponsiveContext);

  const currentTaskArchive = currentRobot?.currentTaskArchive;

  const itemToShow = useMemo(() => {
    const newArr = currentRobot?.currentTaskArchive?.statusHistory?.filter(
      (log) => log
    );

    return newArr?.reverse();
  }, [currentRobot]);

  const timeInfo = useMemo(
    () => getTimeInfo(currentTaskArchive, isMobile),
    [currentTaskArchive, isMobile]
  );

  const updatedAtTime = formatUpdatedAtTime(currentTaskArchive?.updatedAt);
  return (
    <WhiteWrapper
      style={{
        display: "flex",
        height: "150px",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        padding: "unset",
      }}
    >
      <RobotStatusWrapper>
        <RobotRowPhoto
          style={{ width: "25px", height: "25px" }}
          status={currentRobot?.robot_manager?.status}
          alt="Logo"
        />
        <StyledStatus
          status={currentRobot?.robot_manager?.status || ROBOTSTATUSENUM.OFF}
        >
          {ROBOTSTATUSENUMALIAS[
            currentRobot?.robot_manager?.status
              ?.toUpperCase()
              ?.replace(/\s/g, "")
          ] || ROBOTSTATUSENUM.OFF}
        </StyledStatus>
        {itemToShow?.length ? (
          <RunninAppHeader>
            <p>{currentTaskArchive?.task?.application?.aliasName}</p>
            {currentTaskArchive?.status !== ROBOTTASKSTATUSENUM.DEFAULT && (
              <StyledTimeRange>{timeInfo || updatedAtTime}</StyledTimeRange>
            )}
          </RunninAppHeader>
        ) : null}
      </RobotStatusWrapper>
      {itemToShow?.length ? (
        <StyledLogsWrapper style={{ maxHeight: "200px", width: "100%" }}>
          {itemToShow?.map((log, index) => (
            <StyledStatusLog key={"tasklog" + log.id + index}>
              <StatusIcon status={log.status} width={20} height={20} />
              <div style={{ width: "10%" }}>
                {moment(
                  log.id > UTCCompareTime ? log.id : log.id * 1000
                ).format("HH:mm")}
              </div>
              <div style={{ width: "85%" }}>{log.message}</div>
            </StyledStatusLog>
          ))}
        </StyledLogsWrapper>
      ) : (
        <EmptyAppRunningWrapper>
          <p style={{ marginBottom: "15px" }}>No App Running</p>
        </EmptyAppRunningWrapper>
      )}
    </WhiteWrapper>
  );
};

export default RobotRunningApp;
