import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  CalendarWithRangeWrapper,
  StyledCalendarHeader,
  StyledDateInput,
  StyledFooter,
  StyledRangeP,
  StyledRangeRow,
} from "./components";
import "./CalendarWithRange.css";
import moment from "moment-timezone";
import CustomButton from "../button/CustomButton";
import COLORS from "../../constants/colors";
const CalendarWithRange = ({ value = [], handleApply = () => {} }) => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  useEffect(() => {
    value && setDateRange(value);
  }, [value]);
  return (
    <CalendarWithRangeWrapper>
      <StyledCalendarHeader>
        <StyledRangeRow>
          <StyledRangeP>Start Date</StyledRangeP>
          <StyledDateInput>
            {moment(dateRange[0].toString()).format("L")}
          </StyledDateInput>
        </StyledRangeRow>
        <StyledRangeRow>
          <StyledRangeP>End Date</StyledRangeP>
          <StyledDateInput>
            {moment(dateRange[1].toString()).format("L")}
          </StyledDateInput>
        </StyledRangeRow>
      </StyledCalendarHeader>
      <Calendar
        selectRange={true}
        value={dateRange}
        onChange={setDateRange}
        className="react-calendar-with-range"
      />
      <StyledFooter>
        <CustomButton
          backgroundColor={COLORS.secondary.ncs}
          padding="0 7.2rem"
          height="4.8rem"
          margin="0 0 1rem 0"
          width="8.6rem"
          shadow
          onClick={() => handleApply(dateRange)}
        >
          Apply
        </CustomButton>
      </StyledFooter>
    </CalendarWithRangeWrapper>
  );
};

export default CalendarWithRange;
