import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import URLS from "../../constants/urls";
import {
  ArrowDownUp,
  LinkHoverTooltip,
  SidebarNavIcon,
  SidebarNavItem,
  SidebarNavText,
  SidebarTab,
  StyledNavLink,
  SubLinksWrapper,
} from "./components";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";
import { useDispatch } from "react-redux";
import { setSidebarOpen } from "../../redux/clients";
import { useSelector } from "react-redux";
import { ROLESENUM } from "../../enums";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const NavList = ({ setShowMobileSidebar }) => {
  const { isMobile } = useContext(ResponsiveContext);
  const { sidebarOpen, currentRole } = useSelector((state) => state.clients);
  const sideBarItems = [
    {
      icon: ICONS.NavDashboard,
      to: URLS.dashboard,
      title: STRINGS.sidebar_dashboard,
    },
    {
      icon: ICONS.CalendarIcon,
      to: URLS.calendar,
      title: STRINGS.calendar,
      hidden: isMobile,
    },
    {
      icon: ICONS.NavTeam,
      to: URLS.robots,
      title: STRINGS.sidebar_robots,
    },
    {
      icon: ICONS.Scheduler,
      to: URLS.assignments,
      title: STRINGS.assignments,
      hidden: currentRole === ROLESENUM.TELEOPERATOR || isMobile,
    },
    {
      icon: ICONS.Analytics,
      to: URLS.analytics,
      title: STRINGS.analytics,
    },
    {
      icon: ICONS.UsersGroup,
      to: URLS.user_management,
      title: STRINGS.user_management,
      hidden:
        ![ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN, ROLESENUM.MANAGER].includes(
          currentRole
        ) || isMobile,
    },
    {
      icon: ICONS.NavSettings,
      to: URLS.admin,
      title: STRINGS.admin,
      hidden:
        isMobile ||
        ![
          ROLESENUM.ADMIN,
          ROLESENUM.CLIENTADMIN,
          ROLESENUM.DEVELOPER,
          ROLESENUM.TELEOPERATOR,
        ].includes(currentRole),
    },
  ];
  const location = useLocation();
  const dispatch = useDispatch();
  const [showSubLinks, setShowSubLinks] = useState(false);

  const handleDropDown = () => {
    setShowSubLinks((prev) => !prev);
  };

  return sideBarItems.map((item, i) =>
    item.hidden ? null : (
      <React.Fragment key={"sidebarItem" + i}>
        <Link
          style={{ position: "relative" }}
          to={item.to}
          key={i}
          // onClick={item.onClick}
          onClick={(e) => {
            e.stopPropagation();
            item.title === STRINGS.admin && handleDropDown();
            !isMobile &&
              item.title !== STRINGS.admin &&
              `/${location.pathname?.split("/")?.[1]}` === item.to &&
              dispatch(setSidebarOpen());
            isMobile && setShowMobileSidebar(false);
          }}
        >
          <SidebarTab
            active={`/${location.pathname?.split("/")?.[1]}` === item.to}
            isOpen={sidebarOpen}
            isMobile={isMobile}
          >
            <LinkHoverTooltip>{item.title}</LinkHoverTooltip>
            <SidebarNavItem isMobile={isMobile}>
              <SidebarNavIcon icon={item.icon} />
              {item.title !== STRINGS.admin && sidebarOpen && (
                <SidebarNavText isMobile={isMobile}>
                  {item.title}
                </SidebarNavText>
              )}
              {sidebarOpen && (
                <React.Fragment>
                  <SidebarNavText>
                    {item.title === STRINGS.admin && (
                      <div>
                        {item.title}
                        <ArrowDownUp
                          src={showSubLinks ? ICONS.ArrowDown : ICONS.ArrowUp}
                          alt="arrow"
                        />
                      </div>
                    )}
                  </SidebarNavText>
                </React.Fragment>
              )}
            </SidebarNavItem>
          </SidebarTab>
        </Link>
        {item.title === STRINGS.admin && showSubLinks && sidebarOpen && (
          <SubLinksWrapper onClick={(e) => e.stopPropagation()}>
            {ROLESENUM.ADMIN === currentRole && (
              <>
                <StyledNavLink to={URLS.environment_setup}>
                  Environment
                </StyledNavLink>
                <StyledNavLink to={URLS.user_administration}>
                  User Administration
                </StyledNavLink>
              </>
            )}
            {[
              ROLESENUM.ADMIN,
              ROLESENUM.CLIENTADMIN,
              ROLESENUM.DEVELOPER,
            ].includes(currentRole) && (
              <>
                <StyledNavLink to={URLS.application}>Application</StyledNavLink>
                <StyledNavLink to={URLS.location}>Location</StyledNavLink>
                <StyledNavLink to={URLS.fleet_rules}>Fleet Rules</StyledNavLink>
                <StyledNavLink to={URLS.maps}>Maps</StyledNavLink>
              </>
            )}
            {[
              ROLESENUM.ADMIN,
              ROLESENUM.CLIENTADMIN,
              ROLESENUM.DEVELOPER,
              ROLESENUM.TELEOPERATOR,
            ].includes(currentRole) && (
              <>
                <StyledNavLink to={URLS.analytics_log}>
                  Analytics log
                </StyledNavLink>
                <StyledNavLink to={URLS.teleoperation}>
                  Teleoperation
                </StyledNavLink>
              </>
            )}
          </SubLinksWrapper>
        )}
      </React.Fragment>
    )
  );
};

export default NavList;
