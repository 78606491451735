import React from "react";
import { useContext } from "react";

import { RobotsDataContext } from "../../../contexts/RobotsDataContext";
import { ListWrapper } from "../components";
import RobotListRow from "./RobotListRow";

const FleetRobotsList = ({ setMapModal, setClickedRobot }) => {
  const { RobotsData } = useContext(RobotsDataContext);

  return (
    <ListWrapper>
      {[...RobotsData]
        .sort((a) => (a?.robot_manager?.status ? -1 : 1))
        .map((robot) => (
          <RobotListRow
            robot={robot}
            key={robot.id}
            setMapModal={setMapModal}
            setClickedRobot={setClickedRobot}
          />
        ))}
    </ListWrapper>
  );
};

export default FleetRobotsList;
