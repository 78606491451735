import React, { useContext } from "react";
import {
  ShortCutButton,
  ShortcutIcon,
  ShortcutIconsBackground,
  ShortcutName,
} from "../components";
import AssignCustomTaskModal from "../../../components/assign-custom-task-modal/AssignCustomTaskModal";
import { useSelector } from "react-redux";
import { ROLESENUM } from "../../../enums";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";

const ShortcutApplication = ({
  item,
  icon,
  shortcut,
  chosenApp,
  setChosenApp,
  setChosenAppFunc,
  isAppModalOpen,
  setIsAppModalOpen,
}) => {
  const { isMobile } = useContext(ResponsiveContext);
  const { currentRole } = useSelector((state) => state.clients);

  return (
    <React.Fragment>
      <ShortCutButton
        isMobile={isMobile}
        className="clickRevert"
        disabled={[ROLESENUM.VIEWER].includes(currentRole)}
        onClick={() => setChosenAppFunc(item)}
      >
        {!isMobile && (
          <ShortcutIconsBackground>
            <ShortcutIcon src={icon} alt="icon" />
          </ShortcutIconsBackground>
        )}
        <ShortcutName>{item.aliasName}</ShortcutName>
      </ShortCutButton>

      <AssignCustomTaskModal
        isOpen={isAppModalOpen}
        setIsOpen={setIsAppModalOpen}
        chosenApp={chosenApp}
        setChosenApp={setChosenApp}
        shortcut={shortcut}
      />
    </React.Fragment>
  );
};

export default ShortcutApplication;
