import React, { useEffect, useState } from "react";
import { SquareBlockColor, StyledLineConfigRow } from "./components";
import Slider from "../../../../components/slider/Slider";

const LineConfigRow = ({ el, index, video, handleColorOpacityChange }) => {
  const [sliderValue, setSliderValue] = useState(el.opacity);

  useEffect(() => {
    handleColorOpacityChange(el.type, video, index, "opacity", sliderValue);
  }, [el, handleColorOpacityChange, sliderValue, video, index]);

  return (
    <StyledLineConfigRow key={el.name}>
      <div className="color-div">
        <p>Color:</p>
        <SquareBlockColor color={el.color} />
      </div>

      <div className="position-div">
        <p>Position:</p>
        <span>{el.name}</span>
      </div>

      <div className="transparency-div">
        <p>Transparency:</p>
        <Slider
          value={sliderValue * 10}
          min={0}
          max={10}
          setValue={(val) => setSliderValue(val / 10)}
        />
      </div>
    </StyledLineConfigRow>
  );
};

export default LineConfigRow;
