import styled, { keyframes } from "styled-components";
import ICONS from "../../constants/icons";
import { ROBOTTASKSTATUSENUM } from "../../enums";

const growDown = keyframes`
  0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
`;
export const EditBoxWrapper = styled.div`
  display: none;
  margin-bottom: 15px;
`;
export const AssignmentDetailsHeader = styled.div`
  display: flex;
  padding: 24px 34px;
  :hover ${EditBoxWrapper} {
    display: block;
  }
`;
export const LeftDiv = styled.div`
  width: 80%;
`;
export const AssignmentDetailsTitle = styled.h2`
  font-family: "AssistantSemiBold", "Ubuntu-Medium";
  font-weight: 500;
  font-size: ${(props) => (props.calendar ? "24px" : "32px")};
  line-height: 118%;
  color: #030a1b;
  margin-top: 10px;
  word-wrap: break-word;
`;

export const StyledAssignmentStatus = styled.p`
  color: ${(props) =>
    props.status === ROBOTTASKSTATUSENUM.INPROGRESS
      ? "#0686D8"
      : props.status === ROBOTTASKSTATUSENUM.Failed
      ? "#F02121"
      : props.status === ROBOTTASKSTATUSENUM.DONE
      ? "#2FD553"
      : ROBOTTASKSTATUSENUM.SENTTOROBOT === props.status
      ? "#0686D8"
      : ROBOTTASKSTATUSENUM.DEFAULT === props.status && "#5C605D"};
  font-weight: 400;
  font-size: 14px;
  line-height: 118%;
`;
export const ModalForm = styled.div`
  padding: 0px 34px 24px 34px;
`;

export const AbortTaskIcon = styled.img`
  visibility: hidden;
  margin-top: -2px;
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const StyledRowIconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  /* width: 70px; */
`;
export const Arrow = styled.img`
  visibility: hidden;
  margin-top: -2px;
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const RowWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0px 14px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover ${Arrow} {
    visibility: visible;
  }
  &:hover ${AbortTaskIcon} {
    visibility: visible;
  }

  :hover {
    .edit-box-wrapper {
      display: flex;
    }
  }
  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }
`;

export const StyledTaskStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  > h4 {
    width: 20%;
    color: ${(props) =>
      props.status === ROBOTTASKSTATUSENUM.INPROGRESS
        ? "#0686D8"
        : props.status === ROBOTTASKSTATUSENUM.Failed
        ? "#F02121"
        : props.status === ROBOTTASKSTATUSENUM.DONE
        ? "#2FD553"
        : ROBOTTASKSTATUSENUM.SENTTOROBOT === props.status
        ? "#0686D8"
        : ROBOTTASKSTATUSENUM.DEFAULT === props.status && "#5C605D"};
  }
`;

export const StyledTaskName = styled.div`
  color: #030a1b;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  /* @media screen and (max-width: 800px) {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
`;
export const StatusAndNameWrapper = styled.div`
  /* width: calc(100% - 50px); */
  /* max-height: 88px; */
  width: calc(100%);
  border-bottom: ${(props) =>
    props.showDetails && "2px solid rgba(144, 148, 145, 0.4)"};
  margin-bottom: ${(props) => props.showDetails && "25px"};
  padding: 14px 0;
  padding-bottom: ${(props) => props.showDetails && "30px"};
  align-items: ${(props) => !props.isMessage && "center"};
  gap: 5%;
  cursor: ${(props) => !props.upcoming && "pointer"};
`;

export const StyledLastUpdate = styled.p`
  /* opacity: 0.5; */
  color: rgba(99, 102, 109, 0.6);
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  /* margin-top: ${(props) => (props.line === "1" ? "15px" : "0px")}; */
  transition: 0.2s;
`;

export const InfoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > div {
    display: none;
  }
  :hover {
    > div {
      display: block;
    }
  }
`;

export const StyledTaskLogWrapper = styled.div`
  width: 100%;
  /* animation: ${growDown} 300ms ease-in-out forwards; */
  /* transform-origin: top center; */
`;

export const StyledLine = styled.div`
  width: 95%;
  height: 2px;
  background: black;
  opacity: 0.2;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 7px;
`;

export const StyledInput = styled.input`
  width: 45%;
  height: 50px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 16px;
  padding: 0 24px;
`;

export const StyledParamsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StyledStatusLog = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(144, 148, 145, 0.3);
  gap: 50px;
  /* justify-content: flex-start; */
  align-items: center;
  padding: 15px;
`;

export const TaskStatusWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

export const LastUpdate = styled.div`
  /* opacity: 0.5; */
  color: rgba(99, 102, 109, 0.6);
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 10px;
`;

export const StyledTimeRange = styled.div`
  /* width: 140px; */
  white-space: nowrap;
  color: #030a1b;
  font-size: 16px;
`;

export const StyledArrowIcon = styled.img`
  width: 20px;
  align-items: center;
  margin-bottom: 15px;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
`;
export const NotFoundContent = styled.div`
  color: #030a1b;
  font-size: 14px;
`;

export const StyledLogsWrapper = styled.div`
  max-height: 48vh;
  overflow-y: auto;
`;

export const StyledSelect = styled.select`
  appearance: none;
  background-image: url(${ICONS.ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 11px;
  color: #030a1b;
  font-size: 16px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff"};

  width: 100%;
  height: 50px;
  border-radius: 16px;
  padding: 0 24px;
`;

export const StyledOption = styled.option`
  color: #0686d8;
  border: unset !important;
  padding: 20px !important;
  :active {
    background: #000;
  }
`;

export const StyledTitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-left: 5px;
`;

export const StyledStatusAndNameWrapper = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  @media screen and (max-width: 800px) {
    gap: 20px;
  }
`;

export const StyledAssignmentDate = styled.p`
  color: #030a1b;
  font-weight: 300;
  font-size: 20px;
  line-height: 118%;
  letter-spacing: 0.01em;
  font-family: Ubuntu-light;
`;
export const RightDiv = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
`;

export const BackWrapper = styled.div`
  display: flex;
  width: 55px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const StyledBackBtnText = styled.p`
  color: #0686d8;
  font-size: 16px;
`;

export const StyledEditAssignmentText = styled.div`
  font-size: 32px;
  line-height: 118%;
  margin-top: 20px;
`;

export const EditAssignmentButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 20px 35px 0px 35px;
`;

export const EditAssignmentButton = styled.button`
  width: 100%;
  padding: 14px 40px;
  border-radius: 48px;
  cursor: pointer;
  font-size: 16px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border: ${(props) => (props.border ? props.border : "unset")};

  @media screen and (max-width: 1300px) {
    padding: ${(props) => props.assignmentEditMode && "14px 20px"};
  }
  :disabled {
    cursor: not-allowed;
  }
`;

export const StyledChangesContentWrapper = styled.div`
  padding: 24px;
`;

export const StyledContentTitle = styled.p`
  color: #030a1b;
  text-align: center;
  font-size: 16px;
`;

export const StyledModalTitle = styled.h2`
  font-size: 24px;
`;

export const StyledModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTasksTitle = styled.h1`
  font-size: 24px;
  color: #030a1b;
  line-height: 118%;
`;

export const NoTasksComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    cursor: pointer;
  }
`;

export const StyledNoTaskTitle = styled.h3`
  font-size: 28px;
  color: #5c605d;
  font-weight: 700;
  margin-top: 40px;
  cursor: pointer;
`;

export const StyledNoTaskSubtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #5c605d;
  margin-top: 20px;
  text-align: center;
  padding: 24px;
`;

export const StyledTasksTitleWrapper = styled.div`
  width: 100%;
  padding: 24px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: unset;
  background: ${(props) =>
    props.disabled ? "rgba(212, 212, 212, 0.3)" : "unset"};
  width: 68px;
  height: 44px;
  cursor: pointer;
  :hover {
    background: ${(props) => !props.disabled && "rgba(6, 134, 216, 0.1)"};
  }
`;

export const StyledAddNewTaskBtn = styled.div`
  background: rgba(6, 134, 216, 0.1);
  border-radius: 6px;
  padding: 12px 24px;
  color: #0686d8;
  cursor: pointer;
`;

export const TaskEditModeWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  padding: 24px;
`;

export const StyledViewMapButton = styled.span`
  position: absolute;
  right: 15px;
  top: 92px;
  cursor: pointer;
  font-weight: bold;
  color: rgba(6, 134, 216, 1);
  font-size: 16px;
`;

export const ParametersTooltipWrapper = styled.div`
  width: 100%;
  /* padding: 16px; */
`;
export const StyledParTooltipHeader = styled.div`
  color: #030a1b;
  font-weight: 600;
  font-size: 20px;
  line-height: 118%;
`;

export const ParameterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 12px 0;
  border-bottom: 0.5px solid #d5d5d5;
  :last-child {
    border-bottom: unset;
  }
`;

export const StyledParName = styled.p`
  color: #909491;
  font-weight: 500;
  font-size: 16px;
  line-height: 118%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledParValue = styled.p`
  color: #0686d8;
  font-weight: 500;
  font-size: 16px;
  line-height: 118%;
`;

export const StyledCreatedBy = styled.p`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  margin: 5px 0;
`;
