import React, { useEffect, useState } from "react";
import {
  ComponentHeader,
  ComponentTitle,
} from "../environment-setup/components";
import STRINGS from "../../../constants/strings";
import { HeaderStyledButton } from "../../robots/assignments/components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import {
  AnalyticsHeader,
  AnalyticsHeaderBtn,
  AnalytisHeaderContainer,
} from "../analytics/components";
import AnalyticsLogTable from "./AnalyticsLogTable";
import SearchableSelect from "../../../components/searchable-select/SearchableSelect";
import { useSelector } from "react-redux";

const PERIODENUM = {
  FIRST: "1 hour",
  SECOND: "3 hours",
  THIRD: "Today",
  FOURTH: "Last week",
  FITH: "Last month",
};

const periodTypes = [
  PERIODENUM.FIRST,
  PERIODENUM.SECOND,
  PERIODENUM.THIRD,
  PERIODENUM.FOURTH,
  PERIODENUM.FITH,
];

const timeformat = "YYYY-MM-DD[T]HH:mm:ss";

const AnalyticsLog = () => {
  const [chosenPeriod, setChosenPeriod] = useState(
    sessionStorage.getItem("analyticsLogPeriod") || PERIODENUM.FIRST
  );
  const [chosenevent, setChosenevent] = useState(
    sessionStorage.getItem("analyticsLogEvent") || null
  );
  const [addEventModalOpen, setAddEventModalOpen] = useState(false);
  const { eventNames: eventsList } = useSelector((state) => state.analytics);
  const history = useHistory();

  useEffect(() => {
    if (chosenPeriod) {
      let params = new URLSearchParams();

      switch (chosenPeriod) {
        case PERIODENUM.FIRST:
          params.append(
            "from",
            moment().subtract(1, "hour").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        case PERIODENUM.SECOND:
          params.append(
            "from",
            moment().subtract(3, "hour").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        case PERIODENUM.THIRD:
          params.append(
            "from",
            moment(new Date()).startOf("day").format(timeformat).toString()
          );
          params.append("to", moment(new Date()).format(timeformat).toString());
          break;

        case PERIODENUM.FOURTH:
          params.append(
            "from",
            moment().subtract(7, "day").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        case PERIODENUM.FITH:
          params.append(
            "from",
            moment().subtract(31, "day").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        default:
          return;
        // params = search;
        // setChosenPeriod("Custom");
      }
      if (chosenevent) {
        params.append("event", chosenevent);
      }
      history.push({
        pathname: "/admin/analytics-log",
        search: params.toString(),
      });
    }
    sessionStorage.setItem("analyticsLogPeriod", chosenPeriod);
  }, [chosenPeriod, history, chosenevent]);

  return (
    <React.Fragment>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.analytics_log}`}</ComponentTitle>
        <div style={{ position: "relative" }}>
          <HeaderStyledButton onClick={() => setAddEventModalOpen(true)}>
            {STRINGS.add_event}
          </HeaderStyledButton>
        </div>
      </ComponentHeader>
      <AnalyticsHeader>
        <div style={{ width: "190px", position: "absolute", left: 0 }}>
          <SearchableSelect
            width="190px"
            value={chosenevent || "All events"}
            options={[
              { value: null, label: "All events" },
              ...eventsList.map((el) => ({
                value: el,
                label: el,
              })),
            ]}
            handleChange={(val) => {
              setChosenevent(val);
              sessionStorage.setItem("analyticsLogEvent", val);
            }}
          />
        </div>
        <AnalytisHeaderContainer>
          {periodTypes.map((el) => (
            <AnalyticsHeaderBtn
              key={el}
              chosen={chosenPeriod === el}
              onClick={() => setChosenPeriod(el)}
            >
              {el}
            </AnalyticsHeaderBtn>
          ))}
        </AnalytisHeaderContainer>
      </AnalyticsHeader>

      <AnalyticsLogTable
        addEventModalOpen={addEventModalOpen}
        setAddEventModalOpen={setAddEventModalOpen}
      />
    </React.Fragment>
  );
};

export default AnalyticsLog;
