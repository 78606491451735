import React, { useContext, useEffect, useState } from "react";
import StyledSelectMultiplyChoice from "../../../components/styled-select-multiply-choice/StyledSelectMultiplyChoice";
import { AdminTableContext } from "../../../contexts/AdminTableContext";
import { useTransitive } from "../../../contexts/TransitiveContext";
import { StyledInputTitle } from "../card/components";
import { useSelector } from "react-redux";
const TeleopRobotsSelector = ({ setSelectedRobot }) => {
  const { clients } = useContext(AdminTableContext);
  const { chosenClient } = useSelector((state) => state.clients);

  const { robotsList, clientsState, setClientsState } = useTransitive();
  const [robots, setRobots] = useState([]);

  const handleClientChange = async (id) => {
    setClientsState((prev) => {
      const updatedClients = prev.map((c) =>
        c.id === id ? { ...c, checked: !c.checked } : c
      );

      // Save checked client IDs to local storage
      const teleopClientIds = updatedClients
        .filter((c) => c.checked)
        .map((c) => c.id);
      localStorage.setItem("teleopClientIds", JSON.stringify(teleopClientIds));

      return updatedClients;
    });
  };
  const handleRobotChange = (id) => {
    setRobots((prevRobots) => {
      const updatedRobots = prevRobots.map((r) =>
        r.id === id ? { ...r, checked: !r.checked } : r
      );

      // Save checked robot IDs to local storage
      const teleopRobotIds = updatedRobots
        .filter((r) => r.checked)
        .map((r) => r.id);
      localStorage.setItem("teleopRobotIds", JSON.stringify(teleopRobotIds));

      return updatedRobots;
    });
  };

  useEffect(() => {
    if (!chosenClient) return;
    const storedClientIds =
      JSON.parse(localStorage.getItem("teleopClientIds")) || [];

    const updatedClientsState = clients.map((c) => {
      if (storedClientIds.includes(c.id)) {
        return { ...c, checked: true };
      }
      return { ...c, checked: false };
    });

    // If no stored client IDs are valid, default to chosenClient
    const hasValidStoredClients = updatedClientsState.some((c) => c.checked);
    if (!hasValidStoredClients) {
      setClientsState(
        clients.map((c) =>
          c.id === chosenClient
            ? { ...c, checked: true }
            : { ...c, checked: false }
        )
      );
    } else {
      setClientsState(updatedClientsState);
    }
  }, [clients, setClientsState, chosenClient]);

  useEffect(() => {
    setRobots(robotsList.map((r) => ({ ...r, checked: true })));
  }, [robotsList]);

  useEffect(() => {
    setSelectedRobot(robots.filter((r) => r.checked));
  }, [robots, setSelectedRobot]);

  useEffect(() => {}, [clientsState]);
  return (
    <div style={{ display: "flex", gap: "35px" }}>
      <div style={{ width: "250px" }}>
        <StyledInputTitle>Filter By Clients:</StyledInputTitle>
        <StyledSelectMultiplyChoice
          options={clientsState}
          LAYER={"All Clients"}
          handleCheckboxChange={handleClientChange}
        />
      </div>

      <div style={{ width: "250px" }}>
        <StyledInputTitle>Filter By Robots:</StyledInputTitle>
        <StyledSelectMultiplyChoice
          options={robots}
          LAYER={"All Robots"}
          handleCheckboxChange={handleRobotChange}
        />
      </div>
    </div>
  );
};

export default TeleopRobotsSelector;
