import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RobotTaskWarningModal from "./RobotTaskWarningModal";
import ResponseModal from "./ResponseModal";
import { setAbortTask } from "../../redux/robots";
import { setChosenBuilding, setChosenFloor } from "../../redux/maps";

const RobotWarningModalComponent = () => {
  const { robotTaskWarningData, robotsData } = useSelector(
    (state) => state.robots
  );

  const dispatch = useDispatch();
  const [responseModal, setResponseModal] = useState({
    isOpen: false,
    type: "",
  });

  const [isWarningModal, setIsWarningModal] = useState(false);
  useEffect(() => {
    if (robotTaskWarningData.length) {
      // change map where robot is localized
      const { robot } = robotTaskWarningData[0];
      const found = robotsData.find((el) => el.robotId === robot.robotId);
      const mapName = found?.localization_status?.map_name;
      if (mapName) {
        const building = mapName.split("__")[0];
        const floor = mapName.split("__")[1];
        dispatch(setChosenBuilding(building));
        dispatch(setChosenFloor(floor));
      }
      // open modal
      if (!responseModal.isOpen && robotTaskWarningData[0]) {
        setIsWarningModal(true);
      } else {
        setIsWarningModal(false);
      }
    }
  }, [robotTaskWarningData, robotsData, responseModal.isOpen, dispatch]);

  return (
    <React.Fragment>
      <RobotTaskWarningModal
        isOpen={isWarningModal}
        task={robotTaskWarningData[0]}
        setResponseModal={setResponseModal}
        setIsWarningModal={setIsWarningModal}
      />
      <ResponseModal
        type={responseModal.type}
        isOpen={responseModal.isOpen}
        task={robotTaskWarningData[0]}
        requestClose={() => {
          dispatch(setAbortTask(robotTaskWarningData[0]));
          setResponseModal((prev) => ({ ...prev, isOpen: false, type: "" }));
        }}
      />
    </React.Fragment>
  );
};

export default RobotWarningModalComponent;
