import React from "react";
import { InputWrapper } from "../../input/components";
import InputText from "../../input/InputText";
import COLORS from "../../../constants/colors";
import { CheckBoxWrapper } from "../../notifications-in-assignment/components";

const LocalizingInputs = ({ robotsDataForMap, handleChange }) => {
  return (
    <InputWrapper
      style={{
        height: "10%",
        justifyContent: "center",
        gap: "50px",
        pointerEvents: "auto",
      }}
    >
      <InputText
        type="number"
        width="15.4rem"
        height="6.4rem"
        borderColor={COLORS.secondary.water_green}
        placeholder={"Coordinates X"}
        handleChange={(e) => handleChange(e, "x_pixel")}
        name={"Coordinates X"}
        value={parseFloat(robotsDataForMap?.localization_data?.x_pixel).toFixed(
          1
        )}
        backgroundColor="#fff"
        // error={errorValidations.direction}
      />
      <InputText
        type="number"
        width="15.4rem"
        height="6.4rem"
        borderColor={COLORS.secondary.water_green}
        placeholder={"Coordinates Y"}
        name={"Coordinates Y"}
        handleChange={(e) => handleChange(e, "y_pixel")}
        value={parseFloat(robotsDataForMap?.localization_data?.y_pixel).toFixed(
          1
        )}
        // error={errorValidations.x}
        backgroundColor="#fff"
      />
      <InputText
        type="number"
        width="15.4rem"
        height="6.4rem"
        borderColor={COLORS.secondary.water_green}
        placeholder={"Angle"}
        handleChange={(e) => handleChange(e, "angle_degrees")}
        name={"Angle"}
        value={parseFloat(
          robotsDataForMap?.localization_data?.angle_degrees
        ).toFixed(1)}
        // error={errorValidations.y}
        backgroundColor="#fff"
      />

      <CheckBoxWrapper style={{ position: "absolute", right: "50px" }}>
        <input
          type="checkbox"
          id="force_localization"
          checked={robotsDataForMap?.localization_data?.force_localization}
          onChange={(e) => handleChange(e.target.checked, "force_localization")}
        />
        <label htmlFor="force_localization"> Force Localization</label>
      </CheckBoxWrapper>
    </InputWrapper>
  );
};

export default LocalizingInputs;
