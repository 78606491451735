import React, { useState } from "react";
import MapsManagementHeader from "./MapsManagementHeader";
import ModalOutline from "../modal-outline/ModalOutline";
import BuildingModalContent from "./BuildingModalContent";
import MapModalContent from "./MapModalContent";
import MapComponent from "../../pages/robots/MapComponent";
import useMaps from "../../hooks/useMaps";
import { useSelector } from "react-redux";
import { setMapModalData } from "../../redux/maps";
import { useDispatch } from "react-redux";
import AdjustMapModal from "../../pages/dashboard/robot-fleet/AdjustMap/AdjustMapModal";
import { StyledAdjustMapText } from "../../pages/dashboard/components";

const MapsManagement = () => {
  const [buildingModalData, setBuildingModalData] = useState(null);
  const [adjustModal, setAdjustModal] = React.useState(false);
  const {
    mapModalData,
    floorOptions,
    buildingOptions,
    mapOriginalHeight,
    mapOriginalWidth,
  } = useSelector((state) => state.maps);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({});

  const toggleAdjust = () => setAdjustModal((prev) => !prev);
  return (
    <React.Fragment>
      <MapsManagementHeader
        setBuildingModalData={setBuildingModalData}
        floorOptions={floorOptions}
        buildingOptions={buildingOptions}
      />
      <StyledAdjustMapText onClick={toggleAdjust}>
        Adjust map
      </StyledAdjustMapText>
      <AdjustMapModal isOpen={adjustModal} setIsOpen={setAdjustModal} />

      <div style={{ display: "flex", justifyContent: "center", gap: "50px" }}>
        <MapComponent
          mapWidth={500}
          robotWidth={19}
          pointWidth={0}
          vertical={mapOriginalHeight > mapOriginalWidth}
        />
        <MapComponent
          mapWidth={500}
          robotWidth={19}
          pointWidth={0}
          FLMap
          vertical={mapOriginalHeight > mapOriginalWidth}
        />
      </div>

      <ModalOutline
        headerBackground={"#0686D8"}
        heading={
          buildingModalData?.isCreated ? "Edit Building" : "Add New Building"
        }
        onRequestClose={() => setBuildingModalData(null)}
        isOpen={buildingModalData}
      >
        <BuildingModalContent
          onSubmit={() => setBuildingModalData(null)}
          buildingModalData={buildingModalData}
        />
      </ModalOutline>

      <ModalOutline
        headerBackground={"#0686D8"}
        heading={mapModalData?.isCreated ? "Edit Map" : "Add New Map"}
        onRequestClose={() => dispatch(setMapModalData(null))}
        isOpen={mapModalData}
      >
        <MapModalContent />
      </ModalOutline>
    </React.Fragment>
  );
};

export default MapsManagement;
