import React, { useContext } from "react";
import {
  CardInfoRow,
  PercentageWrapper,
  RobotLogoWrapper,
  RobotRowPhoto,
  RobotsRowButton,
  RobotsTableRow,
  StyledBattery,
  StyledCardHeader,
  StyledInfoKey,
  StyledInfoValue,
  StyledRobotCardInfo,
  StyledStatus,
} from "./components";
import URLS from "../../constants/urls";
import { Link } from "react-router-dom";
import STRINGS from "../../constants/strings";
import { UnderLine } from "../../components/add-assignment-modal/components";
import { ROBOTSTATUSENUM, ROBOTSTATUSENUMALIAS } from "../../enums";
import { determineRobotStatusText } from "../../utils/robots/robotStatusText";
import ICONS from "../../constants/icons";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const MobileRobotCard = ({
  item,
  showEdit,
  setShowEdit,
  handleEdit,
  setIsDeleteModal,
  setChosenItem,
  isCompanyMember,
}) => {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Link
      to={`${URLS.robots}/${item.id}`}
      key={item.id}
      style={{ flexBasis: "45%", marginTop: "20px" }}
    >
      <RobotsTableRow
        style={{
          padding: "10px",
          flexDirection: "column",
          marginTop: "unset",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <StyledCardHeader>
          <RobotLogoWrapper style={{ width: "unset" }}>
            <RobotRowPhoto
              style={{ width: "30px", height: "30px", margin: "unset" }}
              status={item?.robot_manager?.status}
              alt="Logo"
            />
            <StyledStatus
              status={item?.robot_manager?.status || ROBOTSTATUSENUM.OFF}
            >
              {ROBOTSTATUSENUMALIAS[
                item?.robot_manager?.status?.toUpperCase()?.replace(/\s/g, "")
              ] || ROBOTSTATUSENUM.OFF}
            </StyledStatus>
          </RobotLogoWrapper>
          <div>
            <RobotsRowButton
              isMobile={isMobile}
              style={{
                color: "#0686D8",
                width: "100px",
                height: "40px",
              }}
            >{`${STRINGS.view_details}>`}</RobotsRowButton>
          </div>
        </StyledCardHeader>
        <UnderLine style={{ margin: "8px 0" }} />
        <StyledRobotCardInfo>
          <CardInfoRow>
            <StyledInfoValue>{item?.name}</StyledInfoValue>
            <StyledInfoValue style={{ display: "flex" }}>
              {item?.robot_manager?.status &&
                item?.robot_manager?.status !== ROBOTSTATUSENUM.OFF && (
                  <>
                    <PercentageWrapper>
                      {(+item?.battery?.level * 100)?.toFixed(0)}%
                    </PercentageWrapper>
                    <StyledBattery
                      style={{ width: "38px", height: "18px", margin: "unset" }}
                      battery={(+item?.battery?.level)?.toFixed(2)}
                    >
                      {item?.robot_manager?.status ===
                        ROBOTSTATUSENUM.CHARGING && (
                        <img src={ICONS.ChargingIcon} alt="" />
                      )}
                    </StyledBattery>
                  </>
                )}
            </StyledInfoValue>
          </CardInfoRow>
          <CardInfoRow>
            <StyledInfoKey>Current task</StyledInfoKey>
            <StyledInfoValue>{determineRobotStatusText(item)}</StyledInfoValue>
          </CardInfoRow>
        </StyledRobotCardInfo>
      </RobotsTableRow>
    </Link>
  );
};

export default MobileRobotCard;
