import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RobotIconWrapper, StyledLocator, StyledNavMap } from "../components";
import GarySVG from "../../../assets/Gary.svg";

const RobotLocalizeOnMap = ({
  robotsDataForMap,
  lineStart,
  lineEnd,
  setLineStart,
  setLineEnd,
  setAngle,
  xScale,
  yScale,
  mapImgRef,
  scale,
  isToggle,
}) => {
  const { chosenMap } = useSelector((state) => state.maps);

  const [isDrawing, setIsDrawing] = useState(false);

  const handleRightClick = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setLineStart({ x, y });
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (isDrawing && lineStart) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setLineEnd({ x, y });

      // Calculate the angle in radians
      const deltaX = x - lineStart.x;
      const deltaY = y - lineStart.y;
      const angleInRadians = Math.atan2(deltaY, deltaX);

      // Convert the angle to degrees
      let angleInDegrees = angleInRadians * (180 / Math.PI);

      // Adjust the angle to be in the range of 0 to 360 degrees
      if (angleInDegrees < 0) {
        angleInDegrees += 360;
      }

      setAngle(angleInDegrees);
    }
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      setIsDrawing(false);
      setLineStart(null);
      setLineEnd(null);
    }
  };

  return (
    <StyledNavMap
      onMouseDown={handleRightClick}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{
        height: scale > 1 && "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {lineStart && lineEnd && (
        <svg
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        >
          <line
            x1={lineStart?.x}
            y1={lineStart?.y}
            x2={lineEnd?.x}
            y2={lineEnd?.y}
            stroke="red"
            strokeWidth="2"
          />
        </svg>
      )}
      <img
        ref={mapImgRef}
        src={`${isToggle ? chosenMap?.editedMapUrl : chosenMap?.mapUrl}`}
        style={{
          width: scale < 1 && "100%",
          height: scale > 1 && "100%",
        }}
        alt="map"
      />
      <RobotIconWrapper
        style={{
          left: robotsDataForMap?.localization_data?.x_pixel / xScale - 15,
          top: robotsDataForMap?.localization_data?.y_pixel / yScale - 15,
          transform: `rotate(${
            robotsDataForMap?.localization_data?.angle_degrees + 90
          }deg)`,
          position: "absolute",
          zIndex: 101,
        }}
      >
        <StyledLocator
          robotWidth={30}
          pointWidth={7}
          style={{
            backgroundImage: `url(${GarySVG})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
      </RobotIconWrapper>
    </StyledNavMap>
  );
};

export default RobotLocalizeOnMap;
