import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROBOTTASKSTATUSENUM, ROLESENUM, TASKLOGSTATUSENUM } from "../../enums";
import {
  RowWrapper,
  StatusAndNameWrapper,
  StyledTaskName,
  StyledLastUpdate,
  StyledTaskStatus,
  Arrow,
  TaskStatusWrapper,
  StyledTimeRange,
  StyledStatusAndNameWrapper,
  EditBoxWrapper,
  AbortTaskIcon,
  StyledRowIconsWrapper,
  StyledViewMapButton,
  ParametersTooltipWrapper,
  StyledParTooltipHeader,
  ParameterRow,
  StyledParName,
  StyledParValue,
} from "./components";
import ICONS from "../../constants/icons";
import TaskLog from "./TaskLog";
import StatusIcon from "./StatusIcon";
import { ArrowAndTimeWrapper } from "../Drawer/components";
import EditBox from "../edit-box/EditBox";
import { setDeleteTask, setStopTaskData } from "../../redux/dashboard";
import MapModal from "../../pages/dashboard/robot-fleet/MapModal";
import GetElementCoordinates from "../get-element-coordinates/GetElementCoordinates";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { formatUpdatedAtTime, getTimeInfo } from "../../utils/tasks/timeUtils";

const TaskParameters = ({
  item,
  setAssignmentsDetails,
  setTasksBeforeChanges,
  taskIndex,
}) => {
  const { locations } = useSelector((state) => state.maps);
  const { currentRole } = useSelector((state) => state.clients);
  const [parameters, setParameters] = useState([]);
  const [offsetHeight, setOffsetHeight] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const messageRef = useRef();
  const dispatch = useDispatch();
  const { isMobile } = useContext(ResponsiveContext);

  const iconsToShow = useMemo(() => {
    let arr = [];
    item.statusHistory?.forEach((statusHistory) => {
      if (
        statusHistory.position &&
        [TASKLOGSTATUSENUM.ERROR, TASKLOGSTATUSENUM.SUCCESS].includes(
          statusHistory.status
        )
      )
        arr.push({
          ...statusHistory,
          icon:
            statusHistory.status === TASKLOGSTATUSENUM.SUCCESS
              ? "#2FD453"
              : "#E40C0C",
        });
    });
    return arr;
  }, [item]);

  const mapModalTitle = useMemo(() => {
    if (item?.application?.aliasName && parameters[0])
      return item?.application?.aliasName + " - " + parameters[0];
    else return item?.application?.aliasName;
  }, [parameters, item]);

  const [mapModal, setMapModal] = useState(false);
  const toggleMapModal = () => setMapModal((prev) => !prev);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setOffsetHeight(messageRef?.current?.offsetHeight);
    }, 800);

    return () => clearTimeout(animationTimeout);
  }, [messageRef?.current?.offsetHeight]);

  useEffect(() => {
    if (item?.task || item?.parameters) {
      const arr = Object.values(item?.task?.parameters || item?.parameters)
        .map((item) => {
          if (typeof item === "object") {
            const location = locations.find(
              (loc) =>
                JSON.stringify(loc.pixelCoordinates) === JSON.stringify(item)
            );
            return location?.name;
          } else return null;
        })
        .filter((item) => item);
      setParameters(arr);
    }
  }, [item.task?.parameters, locations, item]);

  const timeInfo = useMemo(() => getTimeInfo(item, isMobile), [item, isMobile]);

  const updatedAtTime = formatUpdatedAtTime(item.updatedAt);

  const handleShowDetails = () => {
    if (
      item?.statusHistory?.length > 0 &&
      item?.status !== ROBOTTASKSTATUSENUM.DEFAULT
    ) {
      setShowDetails((prev) => !prev);
    }
  };

  const handleTaskEdit = () => {
    setTasksBeforeChanges((prev) => [...prev, item]);
    setAssignmentsDetails((prev) => ({
      ...prev,
      tasks: prev.tasks.map((task) =>
        task.id === item.id
          ? { ...task, isEditMode: true, disabled: false }
          : task
      ),
    }));
  };

  const ParametersInTooltip = () => {
    const KeyOfParam = (param, task) => {
      let found = param.list.find(
        (el) => el.value === item?.task?.parameters[param.parameter]
      );
      if (!found && task) {
        const paramValue = task?.parameters[param.parameter];
        let paramKey = "Default, no matching key found. Value: " + paramValue;
        task?.application?.parameters?.forEach((element) => {
          if (element.type === "key_value") {
            element?.list?.forEach((item) => {
              if (item.value === paramValue) paramKey = item.key;
            });
          }
        });

        return paramKey;
      }
      return found?.key;
    };
    return (
      <ParametersTooltipWrapper>
        <StyledParTooltipHeader>Details</StyledParTooltipHeader>
        {item?.application?.parameters.map((param) => (
          <ParameterRow>
            <StyledParName>{param?.parameter_name}</StyledParName>
            <StyledParValue>
              {item?.task
                ? param.type === "location"
                  ? item?.task?.parameters?.[param.parameter]?.name
                  : param.type === "key_value"
                  ? KeyOfParam(param)
                  : item?.task?.parameters[param.parameter]
                : param.type === "location"
                ? item?.parameters?.[param.parameter]?.name
                : param.type === "key_value"
                ? KeyOfParam(param, item)
                : item?.parameters?.[param.parameter]}
            </StyledParValue>
          </ParameterRow>
        ))}
      </ParametersTooltipWrapper>
    );
  };

  return (
    <RowWrapper>
      <StatusAndNameWrapper
        isMessage={item.message}
        showDetails={showDetails}
        onClick={handleShowDetails}
        upcoming={item?.status === ROBOTTASKSTATUSENUM.DEFAULT}
      >
        <TaskStatusWrapper>
          <div style={{ padding: "6px" }}>
            <StatusIcon
              status={item?.status}
              width={item.status === ROBOTTASKSTATUSENUM.DELAYED ? 21 : 18}
              height={item.status === ROBOTTASKSTATUSENUM.DELAYED ? 21 : 18}
              mt={1}
            />
          </div>
          <div style={{ width: "100%" }}>
            <StyledStatusAndNameWrapper>
              <StyledTaskStatus status={item?.status}>
                <h4>{item?.status}</h4>
              </StyledTaskStatus>
              <StyledTaskName>
                <GetElementCoordinates
                  text={<ParametersInTooltip />}
                  top={3}
                  // left={"5px"}
                  // arrowPosition={"right"}
                  type={taskIndex > 2 ? "top" : "left"}
                  styles={{
                    border: "2px solid #0686D8",
                    width: "430px",
                    background: "#FFFFFF",
                    color: "#08183F",
                    whiteSpace: "unset",
                    boxShadow: "0px 4px 10px rgba(0, 147, 198, 0.18)",
                  }}
                >
                  {!isMobile && (
                    <div style={{ display: "flex", padding: "6px" }}>
                      <img
                        src={ICONS.BlueInfoIcon}
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </div>
                  )}
                </GetElementCoordinates>
                <p style={{ display: "flex", alignItems: "flex-end" }}>
                  {item?.application?.aliasName}
                </p>
                {/* parameters?.length > 0 ? `- ${parameters.join(" , ")}` : "" */}
              </StyledTaskName>
            </StyledStatusAndNameWrapper>
          </div>
          <ArrowAndTimeWrapper>
            {item.status !== ROBOTTASKSTATUSENUM.DEFAULT && (
              <StyledTimeRange>{timeInfo || updatedAtTime}</StyledTimeRange>
            )}
            {item.status !== ROBOTTASKSTATUSENUM.DEFAULT && (
              <StyledRowIconsWrapper>
                {item?.status === ROBOTTASKSTATUSENUM.INPROGRESS && (
                  <AbortTaskIcon
                    src={ICONS.AbortIcon}
                    alt="stop"
                    onClick={() => dispatch(setStopTaskData(item))}
                  />
                )}
                <Arrow
                  src={showDetails ? ICONS.ArrowUp : ICONS.ArrowDown}
                  alt="arrow"
                />
              </StyledRowIconsWrapper>
            )}
            {item.status === ROBOTTASKSTATUSENUM.DEFAULT &&
              ![ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(
                currentRole
              ) && (
                <EditBoxWrapper className="edit-box-wrapper">
                  <EditBox
                    width={33}
                    handleEdit={handleTaskEdit}
                    handleDelete={() => {
                      dispatch(setDeleteTask(item));
                    }}
                  />
                </EditBoxWrapper>
              )}
          </ArrowAndTimeWrapper>
        </TaskStatusWrapper>
        {/* {parameters}
        {timeInfo} */}
        {!showDetails && item.message && (
          <div>
            <StyledLastUpdate
              ref={messageRef}
              line={offsetHeight < 20 ? "1" : "2"}
            >
              Last update: {item.message}
            </StyledLastUpdate>
          </div>
        )}
      </StatusAndNameWrapper>

      {showDetails && (
        <>
          {iconsToShow.length ? (
            <StyledViewMapButton onClick={toggleMapModal}>
              View map
            </StyledViewMapButton>
          ) : null}
          <TaskLog item={item} />
        </>
      )}

      <MapModal
        isOpen={mapModal}
        setIsOpen={setMapModal}
        title={mapModalTitle}
        showIcons={iconsToShow}
        showToolTip={true}
        onlyRobotId={"none"}
      />
    </RowWrapper>
  );
};

export default TaskParameters;
