import styled from "styled-components";

export const StyledTriggerEventsWrapper = styled.div`
  position: absolute;
  top: 100px;
  right: 20px;
  width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(2, 1, 47, 0.2);
  padding: 16px;
  z-index: 9999;
  border-radius: 16px;
  background: #fff;
`;
