import React from "react";
import { useDispatch, useSelector } from "react-redux";
import GarySVG from "../../assets/Gary.svg";
import FreeRobot from "../../assets/FreeRobot.svg";
import { setHoveredRobot } from "../../redux/robots";
import { RobotIconWrapper, StyledLocator } from "./components";
import GetElementCoordinates from "../get-element-coordinates/GetElementCoordinates";

const RobotOnMap = ({
  loc,
  scaleX,
  scaleY,
  pointWidth,
  mapRotateAngle,
  robotWidth,
  showToolTip,
}) => {
  const { hoveredRobot } = useSelector((state) => state.robots);
  const dispatch = useDispatch();
  const handleRobotHover = () => {
    !showToolTip && dispatch(setHoveredRobot(loc.id));
  };
  const handleMouseLeave = () => {
    !showToolTip && dispatch(setHoveredRobot(null));
  };

  return (
    loc.localization_status && (
      <RobotIconWrapper
        style={{
          left: scaleX * +loc.localization_status?.x_pixel - robotWidth / 2,
          top: scaleY * +loc.localization_status?.y_pixel - robotWidth / 2,
          position: "absolute",
          transition: "all 0.7s",
          zIndex: 101,
        }}
        onMouseEnter={() => handleRobotHover()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <GetElementCoordinates
          // left={`${robotWidth / 2}px`}
          styles={{
            background: "#FFFFFF",
            color: "#08183F",
            border: "1px solid #fff",
            boxShadow: "0px 4px 10px rgba(0, 147, 198, 0.18)",
          }}
          text={loc.name}
        >
          <StyledLocator
            key={`gary-pos-${loc.id}`}
            onClick={(e) => e.stopPropagation()}
            robotWidth={robotWidth}
            pointWidth={pointWidth}
            style={{
              transform: `rotate(${
                +loc.localization_status?.angle_degrees + 90
              }deg)`,
              backgroundImage: `url(${
                hoveredRobot === loc.id ? FreeRobot : GarySVG
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </GetElementCoordinates>
      </RobotIconWrapper>
    )
  );
};

export default RobotOnMap;
