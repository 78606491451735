import React, { useContext, useMemo } from "react";
import Calendar from "react-calendar";
import { HeaderWrapper } from "./components";
import OutsideClickHandler from "react-outside-click-handler";
import { useState } from "react";
import {
  CalendarWrapper,
  StyledDate,
  StyledDay,
  StyledDayWrapper,
} from "../dashboard/components";
import moment from "moment-timezone";
import ICONS from "../../constants/icons";
import { ComponentTitle } from "../admin-page/environment-setup/components";
import STRINGS from "../../constants/strings";
import {
  handleAssignmentDetailsUpdate,
  setAssignmentEditMode,
} from "../../redux/dashboard";
import { useDispatch } from "react-redux";
import { AssignmentsContext } from "../../contexts/AssignmentsContext";
import { useSelector } from "react-redux";
import { HeaderStyledButton } from "../robots/assignments/components";
import { ROLESENUM } from "../../enums";

const CalendarHeader = ({ setChosenDate, chosenDate, chosenWeekDays }) => {
  const { currentRole } = useSelector((state) => state.clients);
  const { calendarDate } = useSelector((state) => state.dashboard);
  const { assignmentTemplate } = useContext(AssignmentsContext);
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const handleDayChange = (action) => {
    const time = new Date(
      new Date(chosenDate)?.setDate(new Date(chosenDate)?.getDate() + action)
    );
    setChosenDate(time.toISOString());
  };

  const currentMonth = useMemo(() => {
    if (
      moment(chosenWeekDays[0]).format("MMMM") ===
      moment(chosenWeekDays[6]).format("MMMM")
    ) {
      return moment(new Date(chosenDate)).format("MMMM");
    } else {
      return `${moment(chosenWeekDays[0]).format("MMMM")} - ${moment(
        chosenWeekDays[6]
      ).format("MMMM")} `;
    }
  }, [chosenDate, chosenWeekDays]);

  return (
    <HeaderWrapper>
      <CalendarWrapper>
        <ComponentTitle>Calendar</ComponentTitle>
        <StyledDayWrapper
          className="clickRevert"
          onClick={() => setChosenDate(new Date().toISOString())}
        >
          <StyledDay>Today</StyledDay>
        </StyledDayWrapper>

        <img
          src={ICONS.DoubleArrowLeft}
          alt="left"
          style={{ cursor: "pointer" }}
          onClick={() => handleDayChange(-7)}
        />
        <img
          style={{ cursor: "pointer" }}
          src={ICONS.DoubleArrowRight}
          alt="right"
          onClick={() => handleDayChange(+7)}
        />
        <StyledDate
          style={{ width: "auto" }}
          onClick={() => setShowCalendar((prev) => !prev)}
        >
          {currentMonth}
        </StyledDate>

        {showCalendar && (
          <div
            className="calendar-page"
            style={{
              position: "absolute",
              zIndex: "99",
              top: "50px",
              right: "-90px",
            }}
          >
            <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
              <Calendar
                onChange={(value) => {
                  setChosenDate(value);
                  setShowCalendar(false);
                }}
                value={new Date(chosenDate)}
              />
            </OutsideClickHandler>
          </div>
        )}
      </CalendarWrapper>
      {![ROLESENUM.VIEWER, ROLESENUM.TELEOPERATOR].includes(currentRole) && (
        <HeaderStyledButton
          className="clickAllow clickRevert"
          onClick={() => {
            dispatch(
              handleAssignmentDetailsUpdate({
                ...assignmentTemplate,
                justCreated: true,
                executionTime: calendarDate,
              })
            );
            dispatch(setAssignmentEditMode(true));
          }}
        >{`${STRINGS.add_assignment}`}</HeaderStyledButton>
      )}
    </HeaderWrapper>
  );
};

export default CalendarHeader;
