import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "../../../../components/button/components";
import { StyledElevatorActionsWrapper, StyledInAndOut } from "./components";
import Loader from "../../../../components/loader/Loader";
import elevatorTrigger from "../../../../APIs/teleoperation/elevatorTrigger";
import { setToastMessages } from "../../../../redux/toast";
import restartElevator from "../../../../APIs/teleoperation/restartElevator";
import { ROLESENUM } from "../../../../enums";
import { useSelector } from "react-redux";

const ElevatorActions = ({
  handleElevatorModal,
  currentRobot,
  elevatorDataState,
  lastElevatorCalled,
}) => {
  const [inLoading, setInLoading] = useState(false);
  const [outLoading, setOutLoading] = useState(false);
  const [restartLoading, setRestartLoading] = useState(false);
  const { currentRole } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  const triggerElevator = useCallback(
    async (e, trigger_id, setLoading) => {
      setLoading(true);
      try {
        await elevatorTrigger({
          trigger_id,
          robotId: currentRobot?.robotId,
          clientName: currentRobot?.clientName,
        });
      } catch (error) {
        dispatch(
          setToastMessages({
            text: error?.response?.data?.message,
            type: "error",
          })
        );
        console.error(error);
      }
      setLoading(false);
      e.preventDefault();
    },
    [currentRobot, dispatch]
  );

  const handleElevatorRestart = async (e) => {
    setRestartLoading(true);
    try {
      await restartElevator({
        robotId: currentRobot.robotId,
        clientName: currentRobot.clientName,
      });
    } catch (error) {
      dispatch(
        setToastMessages({
          text: error?.response?.data?.message,
          type: "error",
        })
      );
      console.error(error);
    }
    setRestartLoading(false);
  };

  const isElevatorData =
    Object.keys(elevatorDataState).length && elevatorDataState.comingElevator;

  useEffect(() => {
    if (currentRobot?.clientName) {
      setInLoading(false);
      setOutLoading(false);
    }
  }, [currentRobot]);

  return (
    <>
      <StyledButton
        id="elevator-restart-button"
        backgroundColor={"#0096C6"}
        disabled={
          restartLoading ||
          !currentRobot?.clientName ||
          ![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole)
        }
        borderRadius={"24px"}
        width={"80px"}
        height={"40px"}
        padding="10px 20px"
        style={{ whiteSpace: "noWrap" }}
        onClick={(e) => {
          handleElevatorRestart();
          e.preventDefault();
        }}
      >
        {restartLoading ? (
          <Loader height={20} noText noMargin padding={0} />
        ) : (
          <span style={{ color: "#fff", fontSize: "16px" }}>Restart</span>
        )}
      </StyledButton>
      <StyledElevatorActionsWrapper>
        <StyledButton
          id="elevator-request-button"
          disabled={
            isElevatorData ||
            !currentRobot?.clientName ||
            ![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole)
          }
          backgroundColor={"#0096C6"}
          borderRadius={"48px"}
          width={"170px"}
          padding="10px 20px"
          style={{ whiteSpace: "noWrap" }}
          onClick={(e) => {
            handleElevatorModal("hallCall");
            e.preventDefault();
          }}
        >
          <span style={{ color: "#fff", fontSize: "16px" }}>
            Elevator request
          </span>
        </StyledButton>

        <StyledButton
          disabled={
            isElevatorData ||
            !currentRobot?.clientName ||
            !lastElevatorCalled ||
            ![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole)
          }
          backgroundColor={"#0096C6"}
          borderRadius={"48px"}
          width={"170px"}
          padding="10px 20px"
          style={{ whiteSpace: "noWrap" }}
          onClick={(e) => {
            handleElevatorModal("carCall");
            e.preventDefault();
          }}
        >
          <span style={{ color: "#fff", fontSize: "16px" }}>
            Elevator dispatch
          </span>
        </StyledButton>
        <div style={{ display: "flex", gap: "30px" }}>
          <StyledInAndOut
            background={"#397D48"}
            disabled={
              ![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole)
            }
            // disabled={inLoading || !elevatorDataState?.readyForTrigger}
            onClick={(e) => triggerElevator(e, 0, setInLoading)}
            id="elevator-in-button"
          >
            {inLoading ? (
              <Loader height={20} noText noMargin padding={0} />
            ) : (
              <span style={{ color: "#fff", fontSize: "16px" }}>In</span>
            )}
          </StyledInAndOut>
          <StyledInAndOut
            id="elevator-out-button"
            background={"#D42F39"}
            disabled={
              ![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole)
            }
            // disabled={outLoading || !elevatorDataState?.readyForTrigger}
            onClick={(e) => triggerElevator(e, 1, setOutLoading)}
          >
            {outLoading ? (
              <Loader height={20} noText noMargin padding={0} />
            ) : (
              <span style={{ color: "#fff", fontSize: "16px" }}>Out</span>
            )}
          </StyledInAndOut>
        </div>
      </StyledElevatorActionsWrapper>
    </>
  );
};

export default ElevatorActions;
