import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  StyledNavMap,
  StyledInteractiveMapWrapper,
  StyledNavMapWrapper,
  StyleUploadMapImageWrapper,
} from "./components";
import icons from "../../constants/icons";
import RobotOnMap from "./RobotOnMap";
import { InfoModalTypes } from "../../constants/types";
import InfoModal from "../info-modal/InfoModal";
import IconOnMap from "./IconOnMap";
import { handleMapDelete, setMapModalData } from "../../redux/maps";

export const NavigationMap = ({
  component_width,
  component_height,
  custom_style,
  robotsPosition,
  pointWidth = 12,
  robotWidth = 60,
  showToolTip = true,
  showDeleteMap,
  showIcons = [],
  FLMap,
  vertical,
}) => {
  const INITIAL_MAP_WIDTH = component_width || 1192;
  const INITIAL_MAP_HEIGHT = component_height || 649;

  const { map } = useSelector((state) => state.clients);
  const {
    chosenMapUrl,
    mapsData,
    chosenBuilding,
    chosenFloor,
    isMapLoading,
    chosenMap,
  } = useSelector((state) => state.maps);
  const dispatch = useDispatch();

  const [mapUrl, setMapUrl] = useState(map ? map?.editedMapUrl || false : null);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const [mapRotateAngle, setMapRotateAngle] = useState(0);

  const [mapWidth, setMapWidth] = useState(INITIAL_MAP_WIDTH);
  const [mapHeight, setMapHeight] = useState(INITIAL_MAP_HEIGHT);

  const [deleteModal, setDeleteModal] = useState(false);

  const [filteredRobotsPositions, setFilteredRobotsPositions] = useState();

  useEffect(() => {
    setMapUrl(FLMap ? chosenMap?.editedMapUrl : chosenMapUrl);
  }, [chosenMapUrl, FLMap, chosenMap]);

  useEffect(() => {
    const newRobotPositions = robotsPosition
      .map((pos) => {
        const mapName = pos?.localization_status?.map_name;
        if (
          mapName &&
          mapName === `${chosenBuilding}__${chosenFloor}`
          // mapName.includes(chosenBuilding) &&
          // mapName.includes(chosenFloor)
        )
          return pos;
        else return null;
      })
      .filter((item) => item);
    setFilteredRobotsPositions(newRobotPositions);
  }, [robotsPosition, chosenBuilding, chosenFloor]);

  const getImageDimensions = useCallback(
    async (url) => {
      let img = new Image();
      img.src = url;
      await img.decode();
      let width = img.width;
      let height = img.height;

      let newWidth = INITIAL_MAP_WIDTH;
      let locScale = height / width;

      let heigtToHave = INITIAL_MAP_WIDTH * locScale;

      if (height > width * 1.1) {
        if (heigtToHave < INITIAL_MAP_WIDTH) {
          setMapHeight(INITIAL_MAP_WIDTH);
          heigtToHave = INITIAL_MAP_WIDTH;
        } else {
          heigtToHave = INITIAL_MAP_WIDTH;
          setMapHeight(heigtToHave);

          if (heigtToHave / locScale > INITIAL_MAP_HEIGHT) {
            newWidth = INITIAL_MAP_HEIGHT;
            setMapWidth(newWidth);
            heigtToHave = INITIAL_MAP_HEIGHT * locScale;
            setMapHeight(heigtToHave);
          } else {
            setMapWidth(heigtToHave / locScale);
          }
        }
        setMapRotateAngle(vertical ? 0 : 90);
      } else {
        setMapRotateAngle(vertical ? 90 : 0);
        if (heigtToHave < INITIAL_MAP_HEIGHT) {
          setMapHeight(heigtToHave);
          setMapWidth(newWidth);
        } else {
          heigtToHave = INITIAL_MAP_HEIGHT;
          setMapHeight(heigtToHave);
          newWidth = heigtToHave / locScale;
          setMapWidth(newWidth);
        }
      }

      setScaleX(Math.max(newWidth, heigtToHave) / Math.max(width, height));
      setScaleY(Math.max(newWidth, heigtToHave) / Math.max(width, height));
    },
    [INITIAL_MAP_WIDTH, INITIAL_MAP_HEIGHT, vertical]
  );

  const handleMapPhotoUpload = () => {
    const found = mapsData
      .find((el) => el.building === chosenBuilding)
      ?.floors?.find((item) => item.floor === chosenFloor);
    dispatch(
      setMapModalData({
        id: found?.id,
        building: chosenBuilding,
        floorName: chosenFloor,
        isCreated: true,
      })
    );
  };

  const handleDeleteMap = async () => {
    dispatch(handleMapDelete({ chosenBuilding, chosenFloor }));
    setDeleteModal(false);
  };

  useEffect(() => {
    mapUrl && getImageDimensions(mapUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapUrl, vertical, INITIAL_MAP_WIDTH]);

  return (
    <StyledInteractiveMapWrapper custom_background={custom_style?.background}>
      <StyledNavMapWrapper
      // width={INITIAL_MAP_WIDTH}
      // heigth={INITIAL_MAP_HEIGHT}
      >
        {isMapLoading ? (
          <img src={icons.LoaderGIF} alt="loader" style={{ width: "300px" }} />
        ) : mapUrl ? (
          <>
            {filteredRobotsPositions.length || showIcons.length ? (
              <div
                style={{
                  transform: map?.angleDif
                    ? `rotate(${mapRotateAngle - map?.angleDif * 3.6}deg) `
                    : `rotate(${mapRotateAngle}deg)`,
                  position: "absolute",
                  width: `${mapWidth}px`,
                  height: `${mapHeight}px`,
                  zIndex: "101",
                }}
              >
                {filteredRobotsPositions.map((loc, index) => (
                  <RobotOnMap
                    mapRotateAngle={mapRotateAngle}
                    key={`robot-on-map-${index}`}
                    loc={loc}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    pointWidth={pointWidth}
                    robotWidth={robotWidth}
                    showToolTip={showToolTip}
                  />
                ))}
                {showIcons.map((loc, index) => (
                  <IconOnMap
                    mapRotateAngle={mapRotateAngle}
                    key={`robot-on-map-${index}`}
                    loc={loc}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    pointWidth={pointWidth}
                    robotWidth={robotWidth}
                    showToolTip={showToolTip}
                  />
                ))}
              </div>
            ) : null}
            {showDeleteMap && (
              <>
                {/* <StyledDeleteIcon
                onClick={() => setDeleteModal(true)}
                mapRotateAngle={mapRotateAngle}
                src={ICONS.DeleteIcon}
                alt="DeleteIcon"
              /> */}
                <InfoModal
                  style={{ maxWidth: "52.6rem" }}
                  isOpen={deleteModal}
                  setIsDeleteModal={setDeleteModal}
                  type={InfoModalTypes.WARNING}
                  itemId={map?.id}
                  title="Are you sure you want to delete the robot from the map?"
                  content="This will delete the map from the system."
                  noOuterClickClosing
                  cancelAction={() => setDeleteModal(false)}
                  submitAction={handleDeleteMap}
                  cancelText={"Cancel"}
                  submitText={"Yes Delete"}
                  disabled={false}
                />
              </>
            )}
            <StyledNavMap
              style={{
                background: `transparent url("${mapUrl}")`,
                transform: `rotate(${mapRotateAngle}deg)`,
                width: `${mapWidth}px`,
                height: `${mapHeight}px`,
                borderRadius: "20px",
              }}
            ></StyledNavMap>
          </>
        ) : (
          <StyleUploadMapImageWrapper>
            <button
              type="file"
              id="upload-map-image"
              onClick={handleMapPhotoUpload}
            >
              Upload edited map
            </button>
          </StyleUploadMapImageWrapper>
        )}
      </StyledNavMapWrapper>
    </StyledInteractiveMapWrapper>
  );
};
