import styled from "styled-components";

export const StyledLineConfigWrapper = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
  padding: 16px;
  z-index: 9999;
  border-radius: 16px;
  background: #fff;
`;

export const StyledLineConfigHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: flex-start;
  > p {
    font-size: 24px;
    color: #030a1b;
    font-weight: 400;
    white-space: nowrap;
  }
`;

export const StyledLineConfigRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 15px;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    > p {
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .line-width-div {
    display: flex;
    align-items: center;
    gap: 10px;
    > p {
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .position-div {
    width: 215px;
    > span {
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .transparency-div {
    width: 200px;
  }
`;

export const SquareBlockColor = styled.div`
  width: 20px;
  height: 20px;
  background: ${(props) => props.color};
`;

export const StyledVideoType = styled.p`
  padding: 10px 0 0 0;
  font-size: 16px;
  font-weight: 600;
`;
