import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../components/button/components";
import ICONS from "../../../constants/icons";
import startTeleop from "../../../APIs/teleoperation/startTeleop";
import stopTeleop from "../../../APIs/teleoperation/stopTeleop";
import { StyledSingleLineContainer } from "../../../components/commonStyles";
import Loader from "../../../components/loader/Loader";
import { ROLESENUM, TELEOPSTATUSENUM } from "../../../enums";
import { setToastMessages } from "../../../redux/toast";
import OutsideClickHandler from "react-outside-click-handler";
import { JoystickDescriptionWrapper } from "./components";

const TeleopActions = ({ currentRobot }) => {
  const [isTeleopActive, setIsTeleopActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showJoystickDescription, setShowJoystickDescription] = useState(false);
  const { chosenClient, currentRole } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  const handleStartTeleop = async () => {
    try {
      await startTeleop({
        clientName: currentRobot?.clientName,
        robotId: currentRobot.robotId,
        teleoperator_id: chosenClient,
      });
    } catch (error) {
      dispatch(
        setToastMessages({
          text: "Unable to start teleoperation",
          type: "error",
        })
      );
      console.log(error);
    }
  };

  const handleStopTeleop = async () => {
    try {
      await stopTeleop({
        clientName: currentRobot?.clientName,
        robotId: currentRobot.robotId,
        teleoperator_id: chosenClient,
      });
    } catch (error) {
      dispatch(
        setToastMessages({
          text: "Unable to stop teleoperation",
          type: "error",
        })
      );
      console.log(error);
    }
  };

  useEffect(() => {
    const status = currentRobot?.teleop_status?.status;
    if (status) {
      switch (status) {
        case TELEOPSTATUSENUM.IDLE:
          setIsLoading(false);
          setIsTeleopActive(false);
          break;
        case TELEOPSTATUSENUM.REQUESTING:
          setIsLoading(true);
          setIsTeleopActive(false);
          break;
        case TELEOPSTATUSENUM.TELEOPERATING:
          setIsLoading(false);
          setIsTeleopActive(true);
          break;
        case TELEOPSTATUSENUM.STOPPING_TELEOPERATION:
          setIsLoading(true);
          setIsTeleopActive(false);
          break;
        case TELEOPSTATUSENUM.NOT_AVAILABLE:
          setIsLoading(false);
          setIsTeleopActive(false);
          break;
        default:
          setIsLoading(false);
          setIsTeleopActive(false);
          break;
      }
    }
  }, [currentRobot]);
  return (
    <StyledSingleLineContainer
      style={{
        justifyContent: "flex-start",
        gap: "30px",
        width: "unset",
        position: "relative",
      }}
    >
      <StyledButton
        id="teleop-button"
        disabled={
          isLoading ||
          ![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole)
        }
        backgroundColor={isTeleopActive ? "#D42F39" : "#030A1B"}
        // borderColor={"#030A1B"}
        borderRadius={"48px"}
        padding="15px 40px"
        onClick={(e) => {
          e.preventDefault();
          isTeleopActive ? handleStopTeleop() : handleStartTeleop();
        }}
      >
        {isLoading ? (
          <Loader height={20} noText noMargin padding={0} />
        ) : (
          <span
            style={{ color: "#fff", fontSize: "16px", whiteSpace: "nowrap" }}
          >
            {isTeleopActive ? "Stop Teleoperation" : "Request Teleoperation"}
          </span>
        )}
      </StyledButton>
      <img
        src={isTeleopActive ? ICONS.MicrophoneOn : ICONS.MicrophoneDisabled}
        alt=""
      />
      <OutsideClickHandler
        onOutsideClick={() => setShowJoystickDescription(false)}
      >
        <img
          src={ICONS.Joystick}
          alt=""
          onClick={() => setShowJoystickDescription(true)}
        />
      </OutsideClickHandler>
      {showJoystickDescription && (
        <JoystickDescriptionWrapper>
          <img src={ICONS.DescriptionOfJoystick} alt="" />
        </JoystickDescriptionWrapper>
      )}
      {/* <StyledElevatorStatus /> */}
    </StyledSingleLineContainer>
  );
};

export default TeleopActions;
