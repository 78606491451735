import styled from "styled-components";

export const StyledElevatorWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  padding: 12px;
`;
export const StyledElevatorHeader = styled.div`
  display: flex;
  font-size: 18px;
  > span {
    font-size: 18px;
  }
`;

export const StyledElevatorData = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > p {
    font-size: 16px;
    white-space: nowrap;
  }
  > span {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    border: 1px solid #909491;
    background: #faf9f9;
    padding: 3px 7px;
    white-space: nowrap;
  }
`;

export const StyledElevatorActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 13px;
  justify-content: center;
`;

export const ElevatorModalContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
  gap: 30px;
`;

export const StyledInAndOut = styled.button`
  width: 68px;
  padding: 10px 20px;
  border-radius: 48px;
  background: ${({ background }) => background};
  white-space: nowrap;
  border: none;
  cursor: pointer;
  :disabled {
    opacity: 0.5;
  }
`;

export const StyledElevatorChooseP = styled.p`
  font-size: 8px;
  margin-left: 100px;
  margin-top: -10px;
`;
