import React, { useEffect, useState } from "react";
import getApplications from "../../../APIs/application/getApplications";
import {
  TableWrapper,
  TaskButton,
  TaskListWrapper,
  TasksHeader,
  TaskTitle,
} from "./components";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

import TaskRowComponent from "./TaskRowComponent";
import { useRef } from "react";
import updateTask from "../../../APIs/tasks/updateTask";
import addTask from "../../../APIs/tasks/addTask";
import useTasks from "../../../hooks/useTasks";
import { ROLESENUM } from "../../../enums";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getChangedIndexes, reorder } from "../../../utils/locationsReorder";
import updateTasksOrders from "../../../APIs/tasks/updateTasksOrders";
const Tasks = ({ chosenAssignment, setChosenAssignment }) => {
  const { chosenClient, currentRole } = useSelector((state) => state.clients);
  const bottomRef = useRef();
  const [appsData, setAppsData] = useState([]);
  const [isError, setIsError] = useState(false);
  const { handleTaskValidation } = useTasks({});
  useEffect(() => {
    chosenClient &&
      getApplications(chosenClient).then((data) => {
        setAppsData(data);
      });
  }, [chosenClient]);

  const handleAddTask = () => {
    setChosenAssignment((prev) => ({
      ...prev,
      tasks: [{ id: uuidv4(), isCreated: false }, ...prev.tasks],
    }));
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleSave = async (e, data, order) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = handleTaskValidation(data);

    if (!isValid) {
      setIsError(true);
    } else {
      const handleResponse = (data, responseData) => {
        setChosenAssignment((prev) => ({
          ...prev,
          tasks: prev?.tasks?.map((item) =>
            item.id === data.id
              ? {
                  ...item,
                  disabled: !item.disabled,
                  isCreated: true,
                  id: responseData.id,
                  order: responseData.order,
                }
              : item
          ),
        }));
      };

      const body = {
        applicationId: data.application.id,
        clientId: chosenClient,
        liableRobotId: data?.chosenRobot?.id,
        parameters: {
          ...data.parameters,
          // target_goal: data?.parameters?.target_goal,
        },
      };
      if (data.isCreated) {
        await updateTask(data.id, body).then((responseData) => {
          handleResponse(data, responseData.data);
          setIsError(false);
        });
      } else {
        await addTask(chosenAssignment.id, body).then((responseData) => {
          handleResponse(data, responseData);
          setIsError(false);
        });
      }
    }
  };

  const handleSaveOnOutClick = (e) => {
    chosenAssignment?.tasks?.forEach((item, index) => {
      if (!item.disabled) {
        handleSave(e, item, index);
      }
    });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      chosenAssignment.tasks,
      result.source.index,
      result.destination.index
    );

    const changedItems = getChangedIndexes(chosenAssignment.tasks, items);
    const lastChangedItem = changedItems[changedItems.length - 2];
    let arr = [];
    if (result.source.index < result.destination.index) {
      changedItems.forEach((el, index) => {
        if (index === changedItems.length - 1) {
          arr.push({ ...el, order: lastChangedItem.order });
        } else {
          arr.push({ ...el, order: el.order + 1 });
        }
      });
    }
    if (result.source.index > result.destination.index) {
      changedItems.forEach((el, index) => {
        if (index === 0) {
          arr.push({ ...el, order: changedItems[1].order });
        } else {
          arr.push({ ...el, order: el.order - 1 });
        }
      });
    }
    const updatedOrdersItems = items.map((item) => {
      const found = arr.find((el) => el.id === item.id);
      return found || item;
    });

    setChosenAssignment((prev) => ({ ...prev, tasks: updatedOrdersItems }));

    await updateTasksOrders(arr);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableWrapper onClick={handleSaveOnOutClick}>
        <TasksHeader>
          <TaskTitle>Tasks</TaskTitle>
          {![ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(
            currentRole
          ) && <TaskButton onClick={handleAddTask}>Add task</TaskButton>}
        </TasksHeader>
        <Droppable droppableId={"droppable"}>
          {(provided) => (
            <TaskListWrapper
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {chosenAssignment?.tasks?.map((item, index) => (
                <TaskRowComponent
                  handleSave={handleSave}
                  key={item.id}
                  item={item}
                  index={index}
                  appsData={appsData}
                  chosenAssignment={chosenAssignment}
                  setChosenAssignment={setChosenAssignment}
                  isError={isError}
                  setIsError={setIsError}
                />
              ))}
            </TaskListWrapper>
          )}
        </Droppable>
        <div ref={bottomRef} />
      </TableWrapper>
    </DragDropContext>
  );
};

export default Tasks;
