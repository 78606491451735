import React, { useRef, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ICONS from "../../constants/icons";
import {
  AGENDAASSIGNMENTSSTATUSENUM,
  ASSIGNMENTPROGRESSTEXTENUM,
  ROBOTTASKSTATUSENUM,
  ROLESENUM,
} from "../../enums";
import AgendaTooltip from "./AgendaTooltip";
import {
  AssignmentIcon,
  AssignmentMessage,
  AssignmentMessageWrapper,
  AssignmentName,
  AssignmentNameAndTimeWrapper,
  AssignmentsBox,
  AssignmentWrapper,
  IconWrapperDiv,
  ProgressContent,
  ProgressWrapper,
  StatusWrapper,
  StyledStartingTime,
  SentToRobotWrapper,
  SentToRobotContent,
  StyledVerticalDotsContainer,
} from "./components";
import ProgressPercentage from "./ProgressPercentage";
import useTimeFormat from "../../hooks/useTimeFormat";
import {
  handleAssignmentDetailsUpdate,
  setAssignmentEditMode,
  setDeleteTaskArchive,
  updateAssignmentRedux,
} from "../../redux/dashboard";
import GetElementCoordinates from "../../components/get-element-coordinates/GetElementCoordinates";
import ProgressBar from "../../components/porgressBar/ProgressBar";
import OutsideClickHandler from "react-outside-click-handler";
import {
  EditItem,
  StyledEditBox,
  StyledEditIcon,
} from "../admin-page/environment-setup/components";
import { InfoModalTypes } from "../../constants/types";
import InfoModal from "../../components/info-modal/InfoModal";
import updateTaskArchive from "../../APIs/tasks-archive/updateTaskArchive";
import deleteTaskArchiveById from "../../APIs/tasks-archive/deleteTaskArchiveById";
import FloatingElement from "../../components/floating-element/FloatingElement";

const AssignmentBox = ({ item, width, assignmentBoxRef, setIsOpen }) => {
  const { assignmentDetails } = useSelector((state) => state.dashboard);
  const { currentRole } = useSelector((state) => state.clients);
  const h3Ref = useRef();
  const widthRef = useRef();
  const { timeInterval } = useTimeFormat(item);
  const [maxWidthOfMessage, setMaxWidthOfMessage] = useState();
  const [showTaskEditBox, setShowTaskEditBox] = useState(false);
  const [isTaskDeleteModal, setIsTaskDeleteModal] = useState(false);
  const [isUpdateTaskStatusModal, setIsUpdateTaskStatusModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setMaxWidthOfMessage(h3Ref?.current?.offsetWidth);
  }, [h3Ref]);

  React.useEffect(() => {
    function handleResize() {
      setMaxWidthOfMessage(h3Ref?.current?.offsetWidth);
    }

    window.addEventListener("resize", handleResize);
  });

  const handleDeleteTask = async () => {
    await deleteTaskArchiveById(item.id);
    dispatch(setDeleteTaskArchive(item));
    setIsTaskDeleteModal(false);
  };

  const handleUpdateTaskStatus = async () => {
    const body = {
      taskArchiveId: item.id,
      status: ROBOTTASKSTATUSENUM.DONE,
      message: "Task finished successfully",
      updatedAt: item.statusHistory[item.statusHistory.length - 1]?.updated_at,
    };
    const updatedTask = await updateTaskArchive(body);
    dispatch(updateAssignmentRedux(updatedTask));
    setIsUpdateTaskStatusModal(false);
  };
  // item or assignment status icons
  const agendaItemStatusIcon = useMemo(() => {
    if (item.assignment) {
      switch (true) {
        case AGENDAASSIGNMENTSSTATUSENUM.DELAYED ===
          item.agendaAssignmentStatus:
          return (
            <AssignmentIcon
              src={ICONS.WaitingClock}
              alt="Delayed"
              style={{ width: "21px" }}
            />
          );

        case AGENDAASSIGNMENTSSTATUSENUM.FAILED === item.agendaAssignmentStatus:
          return (
            <AssignmentIcon
              style={{ width: "20px" }}
              src={ICONS.RedCross}
              alt="Failed"
            />
          );

        case AGENDAASSIGNMENTSSTATUSENUM.DONE === item.agendaAssignmentStatus:
          return (
            <AssignmentIcon
              style={{ width: "22px" }}
              src={ICONS.CheckGreen}
              alt="Done"
            />
          );

        case !item.tasks?.length:
          return (
            <AssignmentIcon
              src={ICONS.WarningTriangle}
              alt="Empty"
              style={{ width: "21px" }}
            />
          );

        case AGENDAASSIGNMENTSSTATUSENUM.INPROGRESS ===
          item.agendaAssignmentStatus:
          return (
            <ProgressPercentage progressPercentage={item.progressPercentage} />
          );
        case AGENDAASSIGNMENTSSTATUSENUM.STOPPED ===
          item.agendaAssignmentStatus ||
          AGENDAASSIGNMENTSSTATUSENUM.STOPPED_ASSIGNMENT ===
            item.agendaAssignmentStatus:
          return (
            <AssignmentIcon
              src={ICONS.StoppedOrangeIcon}
              alt="Empty"
              style={{ width: "21px" }}
            />
          );

        default:
          return null;
      }
    } else {
      switch (item.status) {
        case ROBOTTASKSTATUSENUM.DELAYED:
          return (
            <AssignmentIcon
              style={{ width: "21px" }}
              src={ICONS.WaitingClock}
              alt="waiting"
            />
          );

        case ROBOTTASKSTATUSENUM.Failed:
          return (
            <AssignmentIcon
              style={{ width: "20px" }}
              src={ICONS.RedCross}
              alt="Failed"
            />
          );

        case ROBOTTASKSTATUSENUM.DONE:
          return (
            <img style={{ width: "22px" }} src={ICONS.CheckGreen} alt="Done" />
          );
        case ROBOTTASKSTATUSENUM.STOPPED ||
          ROBOTTASKSTATUSENUM.STOPPED_ASSIGNMENT:
          return (
            <img
              src={ICONS.StoppedOrangeIcon}
              alt="Stopped"
              style={{ width: "21px" }}
            />
          );
        case ROBOTTASKSTATUSENUM.INPROGRESS:
          return (
            <ProgressWrapper>
              <ProgressContent>
                {ASSIGNMENTPROGRESSTEXTENUM.INPROGRESS}
              </ProgressContent>
            </ProgressWrapper>
          );

        case ROBOTTASKSTATUSENUM.SENTTOROBOT:
          return (
            <SentToRobotWrapper>
              <SentToRobotContent>Sending...</SentToRobotContent>
            </SentToRobotWrapper>
          );

        default:
          return null;
      }
    }
  }, [item]);

  const status = item?.assignment ? item?.agendaAssignmentStatus : item?.status;
  const relativeElementRef = useRef(null);
  return (
    <AssignmentsBox
      key={item.id}
      ref={assignmentBoxRef}
      style={{ width: width }}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen && setIsOpen(false);
        dispatch(handleAssignmentDetailsUpdate({ ...item, isCreated: true }));
        dispatch(setAssignmentEditMode(false));
      }}
      className="clickAllow clickRevert"
    >
      <AssignmentWrapper
        ref={relativeElementRef}
        message={item?.message}
        status={status}
        isOpen={
          assignmentDetails?.id + assignmentDetails?.agendaDate ===
          item?.id + item?.agendaDate
        }
        isDragging={item.isDragging}
      >
        <AssignmentNameAndTimeWrapper ref={h3Ref}>
          <AssignmentName
            status={status}
            style={{ maxWidth: `${maxWidthOfMessage}px` }}
          >
            {item?.name}
          </AssignmentName>
          <AssignmentMessageWrapper>
            <AssignmentMessage
              style={{ maxWidth: `${maxWidthOfMessage}px` }}
              status={status}
            >
              {item?.message}
            </AssignmentMessage>
          </AssignmentMessageWrapper>
          <StyledStartingTime status={status}>
            {timeInterval}
          </StyledStartingTime>
        </AssignmentNameAndTimeWrapper>
        <IconWrapperDiv>
          {item?.tasks?.length < 1 ? (
            <GetElementCoordinates
              styles={{
                border: "1px solid #C88F5B",
                background: "#fff",
                color: "#030A1B",
              }}
              text="No Task Assign"
            >
              <StatusWrapper>{agendaItemStatusIcon}</StatusWrapper>
            </GetElementCoordinates>
          ) : (
            <StatusWrapper>{agendaItemStatusIcon}</StatusWrapper>
          )}
        </IconWrapperDiv>
        {item.status === ROBOTTASKSTATUSENUM.SENTTOROBOT ? (
          <ProgressBar />
        ) : null}
        {[
          ROLESENUM.ADMIN,
          ROLESENUM.CLIENTADMIN,
          ROLESENUM.TELEOPERATOR,
        ].includes(currentRole) &&
          !item.assignment && (
            <StyledVerticalDotsContainer
              onClick={(e) => {
                e.stopPropagation();
                setShowTaskEditBox((prev) => !prev);
              }}
            >
              <img src={ICONS.VerticalDots} alt="" />

              {showTaskEditBox && (
                <FloatingElement parentRef={relativeElementRef}>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShowTaskEditBox(false);
                    }}
                  >
                    <StyledEditBox>
                      <EditItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // handleEdit();
                          setIsUpdateTaskStatusModal(true);
                          setShowTaskEditBox(false);
                        }}
                      >
                        <StyledEditIcon src={ICONS.PencilDraw} alt="Pencil" />
                        Update Status
                      </EditItem>
                      <EditItem
                        onClick={(e) => {
                          e.preventDefault();
                          // handleDelete();
                          setIsTaskDeleteModal(true);
                          e.stopPropagation();
                          setShowTaskEditBox(false);
                        }}
                      >
                        <StyledEditIcon
                          src={ICONS.DeleteIcon}
                          alt="DeleteIcon"
                        />
                        Delete
                      </EditItem>
                    </StyledEditBox>
                  </OutsideClickHandler>
                </FloatingElement>
              )}
            </StyledVerticalDotsContainer>
          )}
      </AssignmentWrapper>
      {h3Ref?.current?.clientWidth - h3Ref?.current?.scrollWidth < 0 && (
        <AgendaTooltip widthRef={widthRef} item={item} />
      )}
      <InfoModal
        isOpen={isTaskDeleteModal}
        setIsDeleteModal={setIsTaskDeleteModal}
        type={InfoModalTypes.WARNING}
        title="Are you sure to delete task?"
        noOuterClickClosing
        cancelAction={() => {
          setIsTaskDeleteModal(false);
        }}
        submitAction={handleDeleteTask}
        cancelText={"No"}
        submitText={"Yes"}
        disabled={false}
      />
      <InfoModal
        isOpen={isUpdateTaskStatusModal}
        setIsDeleteModal={setIsUpdateTaskStatusModal}
        type={InfoModalTypes.ATTENTION}
        title="Are you sure to change status to successful?"
        noOuterClickClosing
        cancelAction={() => {
          setIsUpdateTaskStatusModal(false);
        }}
        submitAction={handleUpdateTaskStatus}
        cancelText={"No"}
        submitText={"Yes"}
        disabled={false}
      />
    </AssignmentsBox>
  );
};

export default AssignmentBox;
