import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROBOTSTATUSENUM, ROBOTSTATUSENUMALIAS } from "../../../enums";
import { setHoveredRobot } from "../../../redux/robots";
import { determineRobotStatusText } from "../../../utils/robots/robotStatusText";
import {
  RobotLogoWrapper,
  RobotRowPhoto,
  StyledStatus,
} from "../../robots/components";
import { RowWrapper } from "../components";
import { UnderLine } from "../../../components/add-assignment-modal/components";
import { StatusAndNameWrapper } from "./components";
import ElipsisAndTooltipText from "../../../components/text-with-elipsis-and-tooltip/ElipsisAndTooltipText";
import { setChosenBuilding, setChosenFloor } from "../../../redux/maps";

const RobotListRow = ({ robot, setMapModal, setClickedRobot }) => {
  const { hoveredRobot } = useSelector((state) => state.robots);
  const { mapsData } = useSelector((state) => state.maps);
  const dispatch = useDispatch();
  const handleRobotHover = () => {
    dispatch(setHoveredRobot(robot.id));
  };
  const handleMouseLeave = () => {
    dispatch(setHoveredRobot(null));
  };

  const floorName = robot?.localization_status?.map_name?.split("__")?.last();
  const buildingName = robot?.localization_status?.map_name?.split("__")[0];

  const mapAliasName = mapsData
    .find((el) => el.building === buildingName)
    ?.floors.find((floor) => floor.floor === floorName)?.aliasName;

  const isHovered = robot.id === hoveredRobot;
  const handleRobotRowClick = () => {
    // setMapModal(true);
    const mapName = robot?.localization_status?.map_name;
    if (mapName) {
      const building = mapName.split("__")[0];
      const floor = mapName.split("__")[1];
      dispatch(setChosenBuilding(building));
      dispatch(setChosenFloor(floor));
    }
    setClickedRobot(robot.id);
  };
  return (
    <>
      <RowWrapper
        onMouseEnter={() => handleRobotHover()}
        onMouseLeave={() => handleMouseLeave()}
        onClick={handleRobotRowClick}
      >
        <RobotLogoWrapper
          style={{ display: "flex", flexDirection: "row", width: "unset" }}
        >
          <RobotRowPhoto status={robot?.robot_manager?.status} alt="Logo" />
          <StatusAndNameWrapper>
            <StyledStatus
              status={robot?.robot_manager?.status || ROBOTSTATUSENUM.OFF}
              style={{ textAlign: "left" }}
            >
              {ROBOTSTATUSENUMALIAS[
                robot?.robot_manager?.status?.toUpperCase()?.replace(/\s/g, "")
              ] || ROBOTSTATUSENUM.OFF}
            </StyledStatus>
            <span>{robot.name}</span>
          </StatusAndNameWrapper>
        </RobotLogoWrapper>

        <div style={{ maxWidth: "250px" }}>
          <ElipsisAndTooltipText
            style={{
              color: isHovered && "#030A1B",
              fontWeight: isHovered && "700",
            }}
            tooltipPosition={"bottom"}
          >
            {determineRobotStatusText(robot)}
          </ElipsisAndTooltipText>
        </div>

        {buildingName && mapsData?.length > 1 && (
          <div style={{ width: "85px" }}>
            <ElipsisAndTooltipText tooltipPosition={"bottom"}>
              {buildingName}
            </ElipsisAndTooltipText>
          </div>
        )}

        {mapAliasName &&
          (mapsData?.length > 1 || mapsData[0]?.floors?.length > 1) && (
            <div style={{ width: "85px" }}>
              <ElipsisAndTooltipText tooltipPosition={"bottom"}>
                {mapAliasName}
              </ElipsisAndTooltipText>
            </div>
          )}
      </RowWrapper>
      <UnderLine />
    </>
  );
};

export default RobotListRow;
