import styled, { css, keyframes } from "styled-components";
import { StatusColor, TeleopStatusColor } from "../../robots/components";

export const TeleopCardWrapper = styled.div``;

export const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding: 10px 16px;
  background: ${StatusColor};
  border-radius: 8px;
`;

export const StyledTeleopStatus = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding: 10px 16px;
  background: ${TeleopStatusColor};
  border-radius: 8px;
`;

export const StyledRobotName = styled.p`
  white-space: nowrap;
  font-size: 24px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTransitive = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .switch-icon {
    position: absolute;
    top: 10px;
    left: 30px;
    z-index: 9000;
  }

  ${({ toggle }) =>
    !toggle
      ? css`
          .webrtc-video1 {
            display: none;
          }
        `
      : css`
          .webrtc-video0 {
            display: none;
          }
        `}

  .teleop-lines-canvas {
    visibility: hidden;
  }
`;

export const StyledTaskAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const StyledFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledFooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #34619e1a;
  color: #34619e;
  border: unset;
  min-width: 100px;
  padding: 10px 16px;
  border-radius: 8px;
  margin-top: 10px;
`;

export const StyledInputTitle = styled.div`
  color: #030a1b;
  font-weight: 400;
  font-size: 16px;
  line-height: 118%;
  margin-left: 20px;
  margin-bottom: 7px;
`;

const borderHeartbeat = keyframes`
  0%, 100% {
    border-color: transparent;
  }
  50% {
    border-color: #DA0000;
  }
`;
export const StyledTeleopCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 24px;
  max-width: 360px;
  opacity: ${(props) => (props.opacity ? "100%" : "40%")};
  border: 3px solid #ffffff;
  ${(props) =>
    props.isHighlighted &&
    css`
      animation: ${borderHeartbeat} 1s infinite;
    `}

  cursor: pointer;
  :hover {
    border-color: #0686d8;
  }
  :active {
    border-color: #0686d8;
    box-shadow: ${(props) =>
      props.showEdit ? "unset" : "inset 0px 10px 30px rgba(0, 0, 0, 0.15)"};
  }
  .remote-teleop-joystick {
    display: none;
  }
`;
