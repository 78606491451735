import moment from "moment-timezone";
import { parseAssignment } from "./parseAssignments";

export const detectChanges = (currentModalData, newAssignmentModal) => {
  const first = parseAssignment(currentModalData);
  const updated = parseAssignment(newAssignmentModal);

  let isChanged = false;
  if (first.name !== updated.name) {
    isChanged = true;
  }
  if (
    moment(first.executionTime).format("LL") !==
    moment(updated.executionTime).format("LL")
  ) {
    isChanged = true;
  }
  if (first.time !== updated.time) {
    isChanged = true;
  }
  if (first.name !== updated.name) {
    isChanged = true;
  }
  if (first.disposable !== updated.disposable) {
    isChanged = true;
  }
  if (first.disposable === updated.disposable) {
    if (
      first.weekDays &&
      JSON.stringify(first.weekDays) !== JSON.stringify(updated.weekDays)
    ) {
      isChanged = true;
    }
  }
  return isChanged;
};
