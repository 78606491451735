export const helpCrunchSetup = (userData, loaded) => {
  if (loaded) {
    try {
      window.HelpCrunch("updateUser", userData);
    } catch (error) {
      console.log(error);
    }
  } else {
    window.helpcrunchSettings = {
      organization: "unlimitedrobotics",
      appId: "d0ef1d5f-63a7-4d67-a16e-1f5f0500ee4e",
      user: {
        user_id: userData?.id, // Unique user ID
        name: userData?.username, // User's name
        email: userData?.email, // User's email
        phone: userData?.phone, // Optional, if available
      },
    };
    const hS = window.helpcrunchSettings;
    if (!hS || !hS.organization) {
      return;
    }
    const widgetSrc = "https://embed.helpcrunch.com/sdk.js";
    window.HelpCrunch = function () {
      window.HelpCrunch.q.push(arguments);
    };
    window.HelpCrunch.q = [];
    function r() {
      if (document.querySelector('script[src="' + widgetSrc + '"]')) {
        return;
      }
      const s = document.createElement("script");
      s.async = 1;
      s.type = "text/javascript";
      s.src = widgetSrc;
      (document.body || document.head).appendChild(s);
    }
    if (document.readyState === "complete" || hS.loadImmediately) {
      r();
    } else if (window.attachEvent) {
      window.attachEvent("onload", r);
    } else {
      window.addEventListener("load", r, false);
    }
  }
};
