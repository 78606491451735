import React, { useEffect, useState } from "react";
import {
  InputWithBackgroundWrapper,
  ModalFormWrapper,
  PercentSign,
  StyledBatteryTypeContainer,
  StyledBatteryTypesWrapper,
  StyledModalSubtitle,
  StyledTypeTitle,
} from "./components";
import InputText from "../../../components/input/InputText";
import COLORS from "../../../constants/colors";
import {
  ButtonWrapper,
  StyledButton,
  UnderLine,
} from "../../../components/add-assignment-modal/components";
import ChargingMethod from "./ChargingMethod";
import { CHARINGMETHODSENUM } from "../../../enums";
import { v4 as uuidv4 } from "uuid";
import { hasCollision } from "./timeRangesCollisions";
import { InputError } from "../../../components/input/components";
import STRINGS from "../../../constants/strings";
import { shallowEqual, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import InfoModal from "../../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../../constants/types";
import { setBatterfyConfigurationData } from "../../../redux/robots";
import { useDispatch } from "react-redux";
import {
  backToFrontPipeline,
  frontToBackPipeline,
} from "./predefienedTimesHelper";
import updateRobotBatteryPolicy from "../../../APIs/mqtt/updateBatteryPolicy";
import getRobotBatteryPolicy from "../../../APIs/mqtt/getBatteryPolicy";

const BatteryConfigurationModal = ({ setResponseModalType }) => {
  const batterfyConfigurationData = useSelector(
    (state) => state.robots.batterfyConfigurationData,
    // Using shallowEqual from react-redux
    shallowEqual
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let date = new Date();
  const currentTime =
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes();
  const [batterySettingForm, setBatterySettingForm] = useState({
    critical_battery: 15,
    low_battery: 15,
    sufficient_battery: 15,
    charging_method: CHARINGMETHODSENUM.CHARING_AVAILABILITY,
    charging_availability: {
      inactivity_time: 300,
    },
    predefined_times: [
      {
        id: uuidv4(),
        week_days: [
          {
            index: 0,
            day: "Sun",
            chosen: false,
          },
          {
            index: 1,
            day: "Mon",
            chosen: false,
          },
          {
            index: 2,
            day: "Tue",
            chosen: false,
          },
          {
            index: 3,
            day: "Wed",
            chosen: false,
          },
          {
            index: 4,
            day: "Thu",
            chosen: false,
          },
          {
            index: 5,
            day: "Fri",
            chosen: false,
          },
          {
            index: 6,
            day: "Sat",
            chosen: false,
          },
        ],
        time: {
          from: currentTime,
          to: currentTime,
        },
      },
    ],
  });

  const handleParametersChange = (val, name) => {
    setBatterySettingForm((prev) => ({ ...prev, [name]: +val }));
  };

  const handleSave = async () => {
    if (hasCollision(batterySettingForm.predefined_times)) {
      setErrorMessage("Time collisions detected");
    } else {
      dispatch(setBatterfyConfigurationData(null));
      setIsConfirmModal(false);

      setErrorMessage(false);
      setResponseModalType("loading");
      setLoading(true);
      try {
        const updatedPreTimes = frontToBackPipeline(
          batterySettingForm.predefined_times
        );
        const data = {
          ...batterySettingForm,
          predefined_times: updatedPreTimes,
        };
        if (
          data.charging_method === CHARINGMETHODSENUM.CHARING_AVAILABILITY &&
          data.predefined_times
        )
          delete data.predefined_times;
        if (
          data.charging_method === CHARINGMETHODSENUM.PREDEFINED_TIMES &&
          data.charging_availability
        )
          delete data.charging_availability;
        await updateRobotBatteryPolicy(
          batterfyConfigurationData?.robotId,
          data
        );
        setResponseModalType("done");
      } catch (error) {
        console.log(error);
        setResponseModalType("error");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (batterfyConfigurationData?.robotId) {
      getRobotBatteryPolicy(batterfyConfigurationData.robotId)
        .then((data) => {
          const response = data.data.data;
          const updatedPreTimes = backToFrontPipeline(
            response.predefined_times
          );
          setBatterySettingForm((prev) => {
            const updatedForm = {
              ...prev,
              ...response,
              predefined_times: updatedPreTimes,
            };
            return updatedForm;
          });
        })
        .catch((err) => console.log(err));
    }
  }, [batterfyConfigurationData]);
  return (
    <>
      <ModalFormWrapper>
        <StyledModalSubtitle>{STRINGS.battery_levels}:</StyledModalSubtitle>
        <StyledBatteryTypesWrapper>
          <StyledBatteryTypeContainer>
            <StyledTypeTitle>{STRINGS.critical_battery}</StyledTypeTitle>
            <InputWithBackgroundWrapper>
              <InputText
                disabled={true}
                width="100px"
                height="5.4rem"
                type="number"
                padding="0 2.0rem"
                borderColor={COLORS.secondary.water_green}
                handleChange={(e) =>
                  handleParametersChange(e, "critical_battery")
                }
                value={`${batterySettingForm.critical_battery}` || ""}
                backgroundColor="#fff"
                margin="12px 0"
              />
              <PercentSign>%</PercentSign>
            </InputWithBackgroundWrapper>
          </StyledBatteryTypeContainer>
          <StyledBatteryTypeContainer>
            <StyledTypeTitle>{STRINGS.low_battery}</StyledTypeTitle>
            <InputWithBackgroundWrapper>
              <InputText
                disabled={false}
                width="100px"
                height="5.4rem"
                type="number"
                padding="0 2.0rem"
                borderColor={COLORS.secondary.water_green}
                handleChange={(e) => handleParametersChange(e, "low_battery")}
                value={batterySettingForm.low_battery || ""}
                backgroundColor="#fff"
                margin="12px 0"
              />
              <PercentSign>%</PercentSign>
            </InputWithBackgroundWrapper>
          </StyledBatteryTypeContainer>
          <StyledBatteryTypeContainer>
            <StyledTypeTitle>{STRINGS.sufficient_battery}</StyledTypeTitle>
            <InputWithBackgroundWrapper>
              <InputText
                disabled={false}
                width="100px"
                height="5.4rem"
                type="number"
                padding="0 2.0rem"
                borderColor={COLORS.secondary.water_green}
                handleChange={(e) =>
                  handleParametersChange(e, "sufficient_battery")
                }
                value={batterySettingForm.sufficient_battery || ""}
                backgroundColor="#fff"
                margin="12px 0"
              />
              <PercentSign>%</PercentSign>
            </InputWithBackgroundWrapper>
          </StyledBatteryTypeContainer>
        </StyledBatteryTypesWrapper>
        <UnderLine style={{ margin: "35px 0" }} />
        <ChargingMethod
          batterySettingForm={batterySettingForm}
          setBatterySettingForm={setBatterySettingForm}
        />

        <ButtonWrapper
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
            position: "relative",
          }}
        >
          {loading ? (
            <Loader height={40} noText noMargin padding={0} />
          ) : (
            <StyledButton onClick={() => setIsConfirmModal(true)}>
              {STRINGS.update_robot_now}
            </StyledButton>
          )}
        </ButtonWrapper>
        {errorMessage && (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <InputError style={{ top: "calc(100% + -1.4rem)" }}>
              *{errorMessage}
            </InputError>
          </div>
        )}
      </ModalFormWrapper>
      <InfoModal
        style={{ width: "500px" }}
        hideHeader
        isOpen={isConfirmModal}
        setIsDeleteModal={setIsConfirmModal}
        type={InfoModalTypes.WARNING}
        title={`Are you sure you want to send this configuration to ${batterfyConfigurationData?.robotId}?`}
        noOuterClickClosing
        cancelAction={() => setIsConfirmModal(false)}
        submitAction={async () => {
          await handleSave();
        }}
        cancelText={"Return to edit"}
        submitText={"Send to robot"}
        disabled={false}
      />
    </>
  );
};

export default BatteryConfigurationModal;
