import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import deleteAssignment from "../APIs/assignments/deleteAssignment";
import updateAssignment from "../APIs/assignments/updateAssignment";
import { ASSIGNMENTSTATUSENUM, ROLESENUM } from "../enums";
import { setToastMessages } from "../redux/toast";
import { AssignmentsContext } from "../contexts/AssignmentsContext";
import { useSelector } from "react-redux";

const useAssignments = ({
  // setAssignmentsData,
  // assignmentsData,
  setChosenAssignment,
  options,
}) => {
  const { currentRole } = useSelector((state) => state.clients);

  const { assignmentsData, setAssignmentsData } =
    useContext(AssignmentsContext);
  let date = new Date();
  const currentTime =
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes();

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [newAssignmentForm, setNewAssignmentForm] = useState({
    id: uuidv4(),
    name: "",
    disposable: false,
    status: ASSIGNMENTSTATUSENUM.Active,
    time: currentTime,
    weekDays: [
      {
        day: "Sun",
        chosen: true,
      },
      {
        day: "Mon",
        chosen: false,
      },
      {
        day: "Tue",
        chosen: false,
      },
      {
        day: "Wed",
        chosen: false,
      },
      {
        day: "Thu",
        chosen: false,
      },
      {
        day: "Fri",
        chosen: false,
      },
      {
        day: "Sat",
        chosen: false,
      },
    ],
    executionTime: new Date(),
  });
  const handleModalOpen = () => {
    if ([ROLESENUM.TELEOPERATOR, ROLESENUM.VIEWER].includes(currentRole))
      return;
    setCurrentModalData({});
    setNewAssignmentForm({
      id: uuidv4(),
      name: "",
      disposable: false,
      status: ASSIGNMENTSTATUSENUM.Active,
      time: currentTime,
      weekDays: [
        {
          day: "Sun",
          chosen: true,
        },
        {
          day: "Mon",
          chosen: false,
        },
        {
          day: "Tue",
          chosen: false,
        },
        {
          day: "Wed",
          chosen: false,
        },
        {
          day: "Thu",
          chosen: false,
        },
        {
          day: "Fri",
          chosen: false,
        },
        {
          day: "Sat",
          chosen: false,
        },
      ],
      executionTime: new Date(),
    });
    setIsOpen(true);
  };

  const handleToggle = (id, chosen) => {
    setAssignmentsData((prev) => {
      return prev
        .map((item) => {
          if (item.id === id) {
            let obj = {
              ...item,
              status: item.status === "off" ? "active" : "off",
            };

            updateAssignment(obj);
            const chosenOptions = options
              .filter((i) => i.checked)
              .map((i) => i.name);

            if (
              chosenOptions.includes(obj.status) ||
              chosenOptions.includes("All")
            )
              return obj;
            else return null;
          } else return item;
        })
        .filter((i) => i);
    });
    chosen &&
      setChosenAssignment((prev) => {
        let obj = { ...prev };
        obj.status === "off" ? (obj.status = "active") : (obj.status = "off");
        // updateAssignment(obj);
        return obj;
      });
  };

  const handleEdit = (item) => {
    setCurrentModalData(item);
    setIsOpen(true);
  };

  const handleDelete = useCallback(
    async (id) => {
      await deleteAssignment(id);
      const filteredArr = assignmentsData.filter((item) => {
        return item.id !== id;
      });
      dispatch(
        setToastMessages({ text: "Assignment deleted", type: "warning" })
      );
      setAssignmentsData(filteredArr);
    },
    [assignmentsData, dispatch, setAssignmentsData]
  );

  const handleDuplicate = useCallback(async (item, index) => {
    setIsOpen(true);
    setNewAssignmentForm({
      ...item,
      name: `${item.name} (Copy)`,
      id: uuidv4(),
      duplicated: index + 1,
    });
  }, []);

  return {
    handleToggle,
    handleEdit,
    handleModalOpen,
    handleDelete,
    newAssignmentForm,
    setNewAssignmentForm,
    setIsOpen,
    isOpen,
    currentModalData,
    setCurrentModalData,
    handleDuplicate,
  };
};

export default useAssignments;
