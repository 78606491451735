import axios from "axios";
import momentTimezone from "moment-timezone";

const getAnalyticsLogs = async (clientId, params) => {
  const res = await axios.get(
    `/analytics/logs/${clientId}?${params.toString()}&timezone=${encodeURIComponent(
      momentTimezone.tz.guess()
    )}`
  );
  return res.data;
};

export default getAnalyticsLogs;
