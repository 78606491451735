import "./App.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUserFunction } from "./redux/clients";
import "./APIs/axios";
import { SignIn } from "./pages/sign-in/SignIn";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import URLS from "./constants/urls";
import SignUp from "./pages/sign-in/SignUp";
import useRouting from "./hooks/useRouting";
import AuthorisedComponents from "./pages/authorised-routes/AuthorisedComponents";
import OutlineNonAuthorised from "./components/outline-non-authorised/OutlineNonAuthorised";
import MobileLink from "./components/agenda-assignment-details-modal/MobileLink";
import ForgotPassword from "./pages/sign-in/ForgotPassword";
import SetPassword from "./pages/sign-in/SetPassword";
import VerifyEmail from "./pages/sign-in/VerifyEmail";
import UserVerification from "./pages/sign-in/UserVerification";
import AddMember from "./pages/sign-in/AddMember";
import ResendVerification from "./pages/sign-in/ResendVerification";
import { helpCrunchSetup } from "./utils/helpcrunch";

function App() {
  const { PublicRoute } = useRouting();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) dispatch(getCurrentUserFunction());
    else helpCrunchSetup({});
  }, [dispatch]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <PublicRoute
            exact
            path={[URLS.sign_in, URLS.sign_in_with_token, "/"]}
            component={SignIn}
          />
          <PublicRoute exact path={URLS.sign_up} component={SignUp} />
          <PublicRoute exact path={URLS.verify_email} component={VerifyEmail} />
          <PublicRoute
            exact
            path={URLS.resend_verification}
            component={ResendVerification}
          />

          <PublicRoute exact path={URLS.add_member} component={AddMember} />

          <PublicRoute
            path={URLS.user_verification}
            component={UserVerification}
          />
          <PublicRoute
            exact
            path={URLS.forgot_password}
            component={ForgotPassword}
          />
          <PublicRoute exact path={URLS.set_password} component={SetPassword} />

          <Route exact path={URLS.drawer_for_mobile} component={MobileLink} />

          <AuthorisedComponents />
          <Route
            component={() => (
              <OutlineNonAuthorised>not found</OutlineNonAuthorised>
            )}
          />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
