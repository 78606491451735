import React, { useEffect, useMemo, useState } from "react";
import {
  StyledElevatorData,
  StyledElevatorHeader,
  StyledElevatorWrapper,
} from "./components";
import { LineVertical } from "../../components";
import ElevatorActions from "./ElevatorActions";
import RequestElevatorModal from "./RequestElevatorModal";
import socket from "../../../../APIs/socket/socket";

const Elevator = ({
  currentRobot,
  isElevatorModalOpen,
  direction,
  floor,
  toggleModal,
  setDirection,
  setFloor,
  callType,
  setCallType,
  chosenElevator,
  setChosenElevator,
}) => {
  const [elevatorDataState, setElevatorDataState] = useState({});
  const [lastElevatorCalled, setLastElevatorCalled] = useState(null);

  const elevatorEmergency =
    elevatorDataState?.mode === "EFO" || elevatorDataState?.mode === "EFS";

  useEffect(() => {
    if (currentRobot?.robotId && currentRobot?.clientName) {
      socket.on("elevator_data_" + currentRobot?.robotId, (data) => {
        setElevatorDataState(data);
        data?.lastElevatorCalled &&
          setLastElevatorCalled(data.lastElevatorCalled);
      });
    }

    return () => {
      if (currentRobot?.robotId && currentRobot?.clientName) {
        socket.off("elevator_data_" + currentRobot?.robotId);
      }
    };
  }, [currentRobot?.robotId, currentRobot?.clientName]);

  const coomingElevatorData = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          alignItems: "center",
          width: "100%",
        }}
      >
        <StyledElevatorData>
          <p>Door Status:</p>
          <span>{elevatorDataState?.doorStatus?.state}</span>
        </StyledElevatorData>
        <StyledElevatorData>
          <p>Elevator:</p>
          <span>{elevatorDataState?.comingElevator}</span>
        </StyledElevatorData>
        <StyledElevatorData>
          <p>Destination Floor:</p>
          <span>{elevatorDataState?.floor}</span>
        </StyledElevatorData>
        <StyledElevatorData>
          <p>Elevator is on floor:</p>
          <span>{elevatorDataState?.elevatorFloor}</span>
        </StyledElevatorData>
      </div>
    );
  }, [elevatorDataState]);

  const dispatchableElevatorData = useMemo(() => {
    const color = elevatorDataState?.lastCallErrored ? "red" : "green";
    const text = elevatorDataState?.lastCallErrored ? "Error" : "Success";

    return (
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          alignItems: "center",
          width: "100%",
        }}
      >
        <StyledElevatorData>
          <p>Door Status:</p>
          <span style={{ color }}>
            {elevatorDataState?.lastElevatorCalledDoor?.state}
          </span>
        </StyledElevatorData>
        <StyledElevatorData>
          <p>Elevator:</p>
          <span style={{ color }}>{lastElevatorCalled}</span>
        </StyledElevatorData>
        <StyledElevatorData>
          <p>Last call status:</p>
          <span style={{ color }}>{text}</span>
        </StyledElevatorData>
        <StyledElevatorData>
          <p>Elevator is on floor:</p>
          <span style={{ color }}>
            {elevatorDataState?.lastElevatorCalledFloor}
          </span>
        </StyledElevatorData>
      </div>
    );
  }, [elevatorDataState, lastElevatorCalled]);

  return (
    <StyledElevatorWrapper style={{ flexDirection: "row", height: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          <StyledElevatorHeader>
            Elevator Status -
            <span style={{ color: elevatorEmergency ? "red" : "green" }}>
              {elevatorEmergency
                ? " Emergency"
                : elevatorDataState?.comingElevator || lastElevatorCalled
                ? " Normal"
                : "N/A"}
            </span>
          </StyledElevatorHeader>
        </div>

        {elevatorDataState?.comingElevator
          ? coomingElevatorData
          : dispatchableElevatorData}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <LineVertical style={{ height: "100%" }} />

        <ElevatorActions
          currentRobot={currentRobot}
          elevatorDataState={elevatorDataState}
          handleElevatorModal={(call) => {
            setCallType(call);
          }}
          setLastElevatorCalled={setLastElevatorCalled}
          lastElevatorCalled={lastElevatorCalled}
        />
      </div>

      <RequestElevatorModal
        isOpen={isElevatorModalOpen}
        setIsElevatorModalOpen={toggleModal}
        currentRobot={currentRobot}
        callType={callType}
        lastElevatorCalled={lastElevatorCalled}
        direction={direction}
        floor={floor}
        setDirection={setDirection}
        setFloor={setFloor}
        chosenElevator={chosenElevator}
        setChosenElevator={setChosenElevator}
      />
    </StyledElevatorWrapper>
  );
};

export default Elevator;
