import moment from "moment-timezone";

export const changeHoursAndMinutesInISOString = (isoString, hours, minutes) => {
  const dateObject = moment(isoString);
  if (dateObject.isValid()) {
    dateObject.set({ hours, minutes });

    return dateObject.toISOString();
  } else {
    return isoString;
  }
};

export const isTimeFuture = (time, day) => {
  const hours = time.split(":")[0] - 1;
  const minutes = "00";

  const currentDay = new Date(
    changeHoursAndMinutesInISOString(new Date(), new Date().getHours(), minutes)
  ).getTime();

  const modifiedISOString = new Date(
    changeHoursAndMinutesInISOString(day, hours, minutes)
  ).getTime();

  return currentDay - modifiedISOString < 60000;
};
