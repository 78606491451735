import styled from "styled-components";

export const LocalizingModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LocalizingModalMapWrapper = styled.div`
  border-radius: 16px;
  height: 90%;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
`;

export const StyledMapName = styled.h4`
  position: absolute;
  top: 15px;
  left: 20px;
`;
