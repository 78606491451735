import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import deleteFloor from "../APIs/maps/deletefloor";

const initialState = {
  locations: [],
  mapsData: [],
  chosenMapUrl: "",
  mapModalData: null,
  chosenFloor: "",
  chosenBuilding: "",
  buildingOptions: [],
  floorOptions: [],
  isMapLoading: false,
  chosenMap: null,
  mapOriginalWidth: 0,
  mapOriginalHeight: 0,
};

const sortOptions = (options) => {
  const sortedOptions = [...options];
  sortedOptions.sort((a, b) => {
    const isANumber = /^\d/.test(a.label);
    const isBNumber = /^\d/.test(b.label);

    if (!isANumber && isBNumber) {
      return -1; // Text comes before numbers
    }
    if (isANumber && !isBNumber) {
      return 1; // Numbers come after text
    }
    // If both are numbers (or start with numbers), sort numerically
    if (isANumber && isBNumber) {
      return (
        parseFloat(a.label.match(/^\d+/)[0]) -
        parseFloat(b.label.match(/^\d+/)[0])
      );
    }
    // Otherwise, sort alphabetically
    return a.label?.localeCompare(b.label);
  });

  return sortedOptions;
};

export const handleMapDelete = createAsyncThunk(
  "maps/handleMapDelete",
  async ({ chosenBuilding, chosenFloor }, { rejectWithValue }) => {
    try {
      await deleteFloor(chosenBuilding, chosenFloor);
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);
export const mapsSlice = createSlice({
  name: "robots",
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    addLocation: (state, action) => {
      state.locations = [...state.locations, action.payload];
    },
    deleteLocation: (state, action) => {
      state.locations = [
        ...state.locations.filter((item) => action.payload !== item.id),
      ];
    },
    setMapsData: (state, action) => {
      state.mapsData = action.payload;
    },
    setChosenMapUrl: (state, action) => {
      state.chosenMapUrl = action.payload;
    },
    setMapModalData: (state, action) => {
      state.mapModalData = action.payload;
    },
    setChosenBuilding: (state, action) => {
      state.chosenBuilding = action.payload;

      const options =
        state.mapsData
          .find((el) => el.building === action.payload)
          ?.floors?.map((el) => ({ label: el.aliasName, value: el.floor })) ||
        [];

      state.floorOptions = sortOptions(options);
      state.chosenFloor = state.floorOptions[0]?.value || "";
    },
    setChosenFloor: (state, action) => {
      state.chosenFloor = action.payload;
    },
    setIsMapLoading: (state, action) => {
      state.isMapLoading = action.payload;
    },
    setBuildingOptions: (state, action) => {
      state.buildingOptions = action.payload;
    },
    setFloorOptions: (state, action) => {
      state.floorOptions = sortOptions(action.payload);
    },
    setChosenMap: (state, action) => {
      state.chosenMap = action.payload;
    },
    setMapOriginalWidth: (state, action) => {
      state.mapOriginalWidth = action.payload;
    },
    setMapOriginalHeight: (state, action) => {
      state.mapOriginalHeight = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handleMapDelete.fulfilled, (state, action) => {
        const updatedMapsData = state.mapsData.map((el) =>
          el.building === state.chosenBuilding
            ? {
                ...el,
                floors: el.floors.filter(
                  (item) => item.floor !== state.chosenFloor
                ),
              }
            : el
        );
        state.mapsData = updatedMapsData;
        state.floorOptions = state.floorOptions.filter(
          (el) => el !== state.chosenFloor
        );
        state.chosenFloor = state.floorOptions[0].value;
      })
      .addCase(handleMapDelete.rejected, (state, action) => {
        console.error("Error deleting floor:", action.payload);
      });
  },
});

export const {
  setLocations,
  addLocation,
  deleteLocation,
  setMapsData,
  setChosenMapUrl,
  setMapModalData,
  setChosenFloor,
  setChosenBuilding,
  setIsMapLoading,
  setBuildingOptions,
  setFloorOptions,
  setChosenMap,
  setMapOriginalWidth,
  setMapOriginalHeight,
} = mapsSlice.actions;

export default mapsSlice.reducer;
