const URLS = {
  sign_up: "/sign_up",
  sign_up_member: "/register-member",
  sign_in: "/sign_in",
  sign_in_with_token: "/sign_in/:token",
  set_password: "/set-password/:token",
  signup_password: "/signup_password",
  forgot_password: "/forgot_password",
  verify_email: "/verify_email",
  resend_verification: "/resend_verification",
  add_member: "/add-member/:token",
  user_verification: "/verify-email/:token",
  unity_google_signin: "/unity-google-sign-in",
  home: "/home",
  dashboard: "/dashboard",
  model: "/model",
  add_model: "/model/new",
  addmodel_manually: "/addmodel/manually",
  addmodel_upload_folder: "/addmodel/uploadfolder",
  // -- non authorised links -- //
  robotics_page: "https://www.unlimited-robotics.com",
  learning_center: "https://documentation.unlimited-robotics.com",
  community: "https://discord.gg/Db7hrrePhn",
  contact_us: "https://www.unlimited-robotics.com/contact",
  faq: "https://www.unlimited-robotics.com/faq",
  waiting_list: "https://www.unlimited-robotics.com/join",
  podcast: "https://open.spotify.com/show/46gnX1APnNCyp6EDLfb0lR",
  terms_of_use: "https://www.unlimited-robotics.com/terms-of-use",
  privacy_police: "https://www.unlimited-robotics.com/privacy-policy",
  data_protection: "https://www.unlimited-robotics.com/data-protection",

  // -- teams & testers --
  team: "/team",
  team_add_member: "/add-member",

  // -- -- -- --
  settings: "/settings",
  profile: "/profile",

  //fleet management
  assignments: "/assignments",
  assignments_tasks: "/assignments/:id",
  robots: "/robots",
  robots_id: "/robots/:id",
  robots_teleoperation_id: "/robots/teleoperation/:id",
  user_management: "/user-management",

  // robots_assignments: "/robots/:id/assignments",

  admin: "/admin",
  location: "/admin/location",
  application: "/admin/applications",
  application_details: "/admin/applications/:id",
  fleet_rules: "/admin/fleetrules",
  environment_setup: "/admin/environment-setup",
  user_administration: "/admin/user-administration",
  analytics_log: "/admin/analytics-log",
  maps: "/admin/maps",

  //drawer for mobile
  drawer_for_mobile: "/taskstatus/:assignmentId",

  //simulator
  simulator: "/simulator",

  //calendar
  calendar: "/calendar",

  //analytics
  analytics: "/analytics",

  //teleoperation
  teleoperation: "/teleoperation",
  teleoperation_id: "/teleoperation/:id",
};

export default URLS;
