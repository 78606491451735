import React from "react";
import { StyledSingleLineContainer } from "../../../../components/commonStyles";
import { TeleopDebugLogsWrapper } from "../components";

const TeleopDebugLogs = ({ currentRobot }) => {
  return (
    <TeleopDebugLogsWrapper>
      <StyledSingleLineContainer style={{ justifyContent: "center" }}>
        <p style={{ fontSize: "22px", fontWeight: "700", textAlign: "center" }}>
          {currentRobot?.teleop_task_status}
        </p>
      </StyledSingleLineContainer>
    </TeleopDebugLogsWrapper>
  );
};

export default TeleopDebugLogs;
