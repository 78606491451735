import React from "react";
import { useSelector } from "react-redux";
import {
  FleetHeader,
  GoToMap,
  MapComponentWrapper,
  WhiteWrapper,
} from "../components";
import FleetRobotsList from "./FleetRobotsList";
import MapComponent from "../../robots/MapComponent";
import MapModal from "./MapModal";
import useMaps from "../../../hooks/useMaps";
import { setMapModalData } from "../../../redux/maps";
import { useDispatch } from "react-redux";
import ModalOutline from "../../../components/modal-outline/ModalOutline";
import MapModalContent from "../../../components/maps-management/MapModalContent";
import MapSelector from "../../../components/map-selector/MapSelector";

const RobotFleet = () => {
  const [mapModal, setMapModal] = React.useState(false);
  const [clickedRobot, setClickedRobot] = React.useState();
  const dispatch = useDispatch();

  const toggleMapModal = () => setMapModal((prev) => !prev);
  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({});
  const { mapModalData } = useSelector((state) => state.maps);
  return (
    <WhiteWrapper
      style={{
        textAlign: "center",
        padding: "24px",
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        height: "60vh",
      }}
    >
      <FleetHeader>
        <MapSelector />
        <div>
          <GoToMap onClick={toggleMapModal}>Map view</GoToMap>
        </div>
      </FleetHeader>
      <MapComponentWrapper>
        <MapComponent
          mapWidth={470}
          mapHeight={300}
          pointWidth={0}
          robotWidth={19}
          showToolTip={true}
        />
      </MapComponentWrapper>
      <ModalOutline
        headerBackground={"#0686D8"}
        heading={mapModalData?.isCreated ? "Edit Map" : "Add New Map"}
        onRequestClose={() => dispatch(setMapModalData(null))}
        isOpen={mapModalData}
      >
        <MapModalContent />
      </ModalOutline>

      <FleetRobotsList
        setMapModal={setMapModal}
        setClickedRobot={setClickedRobot}
      />
      <MapModal
        isOpen={mapModal}
        setIsOpen={setMapModal}
        onlyRobotId={clickedRobot}
        handleClose={() => setClickedRobot(null)}
      />
    </WhiteWrapper>
  );
};

export default RobotFleet;
