import styled from "styled-components";
import COLORS from "../../../constants/colors";
import FONTS from "../../../constants/fonts";
import ICONS from "../../../constants/icons";

export const TableWrapper = styled.div`
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid ${COLORS.secondary.water_gray};
  padding: 4rem 5rem;
  width: 100%;
  margin-top: 25px;
`;

export const AssignmentDetailHeader = styled.div`
  width: 100%;
  /* padding: 20px 35px 0px 35px; */
`;

export const AssignmentName = styled.div`
  color: #0686d8;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
`;

export const HeaderRightSide = styled.div`
  display: flex;
`;

export const CardHeaderText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #909491;
  opacity: 0.5;
`;

export const IconWrapper = styled.div`
  border: unset;
  background: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : "44px")};
  height: ${(props) => (props.width ? `${props.width}px` : "44px")};
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${(props) => props.background};
  }
  :active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      rgba(6, 134, 216, 0.1);
  }
`;

export const StyledIcon = styled.img``;

export const AssignmentDetailsWrapperComp = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
`;

export const IconAndInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const ContentText = styled.div`
  color: #909491;
  font-weight: 400;
  font-size: 16px;
`;

export const TasksHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TaskTitle = styled.div`
  color: #030a1b;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.04em;
`;
export const TaskButton = styled.div`
  cursor: pointer;
  background: #0686d8;
  border-radius: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  padding: 8px 70px;
  :hover {
    box-shadow: 0px 4px 35px rgba(0, 150, 198, 0.5);
  }
  :active {
    box-shadow: unset;
  }
`;

export const TaskListWrapper = styled.div`
  margin-top: 35px;
`;
export const TaskRow = styled.form`
  display: flex;
  gap: 25px;
`;
export const ColumnNumber = styled.div`
  color: #0686d8;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.04em;
`;

export const RowUnderLine = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #909491;
  margin: 20px 0px;
  opacity: 20%;
`;

export const InputWrapper = styled.div`
  /* width: 100%; */
`;
export const InputTitle = styled.div`
  color: #5c605d;
  font-weight: 400;
  font-size: 16px;
  line-height: 118%;
  margin-left: 20px;
  margin-bottom: 7px;
`;

export const StyledSelect = styled.select`
  border-radius: 16px;
  width: 266px;
  height: 52px;
  padding: 0px 32px 0px 20px;
  appearance: none;
  background-image: url(${ICONS.ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 12px;
  color: #030a1b;
  font-size: 16px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4;")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff;"};
  text-overflow: ellipsis;
  text-transform: capitalize;
`;
export const StyledOption = styled.option`
  color: #030a1b;
  font-size: 16px;
`;

export const ParametersWrapper = styled.div`
  width: 100%;
  /* overflow-x: scroll; */
  display: flex;
  gap: 15px;
  flex-flow: wrap;
`;

export const StyledParInput = styled.input`
  border-radius: 16px;
  padding: 0px 20px 0px 20px;
  width: 155px;
  height: 52px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4;")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff;"};
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const IconsContainerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
`;
export const ErrorMessage = styled.p`
  font-family: "AssistantBold", ${FONTS.UbuntuBold};
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: ${COLORS.secondary.rosso};
`;

export const NumbersParametersInputsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
