import React, { useContext } from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import { ModalCloseX, ModalHeader } from "./components";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const ModalOutline = ({
  isOpen,
  heading,
  onRequestClose = () => {},
  keepOnOverlayClick,
  children,
  width,
  headerBackground,
}) => {
  const { isMobile } = useContext(ResponsiveContext);
  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "999",
    },

    content: {
      zIndex: "888",
      width: width,
      minWidth: isMobile ? "90%" : "600px",
      maxWidth: "Calc(100% - 6.4rem)",
      overflow: "unset",
      height: "fit-content",
      top: "50%",
      left: "50%",
      right: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={!keepOnOverlayClick}
    >
      <ModalHeader headerBackground={headerBackground}>
        {heading}
        <ModalCloseX onClick={onRequestClose} />
      </ModalHeader>
      {children}
    </Modal>
  );
};

export default ModalOutline;
