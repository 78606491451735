import React from "react";
import {
  BatteryAndSignalWrapper,
  BatteryContent,
  BatteryWrapper,
  DataInfo,
  GeneralInfoWrapper,
  LineBorder,
  NameAndIdWrapper,
  RobotId,
  RobotLogoWrapper,
  RobotName,
  RobotRowPhoto,
  RobotStatusWrapper,
  SettingIcon,
  SignalContent,
  SignalWrapper,
  StyledBattery,
  StyledStatus,
} from "./components";
import { ROBOTSTATUSENUM, ROBOTSTATUSENUMALIAS, ROLESENUM } from "../../enums";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";
import { useSelector } from "react-redux";
import { determineRobotStatusText } from "../../utils/robots/robotStatusText";
const RobotGeneralInfo = ({ currentRobot, handleSetting }) => {
  const { currentRole } = useSelector((state) => state.clients);

  return (
    <GeneralInfoWrapper>
      <RobotStatusWrapper>
        <RobotLogoWrapper style={{ width: "unset" }}>
          <RobotRowPhoto
            status={currentRobot?.robot_manager?.status}
            alt="Logo"
          />
          <StyledStatus
            style={{
              background: "#9094910d",
              padding: "8px 16px",
              borderRadius: "8px",
            }}
            status={currentRobot?.robot_manager?.status || "Off"}
          >
            {ROBOTSTATUSENUMALIAS[
              currentRobot?.robot_manager?.status?.toUpperCase()?.replace &&
                currentRobot?.robot_manager?.status
                  ?.toUpperCase()
                  ?.replace(/\s/g, "")
            ] || ROBOTSTATUSENUM.OFF}
          </StyledStatus>
        </RobotLogoWrapper>
        <NameAndIdWrapper>
          <RobotName>{currentRobot?.name}</RobotName>
          <RobotId>
            {determineRobotStatusText(currentRobot) || STRINGS.no_current_task}
          </RobotId>
        </NameAndIdWrapper>
      </RobotStatusWrapper>
      <LineBorder />
      <BatteryAndSignalWrapper>
        <BatteryWrapper>
          <BatteryContent>
            <div style={{ width: "120px" }}>
              {(currentRobot?.robot_manager?.status ||
                currentRobot?.robot_manager?.status ===
                  ROBOTSTATUSENUM.OFF) && (
                <React.Fragment>
                  {STRINGS.battery}
                  <DataInfo>
                    {currentRobot?.battery?.level !== "nan" &&
                      `${+(currentRobot?.battery?.level * 100).toFixed(0)}%`}
                  </DataInfo>
                </React.Fragment>
              )}
            </div>
          </BatteryContent>
          <StyledBattery
            battery={(+currentRobot?.battery?.level)?.toFixed(2)}
          ></StyledBattery>
        </BatteryWrapper>
        <SignalWrapper>
          <SignalContent>
            <div style={{ width: "120px" }}>
              {STRINGS.network_signal + " "}
              <DataInfo>
                {currentRobot?.robot_manager?.status
                  ? "Connected"
                  : "Not Connected"}
              </DataInfo>
            </div>
          </SignalContent>
        </SignalWrapper>
        {[ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN, ROLESENUM.DEVELOPER].includes(
          currentRole
        ) && <SettingIcon src={ICONS.NavSettings} onClick={handleSetting} />}
      </BatteryAndSignalWrapper>
    </GeneralInfoWrapper>
  );
};

export default RobotGeneralInfo;
