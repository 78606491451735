import React, { useCallback } from "react";
import { StyledTriggerEventsWrapper } from "./components";
import { StyledButton } from "../../../../components/button/components";
import elevatorTrigger from "../../../../APIs/teleoperation/elevatorTrigger";
import { useDispatch } from "react-redux";
import { setToastMessages } from "../../../../redux/toast";

const TriggerEvents = ({ currentRobot, setShowTriggerEvents }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const triggerEvent = useCallback(
    async (trigger_id) => {
      setLoading(true);
      try {
        await elevatorTrigger({
          trigger_id,
          robotId: currentRobot?.robotId,
          clientName: currentRobot?.clientName,
        });
        dispatch(
          setToastMessages({
            text: "Trigger event sent",
            type: "success",
          })
        );
        setShowTriggerEvents(false);
      } catch (error) {
        dispatch(
          setToastMessages({
            text: error?.response?.data?.message,
            type: "error",
          })
        );
        console.error(error);
      }
      setLoading(false);
    },
    [currentRobot, dispatch, setShowTriggerEvents]
  );

  return (
    <StyledTriggerEventsWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StyledButton
          borderColor={"#fff"}
          fontSize={"16px"}
          color={"#fff"}
          fontFamily={"unset"}
          backgroundColor={"#0096C6"}
          padding={"8px 20px"}
          margin={"20px 0"}
          width={"144px"}
          type="submit"
          disabled={loading}
          onClick={() => triggerEvent(2)}
        >
          Elevator 2
        </StyledButton>
        <StyledButton
          borderColor={"#fff"}
          fontSize={"16px"}
          color={"#fff"}
          fontFamily={"unset"}
          backgroundColor={"#0096C6"}
          padding={"8px 20px"}
          margin={"20px 0"}
          width={"144px"}
          type="submit"
          disabled={loading}
          onClick={() => triggerEvent(3)}
        >
          Elevator 3
        </StyledButton>
        <StyledButton
          borderColor={"#fff"}
          fontSize={"16px"}
          color={"#fff"}
          fontFamily={"unset"}
          backgroundColor={"#0096C6"}
          padding={"8px 20px"}
          margin={"20px 0"}
          width={"144px"}
          type="submit"
          disabled={loading}
          onClick={() => triggerEvent(4)}
        >
          Elevator 4
        </StyledButton>
      </div>
    </StyledTriggerEventsWrapper>
  );
};

export default TriggerEvents;
