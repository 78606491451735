import React, { useContext, useEffect } from "react";
import COLORS from "../../constants/colors";
import STRINGS from "../../constants/strings";
import { InputWrapper } from "../input/components";
import InputText from "../input/InputText";
import ModalOutline from "../modal-outline/ModalOutline";
import {
  FileInputInput,
  FileInputLabel,
  ModalContentForm,
  UploadLogoButton,
} from "./components";
import ImageUploader from "../upload-logo/ImageUploader";
import { ModalFooter } from "../modal-outline/components";
import CustomButton from "../button/CustomButton";
import { AdminTableContext } from "../../contexts/AdminTableContext";
import addNewClient from "../../APIs/clients/addNewClient";
import updateClient from "../../APIs/clients/updateClient";
import { useState } from "react";
import { emailRegex } from "../../utils/emailRegex";
import Loader from "../loader/Loader";
import { setToastMessages } from "../../redux/toast";
import { useDispatch } from "react-redux";

const AddClientModal = ({
  isOpen,
  setIsOpen,
  currentModalData,
  setNewClientForm,
  newClientForm,
}) => {
  const { clients, setClients } = useContext(AdminTableContext);
  const [logo, setLogo] = useState(null);
  const [errorValidations, setErrorValidations] = useState({
    name: "",
    logo: "",
    projectManagerName: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    Object.keys(currentModalData).length && setNewClientForm(currentModalData);
  }, [currentModalData, setNewClientForm]);

  const handleChange = (inputValue, fieldName) => {
    let obj = { ...newClientForm };
    obj[fieldName] = inputValue;
    setNewClientForm(obj);
    setErrorValidations((prev) => ({ ...prev, [fieldName]: "" }));
  };

  const handPhotoUpload = (event) => {
    if (event.target?.files[0]) {
      setLogo(event.target?.files[0]);
      const src = URL.createObjectURL(event.target.files[0]);
      setNewClientForm({ ...newClientForm, logo: src });
      setErrorValidations((prev) => ({ ...prev, logo: "" }));
    }
  };

  const handleAddClient = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", logo);
    formData.append("name", newClientForm.name);
    formData.append("projectManagerName", newClientForm.projectManagerName);
    formData.append("email", newClientForm.email);
    try {
      const newClient = await addNewClient(formData);
      setClients((prev) => [...prev, newClient]);
      setIsOpen(false);
      setNewClientForm({});
      setLoading(false);
    } catch (error) {
      dispatch(
        setToastMessages({ text: error.response.data.message, type: "error" })
      );
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", logo);
      formData.append("name", newClientForm.name);
      formData.append("projectManagerName", newClientForm.projectManagerName);
      formData.append("email", newClientForm.email);
      formData.append("id", newClientForm.id);
      await updateClient(newClientForm.id, formData);
      const newClients = clients.map((item) => {
        if (item.id === newClientForm.id) {
          return newClientForm;
        } else return item;
      });
      setClients(newClients);
      setIsOpen(false);
    } catch (error) {
      dispatch(
        setToastMessages({ text: error.response.data.message, type: "error" })
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSubmit = (data) => {
    Object.keys(newClientForm).forEach((key) => {
      if (!newClientForm[key]) {
        setErrorValidations((prev) => ({
          ...prev,
          [key]: "This field can not be empty",
        }));
      }
    });

    const existingClient = clients.find(
      (item) => item.name === newClientForm.name && item.id !== data.id
    );
    if (existingClient) {
      setErrorValidations((prev) => ({
        ...prev,
        name: "Client name already exists",
      }));
    } else if (newClientForm.email && !emailRegex(newClientForm.email)) {
      setErrorValidations((prev) => ({
        ...prev,
        email: "Incorrect email format",
      }));
    } else if (
      newClientForm.name &&
      newClientForm.projectManagerName &&
      newClientForm.email
    ) {
      Object.keys(data).length ? handleUpdate() : handleAddClient();
    }
  };

  return (
    <ModalOutline
      isOpen={isOpen}
      heading={
        Object.keys(currentModalData).length
          ? STRINGS.edit_client
          : STRINGS.create_new_client
      }
      onRequestClose={() => {
        setIsOpen(false);
        setErrorValidations({ name: "", logo: "", email: "", address: "" });
      }}
      buttonText={STRINGS.create}
      newClientForm={newClientForm}
      //onSubmit={submitForm}
    >
      <ModalContentForm>
        <InputWrapper>
          <InputText
            width="42.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={STRINGS.client_name}
            handleChange={(e) => handleChange(e, "name")}
            name={STRINGS.client_name}
            value={newClientForm.name || ""}
            error={errorValidations.name}
            backgroundColor="#fff"
          />
        </InputWrapper>
        <InputWrapper>
          <div>
            <FileInputInput
              type="file"
              id="upload-logo"
              onChange={handPhotoUpload}
            />
            {!newClientForm.logo ? (
              <FileInputLabel htmlFor="upload-logo">
                <UploadLogoButton>{STRINGS.upload_logo}</UploadLogoButton>
              </FileInputLabel>
            ) : (
              <ImageUploader
                logo={newClientForm.logo}
                handPhotoUpload={handPhotoUpload}
              />
            )}
          </div>
        </InputWrapper>
        <InputWrapper>
          <InputText
            width="42.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={`Name - ${STRINGS.project_manager_as_admin}`}
            handleChange={(e) => handleChange(e, "projectManagerName")}
            name={`Name - ${STRINGS.project_manager_as_admin}`}
            value={newClientForm.projectManagerName || ""}
            error={errorValidations.projectManagerName}
            backgroundColor="#fff"
          />
        </InputWrapper>

        <InputWrapper>
          <InputText
            width="42.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={`Email - ${STRINGS.project_manager_as_admin}`}
            handleChange={(e) => handleChange(e, "email")}
            name={`Email - ${STRINGS.project_manager_as_admin}`}
            value={newClientForm.email || ""}
            error={errorValidations.email}
            backgroundColor="#fff"
          />
        </InputWrapper>
      </ModalContentForm>
      <ModalFooter>
        {loading ? (
          <Loader height={50} noText noMargin padding={0} />
        ) : (
          <CustomButton
            backgroundColor={COLORS.secondary.ncs}
            type="submit"
            capitalize
            // onClick={onSubmit}
            onClick={() => handleSubmit(currentModalData)}
          >
            {Object.keys(currentModalData).length
              ? STRINGS.update_client
              : STRINGS.create}
          </CustomButton>
        )}
      </ModalFooter>
    </ModalOutline>
  );
};

export default AddClientModal;
