import React, { useContext, useEffect, useState } from "react";
import AnalyticsWidget from "./AnalyticsWidget";
import { useDispatch } from "react-redux";
import { setAnalyticsData, setEventNames } from "../../../redux/analytics";
import getAllAnalytics from "../../../APIs/analytics/getAllAnalytics";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import getEventNames from "../../../APIs/analytics/getEventNames";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import updateGrid from "../../../APIs/analytics/updateGrid";
import { ROLESENUM } from "../../../enums";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";

const ResponsiveGridLayout = WidthProvider(Responsive);
const AnalyticsTable = () => {
  const location = useLocation();
  const { isMobile } = useContext(ResponsiveContext);

  const dispatch = useDispatch();
  const { analyticsData, triggerAnalyticsRefetch } = useSelector(
    (state) => state.analytics
  );

  const [layout, setLayout] = useState([]);

  const { chosenClient, currentRole } = useSelector((state) => state.clients);

  useEffect(() => {
    if (chosenClient && location.search) {
      const params = new URLSearchParams(location.search);
      getAllAnalytics(chosenClient, params).then((data) => {
        dispatch(setAnalyticsData(data));
      });
    }
  }, [dispatch, chosenClient, triggerAnalyticsRefetch, location.search]);

  useEffect(() => {
    if (chosenClient)
      getEventNames(chosenClient).then((data) => {
        dispatch(setEventNames(data));
      });
  }, [dispatch, chosenClient]);

  useEffect(() => {
    const savedLayout = analyticsData.map((item, index) => {
      let grid = item.gridData
        ? { id: item.id.toString(), ...item.gridData }
        : {
            id: item.id.toString(),
            x: (index % 4) * 3, // Initial x-position
            y: Math.floor(index / 4) * 3, // Initial y-position
            w: 3, // Initial width (grid units)
            h: 3, // Initial height (grid units)}
          };
      return grid;
    });
    setLayout(savedLayout);
  }, [analyticsData]);

  const onLayoutChange = async (newLayout) => {
    const modified = newLayout.map((el) => ({ ...el, id: el.i }));
    // Save layout changes (size, position) to local state or API
    await updateGrid(modified);
    setLayout(modified);
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={{ lg: layout }}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={100}
      // onLayoutChange={onLayoutChange}
      onDragStop={onLayoutChange}
      onResizeStop={onLayoutChange}
      draggableCancel=".cancelSelectorName"
      isResizable={
        [ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN].includes(currentRole) &&
        !isMobile
      }
      isDraggable={
        [ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN].includes(currentRole) &&
        !isMobile
      }
    >
      {analyticsData?.map((el) => (
        <div
          style={{ marginTop: "20px", cursor: "pointer" }}
          key={el.id}
          id={el.id}
          data-grid={layout?.find((l) => l.id === el.id.toString())}
        >
          <AnalyticsWidget layout={layout} item={el} setLayout={setLayout} />
        </div>
      ))}
    </ResponsiveGridLayout>
  );
};

export default AnalyticsTable;
