import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import { BatteryModalWrapper } from "./components";
import { ModalCloseX } from "../immediate-task-modal/components";
import ICONS from "../../constants/icons";
import { useSelector } from "react-redux";
import { deleteFirstRobotFromQueue } from "../../redux/robots";
import { useDispatch } from "react-redux";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const LowBatteryModal = () => {
  const { isMobile } = useContext(ResponsiveContext);
  const { lowBatteryRobotsQueue, robotsData } = useSelector(
    (state) => state.robots
  );

  const [currentRobot, setCurrentRobot] = useState();
  const dispatch = useDispatch();
  const ROBOT_NAME = robotsData.find((el) => el.robotId === currentRobot)?.name;

  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "999",
    },

    content: {
      zIndex: "999",
      width: isMobile ? "80%" : "649px",
      height: "320px",
      overflow: "unset",
      top: "50%",
      left: "50%",
      right: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      setCurrentRobot(lowBatteryRobotsQueue[0]);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [lowBatteryRobotsQueue]);

  const onRequestClose = useCallback(() => {
    let seenLowBatteryRobots =
      JSON.parse(sessionStorage.getItem("seen_low_battery_robots")) || [];
    seenLowBatteryRobots = [...seenLowBatteryRobots, currentRobot];

    sessionStorage.setItem(
      "seen_low_battery_robots",
      JSON.stringify(seenLowBatteryRobots)
    );

    dispatch(deleteFirstRobotFromQueue());
    setCurrentRobot(null);
  }, [dispatch, currentRobot]);

  useEffect(() => {
    let timeout;
    if (currentRobot) {
      timeout = setTimeout(() => {
        onRequestClose();
      }, 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [currentRobot, onRequestClose]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={!!currentRobot && ROBOT_NAME}
      style={customStyles}
      ariaHideApp={false}
    >
      <BatteryModalWrapper isMobile={isMobile}>
        <ModalCloseX onClick={onRequestClose} />
        <img src={ICONS.Twenty} alt="" />
        <h1>Low Battery Alarm</h1>
        <p>Attention: {ROBOT_NAME} Is Currently Experiencing Low Battery. </p>
        <button onClick={onRequestClose}>OK</button>
      </BatteryModalWrapper>
    </Modal>
  );
};

export default LowBatteryModal;
