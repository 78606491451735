import axios from "axios";
import momentTimezone from "moment-timezone";

const getAllAnalytics = async (clientId, params) => {
  const res = await axios.get(
    `/analytics/${clientId}?${params.toString()}&timezone=${encodeURIComponent(
      momentTimezone.tz.guess()
    )}`
  );
  return res.data;
};

export default getAllAnalytics;
