import React from "react";
import URLS from "../../constants/urls";
import useRouting from "../../hooks/useRouting";
import TeleoperationPage from "./TeleoperationPage";
import RobotTeleopDetails from "../robots/teleoperation/RobotTeleopDetails";
import { ROLESENUM } from "../../enums";
import { TransitiveProvider } from "../../contexts/TransitiveContext";

const TeleoperationRoutes = () => {
  const { PrivateRoute } = useRouting();
  return (
    <TransitiveProvider>
      <PrivateRoute
        exact
        allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.TELEOPERATOR]}
        path={URLS.teleoperation}
        component={TeleoperationPage}
      />
      <PrivateRoute
        exact
        path={URLS.teleoperation_id}
        component={RobotTeleopDetails}
      />
    </TransitiveProvider>
  );
};

export default TeleoperationRoutes;
