import React, { useContext, useEffect, useState } from "react";
import {
  ComponentHeader,
  ComponentTitle,
} from "../environment-setup/components";
import STRINGS from "../../../constants/strings";
import {
  AnalyticsDatesRange,
  AnalyticsHeader,
  AnalyticsHeaderBtn,
  AnalytisHeaderContainer,
} from "./components";
import AnalyticTypeSelector from "./AnalyticTypeSelector";
import OutsideClickHandler from "react-outside-click-handler";
import AnalyticsTable from "./AnalyticsTable";
import { HeaderStyledButton } from "../../robots/assignments/components";
import CalendarWithRange from "../../../components/calendar-with-range/CalendarWithRange";
import AnalyticsConfigModal from "./AnalyticsConfigModal";
import { useDispatch } from "react-redux";
import { setWidgetModalData } from "../../../redux/analytics";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import { StyledUnderLine } from "../../map/components";
import ICONS from "../../../constants/icons";
import { ROLESENUM } from "../../../enums";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import HeadeWithArrows from "../../../components/header-with-arrows/HeadeWithArrows";
import DownloadSVG from "../../../assets/DownloadSVG";
import LoadingSVG from "../../../assets/LoadingSVG";
import getAnalyticsLogs from "../../../APIs/analytics/getAnalyticsLogs";

const AnalyticsAdmin = () => {
  const periodTypes = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last Month",
    "Custom",
  ];
  const [chosenPeriod, setChosenPeriod] = useState("Today");
  const [showTypeSelector, setShowTypeSelector] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const { widgetModalData } = useSelector((state) => state.analytics);
  const { currentRole, chosenClient } = useSelector((state) => state.clients);
  const { isMobile } = useContext(ResponsiveContext);
  const { search } = useLocation();

  const location = useLocation();
  const [donwalodLoading, setDownloadLoading] = useState(false);

  const dateParams = Object.fromEntries(new URLSearchParams(search));

  const history = useHistory();

  const dispatch = useDispatch();
  const handlePeriodType = (el) => {
    if (el === periodTypes[4]) {
      setChosenPeriod(el);
      setShowCalendar(true);
      return;
    }

    let params = new URLSearchParams();
    if (el === periodTypes[0]) {
      params.append("from", moment().format("YYYY-MM-DD").toString());
      params.append("to", moment().format("YYYY-MM-DD").toString());
    }
    if (el === periodTypes[1]) {
      params.append("from", moment().subtract(1, "day").format("YYYY-MM-DD"));
      params.append("to", moment().subtract(1, "day").format("YYYY-MM-DD"));
    }
    if (el === periodTypes[2]) {
      params.append(
        "from",
        moment().subtract(7, "days").format("YYYY-MM-DD").toString()
      );
      params.append("to", moment().format("YYYY-MM-DD").toString());
    }
    if (el === periodTypes[3]) {
      params.append(
        "from",
        moment().subtract(30, "days").format("YYYY-MM-DD").toString()
      );
      params.append("to", moment(new Date()).format("YYYY-MM-DD").toString());
    }
    history.push({
      pathname: "/analytics",
      search: params.toString(),
    });
    sessionStorage.setItem("analyticsFrom", params.get("from"));
    sessionStorage.setItem("analyticsTo", params.get("to"));

    setChosenPeriod(el);
  };

  const handleCalendarApply = (values) => {
    setShowCalendar(false);
    let params = new URLSearchParams();
    params.append("from", moment(values[0]).format("YYYY-MM-DD").toString());
    params.append("to", moment(values[1]).format("YYYY-MM-DD").toString());
    history.push({
      pathname: "/analytics",
      search: params.toString(),
    });
    sessionStorage.setItem("analyticsFrom", params.get("from"));
    sessionStorage.setItem("analyticsTo", params.get("to"));
  };

  useEffect(() => {
    const storedFrom = sessionStorage.getItem("analyticsFrom");
    const storedTo = sessionStorage.getItem("analyticsTo");

    const params = new URLSearchParams();
    if (storedFrom && storedTo) {
      params.append("from", storedFrom);
      params.append("to", storedTo);

      // Determine chosenPeriod based on stored dates
      const fromDate = moment(storedFrom);
      const toDate = moment(storedTo);
      const today = moment().startOf("day");

      if (fromDate.isSame(today, "day") && toDate.isSame(today, "day")) {
        setChosenPeriod("Today");
      } else if (fromDate.isSame(today.clone().subtract(1, "day"), "day")) {
        setChosenPeriod("Yesterday");
      } else if (
        fromDate.isSame(today.clone().subtract(30, "days"), "day") &&
        toDate.isSame(today, "day")
      ) {
        setChosenPeriod("Last Month");
      } else if (
        fromDate.isSame(today.clone().subtract(7, "days"), "day") &&
        toDate.isSame(today, "day")
      ) {
        setChosenPeriod("Last 7 days");
      } else {
        setChosenPeriod("Custom");
      }

      history.push({
        pathname: "/analytics",
        search: params.toString(),
      });
    } else {
      params.append("from", moment().format("YYYY-MM-DD").toString());
      params.append("to", moment().format("YYYY-MM-DD").toString());

      history.push({
        pathname: "/analytics",
        search: params.toString(),
      });
    }
    sessionStorage.setItem("analyticsFrom", params.get("from"));
    sessionStorage.setItem("analyticsTo", params.get("to"));
  }, [history]); // Run only on component mount

  const handleDownload = async () => {
    setDownloadLoading(true);

    try {
      const params = new URLSearchParams(location.search);
      const response = await getAnalyticsLogs(chosenClient, params);

      // Transform the data to the desired format
      const data = response
        .map((item) =>
          item.event === "unit_delivery_completed"
            ? {
                date: moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                event: item.event,
                parameters: item.parameters,
                robot: item.robot.robotId,
                application: item.application.aliasName,
              }
            : null
        )
        .filter((item) => item);

      // Create CSV header with capital letters
      const headers = ["Date", "Event", "Parameters", "Robot", "Application"];
      const fields = ["date", "event", "parameters", "robot", "application"];

      // Convert data to CSV format
      const csvContent = [
        headers.join(","), // Header row
        ...data.map((row) =>
          fields
            .map((field) => {
              let cellValue = row[field];
              if (field === "parameters") {
                cellValue = JSON.stringify(cellValue);
              }
              // Escape commas and quotes in cell values
              return `"${String(cellValue).replace(/"/g, '""')}"`;
            })
            .join(",")
        ),
      ].join("\n");

      // Create and download the CSV file
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "analytics.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading analytics:", error);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.analytics}`}</ComponentTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            position: "relative",
          }}
        >
          {donwalodLoading ? (
            <LoadingSVG
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          ) : (
            <DownloadSVG
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
              }}
              fill="#000"
              onClick={handleDownload}
            />
          )}
          {[
            ROLESENUM.ADMIN,
            ROLESENUM.CLIENTADMIN,
            ROLESENUM.TELEOPERATOR,
          ].includes(currentRole) &&
            !isMobile && (
              <div style={{ position: "relative" }}>
                <HeaderStyledButton onClick={() => setShowTypeSelector(true)}>
                  {STRINGS.add_content}
                </HeaderStyledButton>
                <OutsideClickHandler
                  onOutsideClick={() => setShowTypeSelector(false)}
                >
                  {showTypeSelector && (
                    <AnalyticTypeSelector
                      onChoose={() => setShowTypeSelector(false)}
                      style={{
                        position: "absolute",
                        left: "-5px",
                        top: "50px",
                      }}
                    />
                  )}
                </OutsideClickHandler>
              </div>
            )}
        </div>
      </ComponentHeader>
      <AnalyticsHeader>
        {isMobile ? (
          <HeadeWithArrows
            options={periodTypes}
            handleOptionClick={(value) => handlePeriodType(value)}
          />
        ) : (
          <AnalytisHeaderContainer>
            {periodTypes.map((el, index) => (
              <React.Fragment key={el}>
                {index !== 0 && (
                  <StyledUnderLine style={{ marginTop: "12px" }} />
                )}
                <AnalyticsHeaderBtn
                  key={el}
                  chosen={chosenPeriod === el}
                  onClick={() => handlePeriodType(el)}
                >
                  {el} {el === "Custom" && <img src={ICONS.GreyArrow} alt="" />}
                </AnalyticsHeaderBtn>
              </React.Fragment>
            ))}
          </AnalytisHeaderContainer>
        )}

        <div style={{ position: "relative" }}>
          <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
            <div
              style={{
                position: "absolute",
                top: "55px",
                right: isMobile ? "0" : "-100px",
                zIndex: "111",
              }}
            >
              {showCalendar && (
                <CalendarWithRange
                  value={dateRange}
                  setValue={setDateRange}
                  handleApply={(values) => {
                    handleCalendarApply(values);
                  }}
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </AnalyticsHeader>
      {isMobile && (
        <AnalyticsDatesRange>
          From {moment(dateParams.from).format("DD MMM")} - Until{" "}
          {moment(dateParams.to).format("DD MMM")}
        </AnalyticsDatesRange>
      )}

      <AnalyticsTable />

      {widgetModalData && (
        <AnalyticsConfigModal
          onRequestClose={() => dispatch(setWidgetModalData(null))}
        />
      )}
    </React.Fragment>
  );
};

export default AnalyticsAdmin;
