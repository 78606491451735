import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";

export function frontToBackPipeline(data) {
  const weekDaysMap = new Map();

  // Process each entry in the data
  data.forEach((entry) => {
    // Extract the chosen week days
    entry.week_days.forEach((day) => {
      if (day.chosen) {
        if (!weekDaysMap.has(day.index)) {
          weekDaysMap.set(day.index, []);
        }

        // Append time periods for the chosen day
        weekDaysMap.get(day.index).push({
          from: moment(entry.time.from, "HH:mm"),
          to: moment(entry.time.to, "HH:mm"),
        });
      }
    });
  });

  // Create the final structure
  const predefinedTimes = {
    week_days: Array.from(weekDaysMap.keys()), // Get all distinct chosen week days
    time_preferences: Array.from(weekDaysMap, ([week_day, time_periods]) => ({
      week_day,
      time_periods,
    })),
  };

  return predefinedTimes;
}

export function backToFrontPipeline(predefinedTimes) {
  const weekDaysMapping = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // First, collect all unique time periods with their associated days
  const timeEntries = [];

  predefinedTimes.time_preferences.forEach((preference) => {
    preference.time_periods.forEach((period) => {
      timeEntries.push({
        weekDay: preference.week_day,
        from: moment(period.from).format("HH:mm"),
        to: moment(period.to).format("HH:mm"),
      });
    });
  });

  // Group by unique time combinations
  const originalData = timeEntries.reduce((acc, entry) => {
    const existingEntry = acc.find(
      (item) => item.time.from === entry.from && item.time.to === entry.to
    );

    if (existingEntry) {
      existingEntry.week_days = existingEntry.week_days.map((day) => ({
        ...day,
        chosen: day.chosen || day.index === entry.weekDay,
      }));
    } else {
      acc.push({
        id: uuidv4(),
        week_days: weekDaysMapping.map((day, index) => ({
          index: index,
          day: day,
          chosen: index === entry.weekDay,
        })),
        time: {
          from: entry.from,
          to: entry.to,
        },
      });
    }

    return acc;
  }, []);

  return originalData;
}
