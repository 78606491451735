import React, { useState } from "react";
import {
  StyledLineConfigHeader,
  StyledLineConfigWrapper,
  StyledVideoType,
} from "./components";
import Slider from "../../../../components/slider/Slider";
import ReactDOM from "react-dom";
import LineConfigRow from "./LineConfigRow";
import ICONS from "../../../../constants/icons";
import { IconWrapper } from "../../tasks-in-assignments/components";
import { StyledButton } from "../../../../components/button/components";
import updateRobot from "../../../../APIs/robots/updateRobot";
import { useSelector } from "react-redux";
import { CloseIconWrapper } from "../../../robot-actions/components";

const LinesConfiguration = ({
  video0LinesRef,
  video1LinesRef,
  video0CurvesRef,
  video1CurvesRef,
  lineWidth,
  setLineWidth,
  handleColorOpacityChange,
  robot_uid,
  robotId,
  onSave = () => {},
}) => {
  const [toggle, setToggle] = useState(false);
  const { chosenClient } = useSelector((state) => state.clients);

  const handleSave = async () => {
    let obj = {
      teleopConfiguration: {
        linesWidth: +lineWidth,
        frontVideoLines: video0LinesRef.current,
        rearVideoLines: video1LinesRef.current,
        frontVideoCurves: video0CurvesRef.current,
        rearVideoCurves: video1CurvesRef.current,
      },
      robotId,
      clientId: chosenClient,
      id: robot_uid,
    };
    await updateRobot(obj);
    onSave();
  };
  return ReactDOM.createPortal(
    <StyledLineConfigWrapper>
      <StyledLineConfigHeader>
        <p>Edit Lines</p>
        <div style={{ display: "flex", gap: "20px", alignContent: "center" }}>
          <div className="line-width-div">
            <p>Line Width:</p>
            <Slider
              value={lineWidth}
              setValue={setLineWidth}
              min={0}
              max={10}
            />
          </div>

          <div>
            <IconWrapper onClick={() => setToggle(!toggle)}>
              <img src={ICONS.SwitchIcon} alt="" />
            </IconWrapper>
          </div>
        </div>

        <CloseIconWrapper onClick={onSave}>
          <img src={ICONS.BlackCross} alt="X" />
        </CloseIconWrapper>
      </StyledLineConfigHeader>
      {!toggle && (
        <>
          <StyledVideoType>Front Camera</StyledVideoType>
          {video0LinesRef.current.map((el, index) => (
            <LineConfigRow
              video="front"
              index={index}
              handleColorOpacityChange={handleColorOpacityChange}
              el={el}
              key={el.id}
            />
          ))}

          {video0CurvesRef.current.map((el, index) => (
            <LineConfigRow
              video="front"
              index={index}
              handleColorOpacityChange={handleColorOpacityChange}
              el={el}
              key={el.id}
            />
          ))}
        </>
      )}
      {toggle && (
        <>
          <StyledVideoType>Back Camera</StyledVideoType>
          {video1LinesRef.current.map((el, index) => (
            <LineConfigRow
              video="rear"
              index={index}
              handleColorOpacityChange={handleColorOpacityChange}
              el={el}
              key={el.id}
            />
          ))}
          {video1CurvesRef.current.map((el, index) => (
            <LineConfigRow
              video="rear"
              index={index}
              handleColorOpacityChange={handleColorOpacityChange}
              el={el}
              key={el.id}
            />
          ))}
        </>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StyledButton
          borderColor={"#fff"}
          fontSize={"16px"}
          color={"#fff"}
          fontFamily={"unset"}
          backgroundColor={"#0096C6"}
          padding={"8px 20px"}
          margin={"20px 0"}
          width={"144px"}
          type="submit"
          onClick={() => handleSave()}
        >
          Save
        </StyledButton>
      </div>
    </StyledLineConfigWrapper>,
    document.getElementById("editLines")
  );
};

export default LinesConfiguration;
