import React, { useContext, useEffect, useState } from "react";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";

import {
  HeaderRightSide,
  MapWrapperTitle,
  StyledHeaderBtn,
  SwitchIcon,
  SwitchToMapWrapper,
} from "./components";
import MapComponent from "./MapComponent";
import RobotsList from "./RobotsList";
import AddRobotModal from "../../components/add-robot-modal/AddRobotModal";
import {
  ComponentHeader,
  ComponentTitle,
} from "../admin-page/environment-setup/components";
import { useSelector } from "react-redux";
import { ROLESENUM } from "../../enums";
import SwitchIcons from "./SwitchIcons";
import useMaps from "../../hooks/useMaps";
import { setMapModalData } from "../../redux/maps";
import { useDispatch } from "react-redux";
import MapModalContent from "../../components/maps-management/MapModalContent";
import ModalOutline from "../../components/modal-outline/ModalOutline";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import BatteryConfigurationModal from "./battery-configuration/BatteryConfigurationModal";
import { setBatterfyConfigurationData } from "../../redux/robots";
import ConfigurationResponse from "./battery-configuration/ConfigurationResponse";
import MapSelector from "../../components/map-selector/MapSelector";

const Robots = () => {
  const [responseModalType, setResponseModalType] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [editRobot, setEditRobot] = useState(null);
  const { isMobile } = useContext(ResponsiveContext);
  const [isMapLoading, setIsMapLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({});
  const { isCompanyMember, currentRole } = useSelector(
    (state) => state.clients
  );
  const { mapModalData } = useSelector((state) => state.maps);
  const { batterfyConfigurationData } = useSelector((state) => state.robots);
  const dispatch = useDispatch();

  useEffect(() => {
    const orientationChangeFunc = () => {
      setIsMapLoading(true);
    };

    window.addEventListener("orientationchange", orientationChangeFunc);

    return () => {
      window.removeEventListener("orientationchange", orientationChangeFunc);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      isMapLoading && setIsMapLoading(false);
    }, 2000);
  }, [isMapLoading]);

  return (
    <React.Fragment>
      <ComponentHeader>
        <ComponentTitle isMobile={isMobile}>
          {toggle && `${STRINGS.robots_list}`}
        </ComponentTitle>
        {!isMobile && (
          <HeaderRightSide style={{ width: toggle && "unset" }}>
            {toggle &&
              [
                ROLESENUM.ADMIN,
                ROLESENUM.ADMIN,
                ROLESENUM.DEVELOPER,
                ROLESENUM.TELEOPERATOR,
              ].includes(currentRole) && (
                <StyledHeaderBtn
                  onClick={() => setIsOpen(true)}
                >{`${STRINGS.add_robot}`}</StyledHeaderBtn>
              )}
            <AddRobotModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              editRobot={editRobot}
              setEditRobot={setEditRobot}
            />
            <SwitchToMapWrapper style={{ background: toggle && "unset" }}>
              {!toggle && <MapSelector />}
              <MapWrapperTitle>
                {toggle
                  ? `${STRINGS.switch_to_map}`
                  : `${STRINGS.switch_to_list}`}
              </MapWrapperTitle>
              <SwitchIcon
                src={toggle ? ICONS.ToggleOff : ICONS.ToggleOn}
                onClick={() => setToggle((prev) => !prev)}
              />
            </SwitchToMapWrapper>
          </HeaderRightSide>
        )}
        {isMobile && <SwitchIcons toggle={toggle} setToggle={setToggle} />}
      </ComponentHeader>

      {toggle ? (
        <RobotsList
          editRobot={editRobot}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setEditRobot={setEditRobot}
          isCompanyMember={isCompanyMember}
        />
      ) : isMapLoading ? (
        `Loading...`
      ) : (
        <>
          <MapComponent
            showDeleteMap={
              [ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN].includes(currentRole)
                ? true
                : false
            }
            pointWidth={0}
            robotWidth={20}
            mapWidth={isMobile && window.innerWidth}
            mapHeight={isMobile && "500"}
          />
        </>
      )}
      <ModalOutline
        headerBackground={"#0686D8"}
        heading={mapModalData?.isCreated ? "Edit Map" : "Add New Map"}
        onRequestClose={() => dispatch(setMapModalData(null))}
        isOpen={mapModalData}
      >
        <MapModalContent />
      </ModalOutline>
      <ModalOutline
        headerBackground={"#0686D8"}
        width={"50%"}
        heading={`${batterfyConfigurationData?.name} Charging Preferences`}
        onRequestClose={() => dispatch(setBatterfyConfigurationData(null))}
        isOpen={!!batterfyConfigurationData}
      >
        <BatteryConfigurationModal
          setResponseModalType={setResponseModalType}
        />
      </ModalOutline>
      <ConfigurationResponse
        responseModalType={responseModalType}
        onRequestClose={() => setResponseModalType(false)}
      />
    </React.Fragment>
  );
};

export default Robots;
