import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Transitive from "../pages/robots/transitive/Transitive";
import socket, { joinSocketRoom } from "../APIs/socket/socket";
import { useSelector } from "react-redux";
import { ROLESENUM } from "../enums";
import { getRobotsByClients } from "../APIs/robots/getRobotsByClients";

const TransitiveContext = createContext();

export const TransitiveProvider = ({ children }) => {
  const { currentRole, chosenClient } = useSelector((state) => state.clients);
  const [teleopRobots, setTeleopRobots] = useState([]);
  const [robotsList, setRobotsList] = useState([]);
  const [clientsState, setClientsState] = useState([]);
  const isEditModeRef = useRef(false);

  const Transitives = teleopRobots.map((robot) => (
    <Transitive key={robot.id} robot={robot} isEditModeRef={isEditModeRef} />
  ));

  useEffect(() => {
    const getRobots = async () => {
      const selectedClientIds = clientsState
        .filter((c) => c.checked && c.id !== "0")
        .map((c) => c.id);
      const response = await getRobotsByClients(
        selectedClientIds.length > 0 ? selectedClientIds : [chosenClient]
      );
      setTeleopRobots(response);
      setRobotsList(response);
    };

    chosenClient && getRobots();
  }, [clientsState, chosenClient]);

  useEffect(() => {
    if (![ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole))
      return;
    // Get all checked clients
    const chosenClients = clientsState.filter((client) => client.checked);

    // Join all checked client rooms
    chosenClients.forEach((client) => {
      joinSocketRoom(client.name);
    });

    socket.on("robots_data", (data) => {
      const socketData = Object.values(data).filter((el) => el.robotId);
      setTeleopRobots((prev) => {
        const updatedRobots = [...prev];
        socketData.forEach((robotData) => {
          // if (!robotData?.robotId) return;
          const existingIndex = updatedRobots.findIndex(
            (robot) =>
              robot.robotId === robotData.robotId &&
              (robot.client?.name === robotData.clientName ||
                robot.clientName === robotData.clientName)
          );
          if (existingIndex >= 0) {
            updatedRobots[existingIndex] = {
              ...updatedRobots[existingIndex],
              ...robotData,
            };
          } else {
            updatedRobots.push(robotData);
          }
        });
        return updatedRobots;
      });
    });

    return () => {
      // Leave all checked client rooms
      chosenClients.forEach((client) => {
        socket.emit("leaveRoom", client.name);
      });
    };
  }, [clientsState, currentRole]);

  return (
    <TransitiveContext.Provider
      value={{
        clientsState,
        robotsList,
        setClientsState,
        teleopRobots,
        Transitives,
        isEditModeRef,
      }}
    >
      {children}
    </TransitiveContext.Provider>
  );
};

export const useTransitive = () => useContext(TransitiveContext);
