import React, { useState } from "react";
import ModalOutline from "../modal-outline/ModalOutline";
import InputText from "../input/InputText";
import COLORS from "../../constants/colors";
import { ModalForm } from "../agenda-assignment-details-modal/components";
import {
  StyledButtonWrapper,
  StyledOption,
  StyledSelect,
  StyledSubmitButton,
} from "./components";
import { useEffect } from "react";

const InputModal = ({
  isOpen,
  title,
  onRequestClose,
  handleSubmit,
  robots,
  chosenRobot: defaultRobot,
  defaultState,
  angle,
}) => {
  const [inputText, setInputText] = useState(defaultState?.name);
  const [angleInput, setAngleInput] = useState(defaultState?.angle || 1);
  const [error, setError] = useState("");

  const [chosenRobot, setChosenRobot] = useState(
    defaultRobot?.id ? defaultRobot.id : robots ? robots[0].id : ""
  );

  const onSubmit = async () => {
    if (!inputText) setError("Name should not be empty");
    else {
      await handleSubmit(inputText, chosenRobot, angleInput);
      onRequestClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (defaultRobot?.id) setChosenRobot(defaultRobot.id);
      else if (defaultState?.chosenRobotId)
        setChosenRobot(defaultState.chosenRobotId);
      else setChosenRobot(robots ? robots[0].id : "");

      setInputText(defaultState?.name);
    } else {
      setInputText("");
      setError("");
      setChosenRobot("");
    }
  }, [
    isOpen,
    defaultRobot?.id,
    defaultState?.chosenRobotId,
    defaultState?.robot,
    defaultState?.name,
    robots,
  ]);

  return (
    <ModalOutline
      isOpen={isOpen}
      heading={title}
      onRequestClose={onRequestClose}
      keepOnOverlayClick={true}
    >
      <ModalForm style={{ padding: "24px 34px" }}>
        <div style={{ color: "#5C605D", margin: "10px 0", marginLeft: "15px" }}>
          Alias Name
        </div>

        <InputText
          width="100%"
          height="6.4rem"
          borderColor={COLORS.secondary.water_green}
          handleChange={(text) => {
            setError("");
            setInputText(text);
          }}
          value={inputText}
          error={error}
          backgroundColor="#fff"
        />

        {robots && (
          <>
            <div
              style={{ color: "#5C605D", margin: "10px 0", marginLeft: "15px" }}
            >
              Choose robot
            </div>
            <StyledSelect
              onClick={(e) => e.stopPropagation()}
              value={chosenRobot}
              onChange={(e) => {
                setChosenRobot(e.target.value);
              }}
            >
              {robots.map((robot) => (
                <StyledOption key={robot.id} value={robot.id}>
                  {robot.name}
                </StyledOption>
              ))}
            </StyledSelect>
          </>
        )}
        {angle && (
          <>
            <div
              style={{ color: "#5C605D", margin: "10px 0", marginLeft: "15px" }}
            >
              Insert Angle
            </div>

            <InputText
              width="30%"
              height="6.4rem"
              borderColor={COLORS.secondary.water_green}
              handleChange={(text) => {
                setAngleInput(+text);
              }}
              value={angleInput}
              backgroundColor="#fff"
              type="number"
            />
          </>
        )}
        <StyledButtonWrapper>
          <StyledSubmitButton onClick={onSubmit}>Save</StyledSubmitButton>
        </StyledButtonWrapper>
      </ModalForm>
    </ModalOutline>
  );
};

export default InputModal;
