import moment from "moment-timezone";
import { ROBOTTASKSTATUSENUM } from "../../../enums";
import { useCallback } from "react";

export const useAgendaData = () => {
  const handleAgendaDataBinding = useCallback(
    (tasks, futureAssignments, calendarDate) => {
      let obj = {
        immediateTasks: [],
        assignments: [],
      };
      obj.assignments = futureAssignments
        .map((assignment) => {
          assignment?.assignmentExceptions.forEach((exception) => {
            if (
              moment(exception.executionTime).format("YY-MM-DD") ===
                moment(calendarDate).format("YY-MM-DD") ||
              moment(exception.executionTime).format("YY-MM-DD") ===
                moment(assignment?.agendaDate).format("YY-MM-DD")
            ) {
              if (exception.hibernate) assignment = null;
              else {
                const ids = [
                  ...exception.exception_data.tasks?.map((e) => e.id),
                  ...exception.deletedTasks,
                ];
                const tasks = [
                  ...exception.exception_data.tasks,
                  ...assignment.tasks?.filter((e) => !ids.includes(e.id)),
                ];
                assignment = {
                  ...exception.exception_data,
                  exceptionId: exception.id,
                  tasks,
                };
              }
            }
          });

          if (!assignment) return assignment;
          return {
            ...assignment,
            assignment: true,
            executionTime: assignment.executionTime,
            tasks: assignment?.tasks?.map((el) => ({
              ...el,
              status: ROBOTTASKSTATUSENUM.DEFAULT,
            })),
          };
        })
        .filter((item) => item);

      tasks.forEach((item) => {
        if (!item.assignment) {
          obj.immediateTasks = [
            ...obj.immediateTasks,
            {
              ...item,
              name: item.application?.aliasName,
              executionTime: item.createdAt,
            },
          ];
        } else {
          let index = false;
          const found = obj.assignments.find((el, ind) => {
            if (el.assignmentId === item.assignment.id) {
              index = ind;
              return true;
            }
            return false;
          });
          if (!found) {
            obj.assignments = [
              ...obj.assignments,
              {
                id: item.assignment.id,
                assignmentId: item.assignment.id,
                launchId: item.launchId,
                assignment: true,
                executionTime: item.assignment.executionTime,
                name: item.assignment.name,
                disposable: item.assignment.disposable,
                status: item.assignment.status,
                agendaDate: item.agendaDate,
                tasks: [
                  {
                    ...item,
                    task: item.task,
                    application: item.application,
                    robot: item.robot,
                    status: item.status,
                  },
                ],
              },
            ];
          } else {
            obj.assignments[index].tasks = [
              ...obj.assignments[index].tasks,
              {
                ...item,
                task: item.task,
                robot: item.robot,
                status: item.status,
                application: item.application,
              },
            ];
          }
        }
      });
      return obj;
    },
    []
  );

  return {
    handleAgendaDataBinding,
  };
};
