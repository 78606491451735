import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { TransitiveCapability } from "@transitive-sdk/utils-web";
import { StyledInput } from "../../../components/agenda-assignment-details-modal/components";
import "./Transitive.css";
import getTeleopJwt from "../../../APIs/teleoperation/getTeleopJwt";
import TransitiveDefaultConfiguration from "./TransitiveDefaultConfiguration.json";
import LinesConfiguration from "../teleoperation/lines/LinesConfiguration";
import defaultSourceConfig from "./TeleopSourceConfiguration.json";

const Transitive = ({ robot, showInput, isEditModeRef }) => {
  const teleopSourceConfig = robot?.teleopSourceConfig || defaultSourceConfig;
  const [jwt, setjwt] = useState();

  const { frontVideoLines, rearVideoLines, frontVideoCurves, rearVideoCurves } =
    TransitiveDefaultConfiguration;

  const video0LinesRef = useRef(
    robot?.teleopConfiguration?.frontVideoLines || frontVideoLines
  );
  const video1LinesRef = useRef(
    robot?.teleopConfiguration?.rearVideoLines || rearVideoLines
  );
  const video0CurvesRef = useRef(
    robot?.teleopConfiguration?.frontVideoCurves || frontVideoCurves
  );
  const video1CurvesRef = useRef(
    robot?.teleopConfiguration?.rearVideoCurves || rearVideoCurves
  );

  const [lineWidth, setLineWidth] = useState(
    robot?.teleopConfiguration?.linesWidth ||
      TransitiveDefaultConfiguration.linesWidth
  );

  const canvasRef0 = useRef(null);
  const canvasRef1 = useRef(null);
  const drawRef0 = useRef(null);
  const drawRef1 = useRef(null);
  const dragPointRef = useRef(null);
  const resizeCleanupRefs = useRef([]);
  const videoRefs = useRef({ video0: null, video1: null });

  const drawGuidanceLines = useCallback(
    (canvas, video, videoKey) => {
      const context = canvas.getContext("2d");

      const draw = () => {
        canvas.width = video.offsetWidth;
        canvas.height = video.offsetHeight;
        context.clearRect(0, 0, canvas.width, canvas.height);

        let lines, curves;
        if (videoKey === "video0") {
          lines = video0LinesRef.current;
          curves = video0CurvesRef.current;
        } else {
          lines = video1LinesRef.current;
          curves = video1CurvesRef.current;
        }

        lines.forEach((line) => {
          context.strokeStyle = line.color;
          context.globalAlpha = line.opacity;
          context.lineWidth = lineWidth;
          context.beginPath();
          context.moveTo(
            canvas.width * line.points.x1,
            canvas.height * line.points.y1
          );
          context.lineTo(
            canvas.width * line.points.x2,
            canvas.height * line.points.y2
          );
          context.stroke();
        });

        curves.forEach((curve) => {
          context.strokeStyle = curve.color;
          context.globalAlpha = curve.opacity;
          context.lineWidth = lineWidth;
          context.beginPath();
          context.moveTo(
            canvas.width * curve.points.start.x,
            canvas.height * curve.points.start.y
          );
          context.quadraticCurveTo(
            canvas.width * curve.points.control.x,
            canvas.height * curve.points.control.y,
            canvas.width * curve.points.end.x,
            canvas.height * curve.points.end.y
          );
          context.stroke();

          if (isEditModeRef.current) {
            context.fillStyle = curve.color;
            context.globalAlpha = 1;
            context.strokeStyle = "rgba(0, 0, 0, 0.8)";
            context.lineWidth = 1.5;

            Object.values(curve.points).forEach((point) => {
              context.beginPath();
              context.arc(
                canvas.width * point.x,
                canvas.height * point.y,
                8,
                0,
                Math.PI * 2
              );
              context.fill();
              context.stroke();
            });
          }
        });

        if (isEditModeRef.current) {
          context.fillStyle = "rgba(255, 255, 255, 0.8)";
          context.strokeStyle = "rgba(0, 0, 0, 0.8)";
          context.lineWidth = 1.5;

          lines.forEach((line) => {
            context.beginPath();
            context.arc(
              canvas.width * line.points.x1,
              canvas.height * line.points.y1,
              8,
              0,
              Math.PI * 2
            );
            context.fill();
            context.stroke();
            context.beginPath();
            context.arc(
              canvas.width * line.points.x2,
              canvas.height * line.points.y2,
              8,
              0,
              Math.PI * 2
            );
            context.fill();
            context.stroke();
          });
        }

        drawRef0.current = requestAnimationFrame(draw);
      };

      draw();
    },
    [isEditModeRef, lineWidth]
  );

  const handleCanvasMouseDown = useCallback(
    (e, canvas, videoKey) => {
      if (!isEditModeRef.current) return;
      const rect = canvas.getBoundingClientRect();
      const x = (e.clientX - rect.left) / canvas.width;
      const y = (e.clientY - rect.top) / canvas.height;

      let lines, curves;
      if (videoKey === "video0") {
        lines = video0LinesRef.current;
        curves = video0CurvesRef.current;
      } else {
        lines = video1LinesRef.current;
        curves = video1CurvesRef.current;
      }

      // Identify which point the mouse is closest to
      lines.forEach((line, index) => {
        const isNear = (px, py) => {
          const distance = Math.sqrt(Math.pow(x - px, 2) + Math.pow(y - py, 2));
          return distance < 0.05;
        };

        if (isNear(line.points.x1, line.points.y1)) {
          dragPointRef.current = `line_${index}_${videoKey}-start`;
        } else if (isNear(line.points.x2, line.points.y2)) {
          dragPointRef.current = `line_${index}_${videoKey}-end`;
        }
      });

      // Identify which point the mouse is closest to for the curve
      curves.forEach((curve, index) => {
        const isNear = (px, py) => {
          const distance = Math.sqrt(Math.pow(x - px, 2) + Math.pow(y - py, 2));
          return distance < 0.05;
        };

        if (isNear(curve.points.start.x, curve.points.start.y)) {
          dragPointRef.current = `curve_${index}_${videoKey}-start`;
        } else if (isNear(curve.points.end.x, curve.points.end.y)) {
          dragPointRef.current = `curve_${index}_${videoKey}-end`;
        } else if (isNear(curve.points.control.x, curve.points.control.y)) {
          dragPointRef.current = `curve_${index}_${videoKey}-control`;
        }
      });
    },
    [isEditModeRef]
  );

  const handleCanvasMouseMove = useCallback(
    (e, canvas, videoKey) => {
      if (!isEditModeRef.current || !dragPointRef.current) return;

      const rect = canvas.getBoundingClientRect();
      const x = (e.clientX - rect.left) / canvas.width;
      const y = (e.clientY - rect.top) / canvas.height;

      const updateLinePoints = (lines) => {
        return lines.map((line, index) => {
          if (dragPointRef.current === `line_${index}_${videoKey}-start`) {
            return {
              ...line,
              points: { ...line.points, x1: x, y1: y },
            };
          } else if (dragPointRef.current === `line_${index}_${videoKey}-end`) {
            return {
              ...line,
              points: { ...line.points, x2: x, y2: y },
            };
          }
          return line;
        });
      };

      const updateCurvePoints = (curves) => {
        return curves.map((curve, index) => {
          if (dragPointRef.current === `curve_${index}_${videoKey}-start`) {
            curve.points.start.x = x;
            curve.points.start.y = y;
          } else if (
            dragPointRef.current === `curve_${index}_${videoKey}-end`
          ) {
            curve.points.end.x = x;
            curve.points.end.y = y;
          } else if (
            dragPointRef.current === `curve_${index}_${videoKey}-control`
          ) {
            curve.points.control.x = x;
            curve.points.control.y = y;
          }

          return curve;
        });
      };

      if (videoKey === "video0") {
        video0LinesRef.current = updateLinePoints(video0LinesRef.current);
        video0CurvesRef.current = updateCurvePoints(video0CurvesRef.current);
      } else {
        video1LinesRef.current = updateLinePoints(video1LinesRef.current);
        video1CurvesRef.current = updateCurvePoints(video1CurvesRef.current);
      }
    },
    [isEditModeRef]
  );

  const handleCanvasMouseUp = useCallback(() => {
    dragPointRef.current = null;
  }, []);

  // Effect for handling video overlays
  useEffect(() => {
    const setupVideoOverlay = (video, canvasRef, drawRef, videoKey) => {
      if (video) {
        const checkVideoActive = () => {
          // Check if the video is displaying active content
          if (video.videoWidth > 0 && video.videoHeight > 0) {
            setupCanvasOverlay(video, canvasRef.current, drawRef, videoKey);
          } else {
            // Try again after a delay if video is not yet active
            setTimeout(checkVideoActive, 500);
          }
        };
        checkVideoActive();
      }
    };

    const setupCanvasOverlay = (video, canvas, drawRef, videoKey) => {
      videoRefs.current[videoKey] = video;

      // Configure canvas position and behavior
      canvas.style.position = "absolute";
      canvas.style.pointerEvents = "auto"; // Enable pointer events for dragging
      canvas.style.display = "block";

      const onMouseDown = (e) => handleCanvasMouseDown(e, canvas, videoKey);
      const onMouseMove = (e) => handleCanvasMouseMove(e, canvas, videoKey);
      const onMouseUp = handleCanvasMouseUp;
      const onMouseLeave = handleCanvasMouseUp;

      // Add mouse event listeners
      canvas.addEventListener("mousedown", onMouseDown);
      canvas.addEventListener("mousemove", onMouseMove);
      canvas.addEventListener("mouseup", onMouseUp);
      canvas.addEventListener("mouseleave", onMouseLeave);

      const updateCanvasSize = () => {
        canvas.style.width = video.offsetWidth + "px";
        canvas.style.height = video.offsetHeight + "px";
        canvas.style.left = video.offsetLeft + "px";
        canvas.style.top = video.offsetTop + "px";
      };

      const resizeObserver = new ResizeObserver(updateCanvasSize);
      resizeObserver.observe(video);

      resizeCleanupRefs.current.push(() => resizeObserver.disconnect());
      if (drawRef.current) {
        cancelAnimationFrame(drawRef.current);
      }

      drawGuidanceLines(canvas, video, videoKey);

      return () => {
        canvas.removeEventListener("mousedown", onMouseDown);
        canvas.removeEventListener("mousemove", onMouseMove);
        canvas.removeEventListener("mouseup", onMouseUp);
        canvas.removeEventListener("mouseleave", onMouseLeave);
      };
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          try {
            node.classList?.contains("webrtc-video0") &&
              setupVideoOverlay(node, canvasRef0, drawRef0, "video0");

            node.classList?.contains("webrtc-video1") &&
              setupVideoOverlay(node, canvasRef1, drawRef1, "video1");

            if (node.classList?.contains("remote-teleop-joystick")) {
              const wrapper = document.getElementsByClassName(
                "remote-teleop-joystick"
              );
              wrapper[0].classList.add("sr-only");
            }
          } catch (error) {
            console.log(error);
          }
        });
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Redraw existing videos when lineWidth changes
    if (videoRefs.current.video0) {
      drawGuidanceLines(canvasRef0.current, videoRefs.current.video0, "video0");
    }
    if (videoRefs.current.video1) {
      drawGuidanceLines(canvasRef1.current, videoRefs.current.video1, "video1");
    }

    return () => {
      observer.disconnect();
      if (drawRef0.current) cancelAnimationFrame(drawRef0.current);
      resizeCleanupRefs.current.forEach((cleanup) => cleanup());
      resizeCleanupRefs.current = [];
    };
  }, [
    drawGuidanceLines,
    handleCanvasMouseDown,
    handleCanvasMouseMove,
    handleCanvasMouseUp,
  ]);

  // get jwt token for streaming

  useEffect(() => {
    const generateJwt = async () => {
      const jwt = await getTeleopJwt({ robotId: robot.robotId });
      setjwt(jwt);
    };
    robot.robotId && generateJwt();
  }, [robot.robotId]);

  const updateItemProperty = useCallback(
    (type, video, index, property, value) => {
      if (type === "line") {
        if (video === "front") {
          video0LinesRef.current = video0LinesRef.current.map((item, idx) =>
            idx === index ? { ...item, [property]: value } : item
          );
        }
        if (video === "rear") {
          video1LinesRef.current = video1LinesRef.current.map((item, idx) =>
            idx === index ? { ...item, [property]: value } : item
          );
        }
      } else if (type === "curve") {
        if (video === "front") {
          video0CurvesRef.current = video0CurvesRef.current.map((item, idx) =>
            idx === index ? { ...item, [property]: value } : item
          );
        }
        if (video === "rear") {
          video1CurvesRef.current = video1CurvesRef.current.map((item, idx) =>
            idx === index ? { ...item, [property]: value } : item
          );
        }
      }
      // Trigger a manual redraw if needed
    },
    []
  );

  const handleColorOpacityChange = useCallback(
    (type, video, index, property, value) => {
      updateItemProperty(type, video, index, property, value);
      if (videoRefs.current.video0) {
        drawGuidanceLines(
          canvasRef0.current,
          videoRefs.current.video0,
          "video0"
        );
      }
      if (videoRefs.current.video1) {
        drawGuidanceLines(
          canvasRef1.current,
          videoRefs.current.video1,
          "video1"
        );
      }
    },
    [drawGuidanceLines, updateItemProperty]
  );

  const LinesConfigurationComponent = useMemo(() => {
    return (
      <LinesConfiguration
        robot_uid={robot?.id}
        robotId={robot?.robotId}
        lineWidth={lineWidth}
        setLineWidth={setLineWidth}
        handleColorOpacityChange={handleColorOpacityChange}
        video0LinesRef={video0LinesRef}
        video1LinesRef={video1LinesRef}
        video0CurvesRef={video0CurvesRef}
        video1CurvesRef={video1CurvesRef}
        onSave={() => (isEditModeRef.current = !isEditModeRef.current)}
      />
    );
  }, [
    handleColorOpacityChange,
    lineWidth,
    robot?.id,
    robot?.robotId,
    isEditModeRef,
  ]);
  return (
    <div key={robot.id} style={{ position: "relative", width: "100%" }}>
      {jwt && (
        <TransitiveCapability
          id="unlimited-robotics_com"
          host="transitiverobotics.com"
          ssl="true"
          jwt={jwt}
          type={teleopSourceConfig?.type}
          count={teleopSourceConfig?.count}
          rtpmtu={teleopSourceConfig?.rtpmtu}
          source={teleopSourceConfig?.source}
          type_1={teleopSourceConfig?.type_1}
          bitrate={teleopSourceConfig?.bitrate}
          timeout={teleopSourceConfig?.timeout}
          source_1={teleopSourceConfig?.source_1}
          quantizer={teleopSourceConfig?.quantizer}
          control_rosVersion="2"
          control_topic="/raya/teleop_transitive/joy"
          control_type="sensor_msgs/msg/Joy"
          alwayson="true"
        />
      )}
      {showInput && (
        <>
          <StyledInput
            value={jwt}
            onChange={(e) => {
              localStorage.setItem("transitive-jwt", e.target.value);
              setjwt(e.target.value);
            }}
            placeholder="Transitive JWT token"
          />

          <button
            style={{
              padding: "10px 20px",
              backgroundColor: isEditModeRef.current ? "#ff4444" : "#44aa44",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => {
              isEditModeRef.current = !isEditModeRef.current;
            }}
          >
            {isEditModeRef.current ? "Save" : "Edit"}
          </button>
        </>
      )}

      {isEditModeRef.current && LinesConfigurationComponent}

      <canvas
        ref={canvasRef0}
        className="teleop-lines-canvas"
        style={{ display: "none" }}
      />
      <canvas
        ref={canvasRef1}
        className="teleop-lines-canvas"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Transitive;
