import styled from "styled-components";

export const StyledSingleLineContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const WhiteWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 16px;
  border-radius: 16px;
`;
