import React, { useContext, useEffect, useState } from "react";
import {
  EditableWidgetContentWrapper,
  EditableWidgetHeader,
  StyledWidgetContent,
  StyledWidgetModalContentWrapper,
  StyledWidgetType,
  WidgetSelectorForm,
  WidgetModalWrapper,
} from "./components";
import ICONS from "../../../constants/icons";
import {
  InputTitle,
  InputWrapper,
  StyledOption,
  StyledSelect,
} from "../../robots/tasks-in-assignments/components";
import COLORS from "../../../constants/colors";
import InputText from "../../../components/input/InputText";
import { InputTitleWrapper } from "../../../components/add-assignment-modal/components";
import SearchableSelect from "../../../components/searchable-select/SearchableSelect";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setAnalyticsData,
  setTriggerAnalyticsRefetch,
  setWidgetModalData,
} from "../../../redux/analytics";
import { StyledButton } from "../../../components/button/components";
import createAnalyticsConfig from "../../../APIs/analytics/createAnalyticsConfig";
import updateAnalyticsConfig from "../../../APIs/analytics/updateAnalyticsConfig";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import { InputError } from "../../../components/input/components";
import WidgetPreview from "./WidgetPreview";
import getApplications from "../../../APIs/application/getApplications";

const AnalyticsConfigModal = ({ onRequestClose = () => {} }) => {
  const { widgetModalData, analyticsData, eventNames } = useSelector(
    (state) => state.analytics
  );
  const [appsData, setAppsData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [widgetData, setWidgetData] = useState(widgetModalData);
  const dispatch = useDispatch();

  const measurementsList = ["sum", "average"];
  const unitsList = ["distance", "hr", "$"];

  const { isMobile } = useContext(ResponsiveContext);
  const { chosenClient } = useSelector((state) => state.clients);

  useEffect(() => {
    if (widgetModalData)
      widgetModalData.isCreated
        ? setWidgetData(widgetModalData)
        : setWidgetData({
            type: widgetModalData.type,
            description: "",
            unit: null,
            measurement: "sum",
            application: null,
          });
  }, [widgetModalData]);

  useEffect(() => {
    chosenClient &&
      getApplications(chosenClient).then((data) => {
        setAppsData(data);
      });
  }, [chosenClient]);

  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "999",
    },

    content: {
      zIndex: "999",
      width: isMobile ? "80%" : "60%",
      height: "fit-content",
      overflow: "unset",
      top: "50%",
      left: "50%",
      right: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };

  const handleParametersChange = (value, inputName) => {
    console.log("🚀 ~ handleParametersChange ~ value:", value);
    setWidgetData((prev) => ({ ...prev, [inputName]: value }));
  };

  const isConfigurationValid = (config) => {
    const { title, description, event, parameter, measurement } = config;
    return title && description && event && parameter && measurement;
  };
  const handleSave = async () => {
    if (!isConfigurationValid(widgetData)) return setIsError(true);
    if (widgetModalData.isCreated) {
      const response = await updateAnalyticsConfig(widgetData.id, widgetData);
      const updatedData = analyticsData.map((el) =>
        el.id === response.id ? { ...el, response } : el
      );
      dispatch(setAnalyticsData(updatedData));
      dispatch(setWidgetModalData(null));
    } else {
      const grid = {
        x: (analyticsData.length % 4) * 3, // Initial x-position
        y: Math.floor(analyticsData.length / 4) * 3, // Initial y-position
        w: 3, // Initial width (grid units)
        h: 3, // Initial height (grid units)}
      };
      await createAnalyticsConfig({
        ...widgetData,
        clientId: chosenClient,
        gridData: { ...grid },
      });
      dispatch(setWidgetModalData(null));
    }
    dispatch(setTriggerAnalyticsRefetch());
  };
  return (
    <Modal
      isOpen={!!widgetModalData}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <WidgetModalWrapper>
        <EditableWidgetHeader>
          <StyledWidgetType>{widgetModalData?.type}</StyledWidgetType>
          <img
            src={ICONS.BlackCross}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={() => dispatch(setWidgetModalData(null))}
          />
        </EditableWidgetHeader>
        <StyledWidgetModalContentWrapper>
          <EditableWidgetContentWrapper>
            <WidgetSelectorForm>
              <InputTitleWrapper>
                <InputTitle>Insert A Title</InputTitle>
                <InputWrapper>
                  <InputText
                    height="4.4rem"
                    borderColor={COLORS.secondary.water_green}
                    name={"title"}
                    hideInputName={true}
                    handleChange={(val, inputName) =>
                      handleParametersChange(val, inputName)
                    }
                    value={widgetData?.title || ""}
                    error={isError && !widgetData?.title && "Required"}
                  />
                </InputWrapper>
              </InputTitleWrapper>
              <InputTitleWrapper style={{ marginTop: "20px" }}>
                <InputTitle>Insert A Description</InputTitle>
                <InputWrapper>
                  <InputText
                    height="4.4rem"
                    borderColor={COLORS.secondary.water_green}
                    name={"description"}
                    hideInputName={true}
                    handleChange={(val, inputName) =>
                      handleParametersChange(val, inputName)
                    }
                    value={widgetData?.description || ""}
                    error={isError && !widgetData?.description && "Required"}
                  />
                </InputWrapper>
              </InputTitleWrapper>
              <InputTitleWrapper style={{ marginTop: "20px" }}>
                <InputTitle>Event</InputTitle>
                <SearchableSelect
                  value={widgetData?.event}
                  handleChange={(val) => handleParametersChange(val, "event")}
                  options={eventNames}
                  error={isError && !widgetData?.event && "Required"}
                />
              </InputTitleWrapper>

              <InputTitleWrapper style={{ marginTop: "20px" }}>
                <InputTitle>Parameter</InputTitle>
                <InputWrapper>
                  <InputText
                    height="4.4rem"
                    borderColor={COLORS.secondary.water_green}
                    name={"parameter"}
                    hideInputName={true}
                    handleChange={(val, inputName) =>
                      handleParametersChange(val, inputName)
                    }
                    value={widgetData?.parameter || ""}
                    error={isError && !widgetData?.parameter && "Required"}
                  />
                </InputWrapper>
              </InputTitleWrapper>

              <InputTitleWrapper style={{ marginTop: "20px" }}>
                <InputTitle>Measurement</InputTitle>
                <StyledSelect
                  style={{ height: "4.6rem" }}
                  name="measurement"
                  value={widgetData?.measurement}
                  onChange={(e) =>
                    handleParametersChange(e.target.value, "measurement")
                  }
                >
                  {measurementsList.map((event) => (
                    <StyledOption key={event}>{event}</StyledOption>
                  ))}
                </StyledSelect>
                {isError && !widgetData?.parameter && (
                  <InputError className="error">* Required</InputError>
                )}
              </InputTitleWrapper>

              <InputTitleWrapper style={{ marginTop: "20px" }}>
                <InputTitle>Unit</InputTitle>
                <StyledSelect
                  style={{ height: "4.6rem" }}
                  name="unit"
                  value={widgetData?.unit}
                  onChange={(e) =>
                    handleParametersChange(e.target.value, "unit")
                  }
                >
                  <StyledOption value={null}>{"No Unit"}</StyledOption>
                  {unitsList.map((event) => (
                    <StyledOption key={event} value={event}>
                      {event}
                    </StyledOption>
                  ))}
                </StyledSelect>
              </InputTitleWrapper>

              <InputTitleWrapper style={{ marginTop: "20px" }}>
                <InputTitle>Application</InputTitle>
                <StyledSelect
                  style={{ height: "4.6rem" }}
                  value={widgetData?.applicationId}
                  onChange={(e) =>
                    handleParametersChange(
                      e.target.value === "All" ? null : e.target.value,
                      "applicationId"
                    )
                  }
                >
                  <StyledOption value="All">{"All"}</StyledOption>
                  {appsData.map((application) => (
                    <StyledOption key={application.id} value={application.id}>
                      {application.aliasName}
                    </StyledOption>
                  ))}
                </StyledSelect>
              </InputTitleWrapper>
            </WidgetSelectorForm>
          </EditableWidgetContentWrapper>
          <StyledWidgetContent>
            <WidgetPreview widgetData={widgetData} />
            <StyledButton
              borderColor={"#fff"}
              fontSize={"16px"}
              color={"#fff"}
              fontFamily={"unset"}
              backgroundColor={"#0096C6"}
              width={"200px"}
              padding={"8px 45px"}
              onClick={handleSave}
            >
              Save
            </StyledButton>
          </StyledWidgetContent>
        </StyledWidgetModalContentWrapper>
      </WidgetModalWrapper>
    </Modal>
  );
};

export default AnalyticsConfigModal;
