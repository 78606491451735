import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentUser, signIn, signUp } from "../APIs";
import { APIRESPONSEENUM, ROLESENUM } from "../enums";
import { helpCrunchSetup } from "../utils/helpcrunch";

const initialState = {
  chosenClient: localStorage.getItem("selected_client_id") || null,
  client: {},
  map: null,
  isCompanyMember: false,
  hasMultipleClients: false,
  sidebarOpen: true,
  currentUser: null,
  isClientLoading: false,
  isLoggedIn:
    !!localStorage.getItem("token") &&
    !!localStorage.getItem("selected_client_id"),
  token: null,
  sign_error: null,
  currentRole: null,
  inviteMemberValues: null,
};

export const userSignIn = createAsyncThunk(
  "clients/userSignIn",
  async ({ vars, setErrors, goToDashboard }, { rejectWithValue }) => {
    try {
      const response = await signIn(vars);
      helpCrunchSetup(response, true);
      goToDashboard();
      return response;
    } catch (error) {
      // setErrors(error.response.data.message);
      throw rejectWithValue(error.response.data);
      // return error;
    }
  }
);

export const userSignUp = createAsyncThunk(
  "clients/userSignUp",
  async ({ vars, setErrors, goToVerifyEmail }, { rejectWithValue }) => {
    try {
      if (!vars?.email.includes("@unlimited-robotics.com")) {
        throw rejectWithValue("Email must belong to Unlimited Robotics");
      }
      const response = await signUp(vars);
      helpCrunchSetup(response, true);
      goToVerifyEmail();
      return response;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentUserFunction = createAsyncThunk(
  "clients/getCurrentUser",
  async () => {
    if (!localStorage.getItem("token")) return;
    const response = await getCurrentUser();
    helpCrunchSetup(response);
    return response;
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setChosenClient: (state, action) => {
      localStorage.setItem("selected_client_id", action.payload);
      if (state?.currentUser?.roles?.all) state.currentRole = ROLESENUM.ADMIN;
      else state.currentRole = state?.currentUser?.roles[action.payload];
      state.chosenClient = action.payload;
      state.map = null;
    },
    setClient: (state, action) => {
      state.client = action.payload || {};
    },
    setClientMap: (state, action) => {
      state.map = action.payload;
    },
    setSidebarOpen: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    logOut: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("selected_client_id");
      state.isLoggedIn = false;
      state.chosenClient = null;
    },
    setSignError: (state, action) => {
      state.sign_error = action.payload;
    },
    setUserRole: (state) => {
      if (state?.currentUser?.roles?.all) state.currentRole = ROLESENUM.ADMIN;
      else state.currentRole = state?.currentUser?.roles[state.chosenClient];
    },
    setInviteMemberValues: (state, action) => {
      state.inviteMemberValues = action.payload;
    },
    setClientLoader: (state, action) => {
      state.isClientLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(userSignIn.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isCompanyMember = state.currentUser.roles.all;
      state.hasMultipleClients =
        state.isCompanyMember ||
        Object.keys(state.currentUser.roles)?.length > 2;
      state.isClientLoading = false;
      state.isLoggedIn = true;
    });
    builder.addCase(userSignIn.pending, (state, action) => {
      // state.isClientLoading = true;
    });
    builder.addCase(userSignIn.rejected, (state, action) => {
      localStorage.setItem("emailToVerify", action.meta.arg.vars.email);
      state.sign_error = action.payload?.message;
      state.isClientLoading = false;
    });
    builder.addCase(userSignUp.fulfilled, (state, action) => {
      localStorage.setItem("emailToVerify", action.meta.arg.vars.email);
    });
    builder.addCase(userSignUp.rejected, (state, action) => {
      state.sign_error =
        action.payload?.message || "Email must belong to Unlimited Robotics";
      state.isClientLoading = false;
    });

    builder.addCase(getCurrentUserFunction.fulfilled, (state, action) => {
      if (action.payload) {
        state.currentUser = action.payload;
        state.isCompanyMember = state.currentUser.roles?.all;
        state.hasMultipleClients =
          state.isCompanyMember ||
          Object.keys(state.currentUser.roles)?.length > 2;
        state.isClientLoading = false;
        if (state.currentUser.roles.all) state.currentRole = ROLESENUM.ADMIN;
        else state.currentRole = state.currentUser.roles[state.chosenClient];
        state.isLoggedIn = true;
        localStorage.setItem("token", state.currentUser.token);
      }
    });
    builder.addCase(getCurrentUserFunction.pending, (state, action) => {
      state.isClientLoading = true;
    });
    builder.addCase(getCurrentUserFunction.rejected, (state, action) => {
      if (action.error.code === APIRESPONSEENUM.ERR_BAD_REQUEST) {
        localStorage.removeItem("token");
        localStorage.removeItem("selected_client_id");
        state.isLoggedIn = false;
        state.isClientLoading = false;
        state.chosenClient = null;
      }
    });
  },
});

export const {
  setChosenClient,
  setClient,
  setClientMap,
  setSidebarOpen,
  setCurrentUser,
  logOut,
  setSignError,
  setUserRole,
  setInviteMemberValues,
  setClientLoader,
} = clientsSlice.actions;

export default clientsSlice.reducer;
