import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";
import { AdminTableContext } from "../../contexts/AdminTableContext";
import {
  setChosenClient,
  setClient,
  setSidebarOpen,
} from "../../redux/clients";
import {
  WholeWrapper,
  HeaderWrapper,
  MainContainer,
  Sidebar,
  RightContainer,
  SidebarHeader,
  HeaderBurger,
  HeaderLogo,
  PageTitle,
  HeaderLogout,
  SidebarFooter,
  HeaderSelect,
  SelectOption,
  StyledWelcomeText,
} from "./components";
import Clock from "../clock/clock";
import NavList from "./NavList";
import ICONS from "../../constants/icons";
import MobileSidebar from "../mobile-sidebar/MobileSidebar";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LogOutModal from "../log-out-modal/LogOutModal";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
const OutlineAuthorised = ({ children, pageTitle, paddingTop }) => {
  const { isMobile, isTablet, isIos } = useContext(ResponsiveContext);
  const dispatch = useDispatch();
  const { hasMultipleClients, sidebarOpen, chosenClient, client, currentUser } =
    useSelector((state) => state.clients);
  const [showMobileSiderbar, setShowMobileSidebar] = useState();
  const [isLogOutModal, setIsLogOutModal] = useState();
  const { clients } = useContext(AdminTableContext);
  const location = useLocation();

  const handleSelectChange = (e) => {
    dispatch(setChosenClient(e.target.value));
    dispatch(setClient(clients.find((item) => item.id === e.target.value)));
  };
  useEffect(() => {
    if (sidebarOpen) {
      isTablet && dispatch(setSidebarOpen());
    }
  }, [dispatch, sidebarOpen, isTablet]);
  return (
    <WholeWrapper isMobile={isMobile} style={{ height: isIos && "100svh" }}>
      <Sidebar isOpen={sidebarOpen} isMobile={isMobile}>
        <SidebarHeader isMobile={isMobile}>
          <HeaderBurger onClick={() => dispatch(setSidebarOpen())} />
          <HeaderLogo img={client?.logo} />
        </SidebarHeader>

        <NavList />

        <SidebarFooter>
          <Clock sidebarOpen={sidebarOpen} />
          <div>
            <a href={URLS.terms_of_use} target="_blank" rel="noreferrer">
              {`${STRINGS.terms_of_use} - `}
            </a>
            <a href={URLS.privacy_police} target="_blank" rel="noreferrer">
              {STRINGS.privacy_policy}
            </a>
          </div>
          <div>
            <a href={URLS.data_protection} target="_blank" rel="noreferrer">
              {STRINGS.data_protection}
            </a>
          </div>
        </SidebarFooter>
      </Sidebar>
      <OutsideClickHandler onOutsideClick={() => setShowMobileSidebar(false)}>
        {isMobile && (
          <MobileSidebar
            isOpen={showMobileSiderbar}
            setShowMobileSidebar={setShowMobileSidebar}
          />
        )}
      </OutsideClickHandler>

      <RightContainer isMobile={isMobile}>
        {!location?.pathname?.startsWith("/teleoperation/") && (
          <HeaderWrapper
            isMobile={isMobile}
            style={{
              justifyContent: !isMobile && !hasMultipleClients && "flex-end",
            }}
          >
            {isMobile && (
              <img
                src={ICONS.WhiteBurgerMenu}
                alt=""
                onClick={() => setShowMobileSidebar((prev) => !prev)}
              />
            )}

            {hasMultipleClients && (
              <HeaderSelect
                onChange={handleSelectChange}
                value={chosenClient}
                isMobile={isMobile}
              >
                {clients.map((item) => (
                  <SelectOption key={item.id} value={item.id}>
                    {item.name}
                  </SelectOption>
                ))}
              </HeaderSelect>
            )}
            {!hasMultipleClients && isMobile && (
              <StyledWelcomeText>
                Welcome Back, {currentUser?.firstName}!
              </StyledWelcomeText>
            )}
            {isMobile && (
              <HeaderLogo
                img={client?.logo}
                style={{ borderRadius: "unset" }}
              />
            )}

            {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
            {!isMobile && (
              <HeaderLogout
                isMobile={isMobile}
                onClick={() => {
                  setIsLogOutModal(true);
                }}
              />
            )}
          </HeaderWrapper>
        )}
        <MainContainer
          paddingTop={paddingTop}
          isMobile={isMobile}
          isTablet={isTablet}
          url={location?.pathname}
        >
          {children}
        </MainContainer>
      </RightContainer>
      <LogOutModal isOpen={isLogOutModal} setIsOpen={setIsLogOutModal} />
    </WholeWrapper>
  );
};

export default OutlineAuthorised;
