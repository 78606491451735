import React, { useMemo } from "react";
import {
  IconWrapper,
  TeleopHeaderContainer,
  TransitiveComponentWrapper,
} from "../components";
import ICONS from "../../../../constants/icons";
import { useTransitive } from "../../../../contexts/TransitiveContext";
import { useRef } from "react";

const TeleopStreamingComponent = ({
  handleTeleopFullScreen,
  isFullScreen,
  currentRobot,
}) => {
  const { Transitives } = useTransitive();
  const streamingWrapperRef = useRef(null);

  const TransitiveComponent = useMemo(() => {
    return Transitives.find((t) => t.key === currentRobot?.id);
  }, [currentRobot, Transitives]);

  return (
    <TeleopHeaderContainer
      style={{ position: "relative" }}
      ref={streamingWrapperRef}
    >
      <IconWrapper
        style={{
          position: "absolute",
          top: "25px",
          right: "23px",
          zIndex: 999,
        }}
        onClick={handleTeleopFullScreen}
      >
        <img src={ICONS.QrscanIcon} alt="" />
      </IconWrapper>
      <TransitiveComponentWrapper
        isFullScreen={isFullScreen}
        teleopVideoWidth={"60%"}
      >
        {TransitiveComponent || (
          <img
            src={ICONS.ErrorRobotIcon}
            alt="error"
            style={{ width: "100%" }}
          />
        )}
      </TransitiveComponentWrapper>
    </TeleopHeaderContainer>
  );
};

export default TeleopStreamingComponent;
