import React, { useState } from "react";
import { WhiteWrapper } from "../../pages/dashboard/components";
import { ComponentTitle } from "../../pages/admin-page/environment-setup/components";
import SearchableSelect from "../searchable-select/SearchableSelect";
import { StyledButton } from "../button/components";
import ICONS from "../../constants/icons";
import {
  EditBoxWrapper,
  StyledBtnAndSelectWrapper,
  VerticalLine,
} from "./components";
import EditBox from "../edit-box/EditBox";
import OutsideClickHandler from "react-outside-click-handler";
import InfoModal from "../info-modal/InfoModal";
import { InfoModalTypes } from "../../constants/types";
import deleteBuilding from "../../APIs/maps/deleteBuilding";
import {
  handleMapDelete,
  setChosenBuilding,
  setChosenFloor,
  setMapModalData,
  setMapsData,
} from "../../redux/maps";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ROLESENUM } from "../../enums";

const MapsManagementHeader = ({
  setBuildingModalData,
  floorOptions,
  buildingOptions,
}) => {
  const [buildingEditBox, setBuildingEditBox] = useState(false);
  const [mapEditBox, setMapEditBox] = useState(false);
  const [isDeleteBuildingModal, setIsDeleteBuildingModal] = useState(false);
  const [isDeleteMapModal, setIsDeleteMapModal] = useState(false);
  const { mapsData, chosenMapUrl, chosenFloor, chosenBuilding } = useSelector(
    (state) => state.maps
  );
  const { currentRole } = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  const handleAddNewBuilding = () => {
    setBuildingModalData({ buildingName: "" });
  };
  const handleAddNewMap = () => {
    dispatch(setMapModalData({ building: chosenBuilding, floorName: "" }));
  };
  const handleMapEdit = () => {
    const found = mapsData
      .find((el) => el.building === chosenBuilding)
      ?.floors?.find((item) => item.floor === chosenFloor);
    dispatch(
      setMapModalData({
        id: found?.id,
        building: chosenBuilding,
        floorName: chosenFloor,
        isCreated: true,
        mapUrl: chosenMapUrl,
        editedMapUrl: found.editedMapUrl,
        aliasName: found.aliasName,
      })
    );
  };

  const handleSubmit = async () => {
    await deleteBuilding(chosenBuilding);
    dispatch(
      setMapsData(mapsData.filter((el) => el.building !== chosenBuilding))
    );
    setIsDeleteBuildingModal(false);
    dispatch(setChosenBuilding(mapsData[0].building));
  };

  const handleDeleteFloor = async () => {
    dispatch(handleMapDelete({ chosenBuilding, chosenFloor }));
    setIsDeleteMapModal(false);
  };

  return (
    <React.Fragment>
      <WhiteWrapper
        style={{
          width: "auto",
          padding: "16px 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ComponentTitle style={{ whiteSpace: "nowrap", fontSize: "20px" }}>
          Multi-Maps
        </ComponentTitle>
        <VerticalLine />
        <StyledBtnAndSelectWrapper>
          <SearchableSelect
            value={chosenBuilding}
            width="190px"
            handleChange={(val) => dispatch(setChosenBuilding(val))}
            options={buildingOptions}
          />
          <StyledButton
            color="#fff"
            backgroundColor="rgba(6, 134, 216, 1)"
            padding="8px 24px"
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
            onClick={handleAddNewBuilding}
          >
            <img src={ICONS.WhiteCross} alt="" />
            <span style={{ color: "#fff" }}>Add New Building</span>
          </StyledButton>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={ICONS.VerticalDots}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => setBuildingEditBox((prev) => !prev)}
            />
            {buildingEditBox && (
              <OutsideClickHandler
                onOutsideClick={() => setBuildingEditBox(false)}
              >
                <EditBoxWrapper style={{ top: "-55px", right: "0px" }}>
                  <EditBox
                    hideDelete={
                      ![ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN].includes(
                        currentRole
                      )
                    }
                    handleEdit={() => {
                      setBuildingModalData({
                        buildingName: chosenBuilding,
                        isCreated: true,
                      });
                    }}
                    handleDelete={() => setIsDeleteBuildingModal(true)}
                  />
                </EditBoxWrapper>
              </OutsideClickHandler>
            )}
          </div>
        </StyledBtnAndSelectWrapper>
        <VerticalLine />
        <StyledBtnAndSelectWrapper>
          <SearchableSelect
            width="190px"
            value={chosenFloor}
            options={floorOptions}
            handleChange={(val) => dispatch(setChosenFloor(val))}
          />
          <StyledButton
            color="#fff"
            backgroundColor="rgba(6, 134, 216, 1)"
            padding="8px 24px"
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
            onClick={handleAddNewMap}
          >
            <img src={ICONS.WhiteCross} alt="" />
            <span style={{ color: "#fff" }}>Add Map</span>
          </StyledButton>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={ICONS.VerticalDots}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => setMapEditBox((prev) => !prev)}
            />
            {mapEditBox && (
              <OutsideClickHandler onOutsideClick={() => setMapEditBox(false)}>
                <EditBoxWrapper style={{ top: "-55px", right: "-20px" }}>
                  <EditBox
                    hideDelete={
                      ![ROLESENUM.ADMIN, ROLESENUM.CLIENTADMIN].includes(
                        currentRole
                      )
                    }
                    handleEdit={() => {
                      handleMapEdit();
                    }}
                    handleDelete={() => {
                      setIsDeleteMapModal(true);
                    }}
                  />
                </EditBoxWrapper>
              </OutsideClickHandler>
            )}
          </div>
        </StyledBtnAndSelectWrapper>
      </WhiteWrapper>
      <InfoModal
        isOpen={isDeleteBuildingModal}
        setIsDeleteModal={setIsDeleteBuildingModal}
        type={InfoModalTypes.WARNING}
        title="Are you sure to delete chosen building?"
        noOuterClickClosing
        cancelAction={() => {
          setIsDeleteBuildingModal(false);

          // setShowEdit(false);
        }}
        submitAction={handleSubmit}
        cancelText={"No"}
        submitText={"Yes"}
        disabled={false}
      />
      <InfoModal
        isOpen={isDeleteMapModal}
        setIsDeleteModal={setIsDeleteMapModal}
        type={InfoModalTypes.WARNING}
        title="Are you sure to delete chosen map?"
        noOuterClickClosing
        cancelAction={() => {
          setIsDeleteMapModal(false);
          // setShowEdit(false);
        }}
        submitAction={handleDeleteFloor}
        cancelText={"No"}
        submitText={"Yes"}
        disabled={false}
      />
    </React.Fragment>
  );
};

export default MapsManagementHeader;
