import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { AGENDAASSIGNMENTSSTATUSENUM, ROBOTTASKSTATUSENUM } from "../enums";
import { parseAssignment } from "../utils/parseAssignments";
const initialState = {
  agendaData: {
    immediateTasks: [],
    assignments: [],
  },
  agendaDataBeforeDrag: { immediateTasks: [], assignments: [] },
  sortedDataForAgenda: [],
  deleteTaskData: null,
  deleteAssignmentData: null,
  assignmentDetails: null,
  isAssignmentEditMode: false,
  editTaskData: null,
  stopTaskData: null,
  stopAssignmentData: null,
  calendarDate: new Date().toISOString(),
  refreshAgendaState: false,
  ITModal: { isOpen: false, robotName: "" },
  tasksInProgress: [],
  draggableAssignment: null,
  isLoading: false,
  duplicateAssignment: null,
  assignmentDetailsBeforeChange: null,
  chosenWeekDays: [],
};

export const updateAssignmentStatus = (item) => {
  if (!item?.tasks?.length) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DEFAULT,
    };
  } else if (
    item?.tasks?.find(
      (item) => item.status === ROBOTTASKSTATUSENUM.STOPPED_ASSIGNMENT
    )
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.STOPPED,
    };
  } else if (
    item?.tasks?.every((item) => item.status === ROBOTTASKSTATUSENUM.STOPPED)
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.STOPPED,
    };
  } else if (
    item.tasks.every((item) => item.status === ROBOTTASKSTATUSENUM.DONE) &&
    item.tasks.length > 0
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DONE,
    };
  } else if (
    item.tasks.every((item) => item.status === ROBOTTASKSTATUSENUM.DEFAULT) &&
    item.tasks.length > 0
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DEFAULT,
    };
  } else if (
    item.tasks.find((item) => item.status === ROBOTTASKSTATUSENUM.INPROGRESS)
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.INPROGRESS,
    };
  } else if (
    item?.tasks?.find((item) => item.status === ROBOTTASKSTATUSENUM.DELAYED)
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DELAYED,
    };
  } else if (
    item.tasks.find((item) => item.status === ROBOTTASKSTATUSENUM.Failed)
  ) {
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.FAILED,
    };
  } else
    return {
      ...item,
      agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DEFAULT,
    };
};

export const updateSingleAssignmentPercentage = (item) => {
  const eachPercentage = 100 / item?.tasks?.length;
  let totalPercentage = 0;
  item?.tasks?.forEach((el) => {
    if (el.status === ROBOTTASKSTATUSENUM.DONE) {
      totalPercentage += eachPercentage;
    }
    if (el.status === ROBOTTASKSTATUSENUM.Failed) {
      totalPercentage += eachPercentage;
    }
    if (
      el.status === ROBOTTASKSTATUSENUM.STOPPED ||
      el.status === ROBOTTASKSTATUSENUM.STOPPED_ASSIGNMENT
    ) {
      totalPercentage += eachPercentage;
    }
    if (el.status === ROBOTTASKSTATUSENUM.INPROGRESS) {
      totalPercentage += Math.floor(eachPercentage / 2);
    }
  });
  if (item?.tasks?.every((el) => el.status === ROBOTTASKSTATUSENUM.DONE)) {
    totalPercentage = 100;
  }
  if (item?.tasks?.every((el) => el.status === ROBOTTASKSTATUSENUM.Failed)) {
    totalPercentage = 100;
  }
  return { ...item, progressPercentage: Math.floor(totalPercentage) };
};

const updateAssignmentProgressPercentage = (data) => {
  const assignmentsWithStatuses = data.assignments.map((item) => {
    return updateAssignmentStatus(item);
  });
  //update progress percentage
  const assignmentsWithPercentage = assignmentsWithStatuses.map((item) => {
    return updateSingleAssignmentPercentage(item);
  });
  return { ...data, assignments: assignmentsWithPercentage };
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setAssignmentsRedux: (state, action) => {
      state.agendaData = updateAssignmentProgressPercentage(action.payload);
    },
    addAssignmentRedux: (state, action) => {
      if (
        !state.agendaData.immediateTasks.find(
          (task) => task.id === action.payload.id
        )
      ) {
        state.agendaData = {
          ...state.agendaData,
          immediateTasks: [...state.agendaData.immediateTasks, action.payload],
        };
      }
    },
    setAgendaDataBeforeDrag: (state, action) => {
      state.agendaDataBeforeDrag = action.payload;
    },
    handleUpdateAssignmentFromAgenda: (state, action) => {
      const updatedAssignment = { ...action.payload };
      state.agendaData = {
        ...state.agendaData,
        assignments: [...state.agendaData.assignments].map((item) => {
          if (updatedAssignment.isDragging) {
            return item.id + item.agendaDate ===
              updatedAssignment.id + updatedAssignment.agendaDateBeforeDrag
              ? { ...updatedAssignment }
              : item;
          } else {
            return item.id === updatedAssignment.id
              ? { ...updatedAssignment, agendaDate: item.agendaDate }
              : item;
          }
        }),
      };
      if (
        !state.agendaData.assignments.find(
          (assignment) => assignment.id === updatedAssignment.id
        )
      ) {
        state.agendaData = {
          ...state.agendaData,
          assignments: [...state.agendaData.assignments, action.payload],
        };
      }
    },
    handleSortAgendaData: (state, action) => {
      state.sortedDataForAgenda = action.payload;
    },
    handleInProgressTasks: (state, action) => {
      state.tasksInProgress = action.payload;
    },
    updateAssignmentRedux: (state, action) => {
      const obj = action.payload;
      const updatedAgenda = {
        ...state.agendaData,
        immediateTasks: state.agendaData.immediateTasks.map((item) => {
          return item.id === obj.id ? { ...item, ...obj } : item;
        }),
        assignments: state.agendaData.assignments.map((item) => {
          let isNewTask = false;
          if (item.id === obj?.assignment?.id) {
            if (!item.tasks.some((tsk) => tsk.id === obj.id)) isNewTask = true;
          }
          return {
            ...item,
            tasks: isNewTask
              ? [
                  ...item.tasks.filter(
                    (tsk) => tsk.status !== ROBOTTASKSTATUSENUM.DEFAULT
                  ),
                  obj,
                ]
              : item.tasks.map((el) => {
                  return el?.id === obj.id
                    ? {
                        ...el,
                        status: obj.status,
                        statusHistory: obj.statusHistory,
                      }
                    : el;
                }),
          };
        }),
      };
      state.agendaData = updateAssignmentProgressPercentage(updatedAgenda);
    },
    updateAssignmentDetailsStatuses: (state, action) => {
      if (state.assignmentDetails) {
        let data = { ...action.payload };
        if (data.assignment) {
          let assignmentCopy = state.assignmentDetails
            ? { ...state.assignmentDetails }
            : null;
          if (assignmentCopy && assignmentCopy?.id === data?.id) {
            const updatedTask = [
              ...state.agendaData.assignments,
              ...state.agendaData.immediateTasks,
            ]?.find((item) => {
              return item.id === assignmentCopy?.id;
            });

            if (
              updatedTask?.message !== data?.message ||
              updatedTask?.status !== data?.status ||
              updatedTask?.statusHistory?.length !==
                assignmentCopy?.statusHistory?.length
            ) {
              assignmentCopy = {
                ...updatedTask,
                message: data?.message,
              };
            }
          } else if (
            assignmentCopy?.assignment
            // assignmentCopy.id === data?.assignment?.id
          ) {
            assignmentCopy.tasks = assignmentCopy?.tasks?.map((task) =>
              task?.id === data?.task?.id || task?.id === data?.id
                ? { ...task, ...data }
                : task
            );
          }
          dashboardSlice.caseReducers.handleAssignmentDetailsUpdate(state, {
            ...action,
            payload: assignmentCopy,
          });
        }
        if (!data.assignment) {
          if (data.id === state.assignmentDetails.id) {
            dashboardSlice.caseReducers.handleAssignmentDetailsUpdate(state, {
              ...action,
              payload: {
                ...data,
                status: data.status,
                statusHistory: data.statusHistory,
              },
            });
          }
        }
      }
    },
    handleAssignmentDetailsUpdate: (state, action) => {
      let data = action.payload && { ...action.payload };
      if (data?.assignment) {
        data.tasks = [...data.tasks].sort((a, b) => {
          return (
            moment(a.createdAt)
              .format("HH:mm")
              .replace(":", "")
              .replace(" ", "") -
            moment(b.createdAt)
              .format("HH:mm")
              .replace(":", "")
              .replace(" ", "")
          );
        });
        data.tasks = [...data.tasks].map((item) => ({
          ...item,
          isCreated: true,
        }));

        state.assignmentDetails = updateSingleAssignmentPercentage(
          updateAssignmentStatus(data)
        );
      } else {
        state.assignmentDetails = data;
      }

      // when assignment details is closing or creating new one
      if (!data || !data?.isCreated) {
        state.sortedDataForAgenda = state.sortedDataForAgenda.map((item) => ({
          ...item,
          justCreated: false,
        }));
      }
    },
    setAssignmentEditMode: (state, action) => {
      state.isAssignmentEditMode = action.payload;
    },
    setAssignmentDetailsBeforeChange: (state, action) => {
      state.assignmentDetailsBeforeChange = parseAssignment(action.payload);
    },
    setDeleteTask: (state, action) => {
      state.deleteTaskData = action.payload;
    },
    setEditTaskData: (state, action) => {
      state.editTaskData = action.payload;
    },
    setStopTaskData: (state, action) => {
      state.stopTaskData = action.payload;
    },
    setStopAssignmentData: (state, action) => {
      state.stopAssignmentData = action.payload;
    },
    setDeleteAssignment: (state, action) => {
      const assignment = { ...action.payload };
      state.agendaData = {
        ...state.agendaData,
        assignments: state.agendaData.assignments.filter(
          (el) => el.id !== assignment.id
        ),
      };
    },
    setDeleteTaskArchive: (state, action) => {
      const task = { ...action.payload };
      state.agendaData = {
        ...state.agendaData,
        immediateTasks: state.agendaData.immediateTasks.filter(
          (el) => el.id !== task.id
        ),
      };
    },
    setAgendaCalendarDate: (state, action) => {
      state.calendarDate = action.payload;
    },
    setDuplicateAssignment: (state, action) => {
      state.duplicateAssignment = action.payload;
    },
    refreshAgenda: (state) => {
      state.refreshAgendaState = !state.refreshAgendaState;
    },
    setITModal: (state, action) => {
      state.ITModal = action.payload;
    },
    setDraggableAssignment: (state, action) => {
      state.draggableAssignment = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setChosenWeekDays: (state, action) => {
      state.chosenWeekDays = action.payload;
    },
    handleAgendaRevert: (state, action) => {
      if (state.sortedDataForAgenda.find((item) => item.isDragging)) {
        dashboardSlice.caseReducers.setAssignmentsRedux(state, {
          ...action,
          payload: state.agendaDataBeforeDrag,
        });
      }
    },
  },
});

export const {
  setAssignmentsRedux,
  addAssignmentRedux,
  updateAssignmentRedux,
  handleAssignmentDetailsUpdate,
  handleUpdateAssignmentFromAgenda,
  handleSortAgendaData,
  handleInProgressTasks,
  setDeleteTask,
  setAssignmentEditMode,
  setDeleteAssignment,
  setEditTaskData,
  setAgendaCalendarDate,
  refreshAgenda,
  setITModal,
  setStopTaskData,
  setStopAssignmentData,
  updateAssignmentDetailsStatuses,
  setDraggableAssignment,
  handleAgendaRevert,
  setAgendaDataBeforeDrag,
  setIsLoading,
  setDuplicateAssignment,
  setAssignmentDetailsBeforeChange,
  setChosenWeekDays,
  setDeleteTaskArchive,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
