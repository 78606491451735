import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BackArrow,
  BatteryContent,
  DataInfo,
  LineVertical,
  StyledBattery,
} from "../components";
import {
  StyledRobotName,
  TeleopHeaderContainer,
  SignalWrapper,
} from "./components";
import { ROBOTSTATUSENUM, ROLESENUM } from "../../../enums";
import TeleopActions from "./TeleopActions";
import ICONS from "../../../constants/icons";
import { StyledSingleLineContainer } from "../../../components/commonStyles";
import { useTransitive } from "../../../contexts/TransitiveContext";
import TriggerEvents from "./trigger-events/TriggerEvents";
import { useSelector } from "react-redux";

const TeleopGeneralInfo = ({ currentRobot }) => {
  const history = useHistory();
  const { isEditModeRef } = useTransitive();
  const [showTriggerEvents, setShowTriggerEvents] = useState(false);
  const { currentRole } = useSelector((state) => state.clients);
  const handleBurgerMenuClick = () => {
    [ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole) &&
      setShowTriggerEvents((prev) => !prev);
  };

  return (
    <TeleopHeaderContainer>
      <BackArrow src={ICONS.ChevronLeft} onClick={() => history.goBack()} />
      <LineVertical />
      <div>
        <StyledRobotName>{currentRobot?.name}</StyledRobotName>
        <StyledRobotName style={{ fontSize: "16px", fontWeight: 400 }}>
          {currentRobot?.teleop_status?.status}
        </StyledRobotName>
      </div>

      <LineVertical style={{ margin: "0 25px" }} />
      <BatteryContent>
        <div
          style={{
            width: "120px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {(currentRobot?.robot_manager?.status ||
            currentRobot?.robot_manager?.status === ROBOTSTATUSENUM.OFF) && (
            <DataInfo>
              {!isNaN(currentRobot?.battery?.level) &&
                `${+(currentRobot?.battery?.level * 100).toFixed(0)}%`}
            </DataInfo>
          )}
          <StyledBattery
            style={{ margin: "unset" }}
            battery={(+currentRobot?.battery?.level)?.toFixed(2)}
          />
        </div>
      </BatteryContent>
      <SignalWrapper>
        <p>Poor</p>
        <img src={ICONS.ConnectionStatusIcon} alt="" />
      </SignalWrapper>
      <LineVertical style={{ margin: "0 25px" }} />
      <TeleopActions currentRobot={currentRobot} />

      {showTriggerEvents && (
        <TriggerEvents
          setShowTriggerEvents={setShowTriggerEvents}
          currentRobot={currentRobot}
        />
      )}
      <StyledSingleLineContainer
        style={{
          gap: "15px",
          justifyContent: "flex-end",
          position: "relative",
        }}
        id="editLines"
      >
        <img
          src={ICONS.BurgerMenu}
          alt=""
          style={{ marginLeft: "20px", cursor: "pointer" }}
          onClick={handleBurgerMenuClick}
        />
        <LineVertical style={{ margin: "0 15px" }} />
        <img
          src={ICONS.PencilDraw}
          alt=""
          style={{ marginRight: "15px", cursor: "pointer" }}
          onClick={() =>
            [ROLESENUM.TELEOPERATOR, ROLESENUM.ADMIN].includes(currentRole) &&
            (isEditModeRef.current = !isEditModeRef.current)
          }
        />
      </StyledSingleLineContainer>
    </TeleopHeaderContainer>
  );
};

export default TeleopGeneralInfo;
